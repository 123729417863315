// components/ProtectedRoute.tsx
import {Navigate, useLocation} from 'react-router-dom'
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {setAccessTokenGetter} from "@/lib/httpClient";
import useUserStore from "@/stores/useUserStore";
import config from "@/lib/config";
import {jwtDecode, JwtPayload} from "jwt-decode";

interface ProtectedRouteProps {
    children: React.ReactNode
}

interface AuthMetadata {
    department_id: string;
    id: string;
    role_hierarchy_level: number;
    tenant_id: string;
}

export const ProtectedRoute = ({children}: ProtectedRouteProps) => {
    const {user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims} = useAuth0();
    const location = useLocation();
    const {initCompleted, setUser} = useUserStore();
    const appMetadata = `${config.audience}/metadata` as keyof JwtPayload;
    const appPermissions = 'permissions' as keyof JwtPayload;

    useEffect(() => {
        const initUser = async () => {
            if (isAuthenticated) {
                const tokenClaims = await getIdTokenClaims();
                const accessToken = await getAccessTokenSilently();
                const decodedToken = jwtDecode(accessToken);
                const authMetadata = decodedToken != undefined ? decodedToken[appMetadata] : {};
                const authPermissions = decodedToken[appPermissions] ?? [];
                const { id, tenant_id } = authMetadata as AuthMetadata;
                const roles = tokenClaims != undefined ? tokenClaims[`${config.audience}/roles`] : [];

                if (import.meta.env.DEV) {
                    console.log('decodedToken', decodedToken);
                }

                setUser({user_id: id, name: user?.name, email: user?.email, profilePicture: user?.picture, roles: roles, company_id: tenant_id, permissions: authPermissions as string[]});
                setAccessTokenGetter(getAccessTokenSilently);
            }
        }

        initUser().catch();
    }, [user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, setUser, appMetadata]);

    if (isLoading) return

    if (isAuthenticated && !initCompleted) return

    if (!isAuthenticated) return <Navigate to="/login" state={{from: location}} replace/>

    return <>{children}</>
}
