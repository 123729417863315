import React from "react";

interface RoleGuardProps {
    children: React.ReactNode
}

const RoleGuard = ({children}: RoleGuardProps) => {
    return <>{children}</>
}

export default RoleGuard;