import React from 'react';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';
import type { Role } from '@/types/role.types';
import { useGetRolesQuery } from "@/components/features/roles/services/roles.queries";

interface RoleDropdownProps {
    label?: string;
    value?: string | undefined;
    onChange?: (value: string) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    helperText?: string;
    currentUserHierarchyLevel?: number;
}

const RoleDropdown = React.forwardRef<HTMLSelectElement, RoleDropdownProps>(
    ({
         label = 'Ρόλος',
         value = undefined,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε ρόλο',
         helperText,
         currentUserHierarchyLevel,
         ...props
     }, ref) => {
        const { data: roles, isLoading, isError } = useGetRolesQuery();
        const [selectedRole, setSelectedRole] = React.useState<string | undefined>(value);

        // Update local state when value prop changes
        React.useEffect(() => {
            setSelectedRole(value);
        }, [value]);

        currentUserHierarchyLevel = 1;

        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const newValue = event.target.value;
            setSelectedRole(newValue);
            onChange?.(newValue);
        };

        // Filter roles based on the current user's hierarchy level
        const roleOptions = React.useMemo(() => {
            if (!roles) return [];

            return roles
                .filter((role: Role) => role.hierarchy_level >= currentUserHierarchyLevel)
                .sort((a: Role, b: Role) => a.hierarchy_level - b.hierarchy_level)
                .map((role: Role) => ({
                    value: role.role_id,
                    label: role.role_name,
                }));
        }, [roles, currentUserHierarchyLevel]);

        if (isError) {
            return (
                <DropdownWithLabel
                    ref={ref}
                    label={label}
                    options={[]}
                    error="Σφάλμα φόρτωσης ρόλων"
                    disabled
                    required={required}
                    {...props}
                />
            );
        }

        return (
            <DropdownWithLabel
                ref={ref}
                label={label}
                options={roleOptions}
                value={selectedRole || ''}
                onChange={handleChange}
                error={error}
                disabled={disabled || isLoading}
                required={required}
                placeholder={isLoading ? 'Φόρτωση...' : placeholder}
                helperText={helperText}
                {...props}
            />
        );
    }
);

RoleDropdown.displayName = 'RoleDropdown';

export default RoleDropdown;
