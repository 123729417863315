import { Building2, FileText, InfoIcon, LayoutDashboard, Users} from "lucide-react";
import {NavItem} from "@/routes/navigation";

export const clientManagerNavItems: NavItem[] =  [
    {
        name: 'Πίνακας Ελέγχου',
        icon: LayoutDashboard,
        href: '/'
    },
    {
        name: 'Αιτήματα',
        icon: FileText,
        children: [
            { name: 'Όλα τα Αιτήματα', href: '/tickets' },
            { name: 'Δημιουργία Αιτήματος', href: '/tickets/new' },
        ]
    },
    {
        name: 'Η Εταιρία μου',
        icon: Building2,
        href: '/company'
    },
    {
        name: 'Χρήστες',
        icon: Users,
        children: [
            { name: 'Όλοι οι Χρήστες', href: '/users' },
        ]
    },
    // {
    //     name: 'Προϊόντα',
    //     icon: Boxes,
    //     children: [
    //         { name: 'Όλα τα Προϊόντα', href: '/products' },
    //     ]
    // },
    // {
    //     name: 'Αναφορές',
    //     icon: BarChart3,
    //     children: [
    //         { name: 'Αναφορά Αιτημάτων', href: '/reports/tickets' },
    //         { name: 'Αναφορά SLA', href: '/reports/sla' }
    //     ]
    // },
    {
        name: 'Πληροφορίες',
        icon: InfoIcon,
        children: [
            { name: 'Επίπεδα Προτεραιότητας', href: '/priorities' },
            { name: 'Καταστάσεις', href: '/status' }
        ]
    },
];
