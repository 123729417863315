// src/components/features/tickets/components/UserOwnedTickets.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { TimeAgo } from '@/components/ui/TimeAgo/TimeAgo';
import { useGetTicketsQuery } from '@/components/features/tickets/services/tickets.queries';
import { ROUTES } from '@/constants/routes.constants';
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import DataTable from "@/components/ui/DataTable";
import type { ColumnDef } from "@tanstack/react-table";
import type { Ticket } from "@/types/ticket.types";
import ColumnTitle from "@/components/ui/DataTable/ColumnTitle";
import { StatusName } from "@/types/status.types";
import { PriorityName } from "@/types/priority.types";

interface TicketRow {
    id: string;
    number: string;
    title: string;
    status: StatusName;
    priority: PriorityName;
    createdAt: string;
    updatedAt: string;
}

const mapTicketToRow = (ticket: Ticket): TicketRow => ({
    id: ticket.ticket_id,
    number: ticket.ticket_number.toString(),
    title: ticket.title,
    status: ticket.status.status_name as StatusName,
    priority: ticket.priority.priority_name as PriorityName,
    createdAt: ticket.created_at,
    updatedAt: ticket.updated_at
});

interface UserOwnedTicketsProps {
    userId: string;
}

export const UserOwnedTickets: React.FC<UserOwnedTicketsProps> = ({ userId }) => {
    const navigate = useNavigate();

    const { data: tickets, isLoading } = useGetTicketsQuery({
        user_id: userId,
        owned: true,
        page_size: 5
    });

    const handleTicketClick = (row: TicketRow) => {
        navigate(ROUTES.TICKETS.DETAILS(row.id));
    };

    const handleViewAll = () => {
        navigate(ROUTES.TICKETS.LIST + '?owned=true');
    };

    const handleCreateTicket = () => {
        navigate(ROUTES.TICKETS.NEW);
    };

    const columns: ColumnDef<TicketRow, string>[] = [
        {
            id: 'number',
            header: ({ column }) => (
                <ColumnTitle title="Αριθμός - Τίτλος" isSortable column={column} />
            ),
            accessorFn: (row) => row.number,
            cell: ({ row }) => (
                <div className="flex flex-col">
                    <div className="flex items-center gap-1">
                        <span className="font-medium text-gray-900">#{row.original.number}</span>
                    </div>
                    <span className="text-sm text-gray-600 truncate max-w-md">
        {row.original.title}
    </span>
                </div>
            ),
        },
        {
            id: 'status',
            header: ({column}) => (
                <ColumnTitle title="Κατάσταση" isSortable column={column}/>
            ),
            accessorFn: (row) => row.status,
            cell: ({row}) => (
                <StatusBadge status={row.original.status} />
            ),
        },
        {
            id: 'priority',
            header: ({ column }) => (
                <ColumnTitle title="Προτεραιότητα" isSortable column={column} />
            ),
            accessorFn: (row) => row.priority,
            cell: ({ row }) => (
                <PriorityBadge priority={row.original.priority} />
            ),
        },
        {
            id: 'updatedAt',
            header: ({ column }) => (
                <ColumnTitle title="Τελευταία Ενημέρωση" isSortable column={column} />
            ),
            accessorFn: (row) => new Date(row.updatedAt).getTime().toString(),
            cell: ({ row }) => (
                <TimeAgo date={row.original.updatedAt} />
            ),
        },
    ];

    const mappedTickets = React.useMemo(() => {
        if (!tickets?.data) return [];
        return tickets.data.map(mapTicketToRow);
    }, [tickets]);

    return (
        <Card>
            <CardHeader>
                <div className="flex items-center justify-between">
                    <CardTitle>Πρόσφατα Αιτήματα</CardTitle>
                    <div className="flex space-x-2">
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            onClick={handleViewAll}
                        >
                            Προβολή Όλων
                        </Button>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SM}
                            onClick={handleCreateTicket}
                        >
                            <Plus className="h-4 w-4 mr-2" />
                            Νέο Αίτημα
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <CardContent>
                <DataTable
                    columns={columns}
                    data={mappedTickets}
                    enableGlobalFilter={false}
                    enableColumnFilters={false}
                    noDataMessage={
                        "Δεν έχετε δημιουργήσει κανένα αίτημα ακόμα"
                    }
                    onRowClick={handleTicketClick}
                    isLoading={isLoading}
                />
            </CardContent>
        </Card>
    );
};

export default UserOwnedTickets;
