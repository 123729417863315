import React from 'react';
import useUserStore from "@/stores/useUserStore";
import AdministratorDashboard from "@/components/layouts/Dashboard/AdministratorDashboard";
import HelpDeskDashboard from "@/components/layouts/Dashboard/HelpDeskDashboard";
import ManagerDashboard from "@/components/layouts/Dashboard/ManagerDashboard";
import AgentDashboard from "@/components/layouts/Dashboard/AgentDashboard";
import ClientManagerDashboard from "@/components/layouts/Dashboard/ClientManagerDashboard";
import ClientEmployeeDashboard from "@/components/layouts/Dashboard/ClientEmployeeDashboard";
import ClientAdministratorDashboard from "@/components/layouts/Dashboard/ClientAdministratorDashboard";

const Dashboard = () => {
    const { user } = useUserStore();
    const roles = user?.roles || [];

    // Return appropriate dashboard based on role
    if (roles.includes('administrator')) {
        return <AdministratorDashboard />;
    }

    if (roles.includes('helpdesk')) {
        return <HelpDeskDashboard />;
    }

    if (roles.includes('manager')) {
        return <ManagerDashboard />;
    }

    if (roles.includes('agent')) {
        return <AgentDashboard />;
    }

    if (roles.includes('client_administrator')) {
        return <ClientAdministratorDashboard />;
    }

    if (roles.includes('client_manager')) {
        return <ClientManagerDashboard />;
    }

    if (roles.includes('client_employee')) {
        return <ClientEmployeeDashboard />;
    }

    // Default dashboard (or could show an error/unauthorized message)
    return <ClientEmployeeDashboard />;
};

export default Dashboard;
