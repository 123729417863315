// components/ui/Avatar/Avatar.tsx
import { cn } from "@/lib/utils";
import { getRoleColorClasses } from "@/constants/colors.constants";
import { UserRole } from "@/types/role.types";

type AvatarSize = 'sm' | 'md' | 'lg' | 'xl';

const sizeClasses: Record<AvatarSize, string> = {
    sm: 'h-8 w-8 text-xs',
    md: 'h-10 w-10 text-sm',
    lg: 'h-12 w-12 text-base',
    xl: 'h-16 w-16 text-lg'
} as const;

type UserObject = {
    avatar_url?: string | null;
    first_name: string;
    last_name: string;
    role: {
        role_id: string;
        role_name: string;
    };
};

interface AvatarProps {
    user?: UserObject;
    firstName?: string;
    lastName?: string;
    roleName?: string;
    size?: AvatarSize;
    className?: string;
}

const mapRoleNameToType = (roleName: string): UserRole => {
    switch (roleName) {
        case 'Διαχειριστής':
            return 'administrator';
        case 'Τεχνική Υποστήριξη':
            return 'helpdesk';
        case 'Υπεύθυνος':
            return 'manager';
        case 'Εκπρόσωπος':
            return 'agent';
        case 'Διαχειριστής Πελάτη':
            return 'client_administrator';
        case 'Υπεύθυνος Πελάτη':
            return 'client_manager';
        case 'Υπάλληλος Εταιρίας':
            return 'client_employee';
        default:
            return 'agent';
    }
};

export function Avatar({
                           user,
                           firstName,
                           lastName,
                           roleName = 'Εκπρόσωπος',
                           size = 'md',
                           className
                       }: AvatarProps) {
    // Determine the actual values to use
    const actualFirstName = user?.first_name || firstName || '';
    const actualLastName = user?.last_name || lastName || '';
    const actualRoleName = user?.role?.role_name || roleName;
    const avatarUrl = user?.avatar_url;

    // Get role type and colors
    const roleType = mapRoleNameToType(actualRoleName);
    const colors = getRoleColorClasses(roleType);

    // Generate initials
    const name = `${actualFirstName} ${actualLastName}`;
    const initials = name.trim().split(/\s+/)
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);

    if (avatarUrl) {
        return (
            <div className={cn("relative", sizeClasses[size], className)}>
                <img
                    src={avatarUrl}
                    alt={name}
                    className="rounded-full object-cover w-full h-full"
                    onError={(e) => {
                        e.currentTarget.style.display = 'none';
                        e.currentTarget.parentElement?.querySelector('.fallback')?.classList.remove('hidden');
                    }}
                />
                <div className={cn(
                    "fallback hidden absolute inset-0 rounded-full flex items-center justify-center font-medium",
                    colors.bg,
                    colors.text
                )}>
                    <span>{initials}</span>
                </div>
            </div>
        );
    }

    return (
        <div className={cn(
            "rounded-full flex items-center justify-center font-medium",
            sizeClasses[size],
            colors.bg,
            colors.text,
            className
        )}>
            <span>{initials}</span>
        </div>
    );
}
