import React from 'react';
import { Package, Tag } from 'lucide-react';
import { Ticket } from '@/types/ticket.types';
import useUserStore from "@/stores/useUserStore";
import {RoleTypes} from "@/types/role.types";

interface TicketProductsCardProps {
    ticket: Ticket;
}

const TicketProductsCard: React.FC<TicketProductsCardProps> = ({ ticket }) => {
    const userStore = useUserStore();
    const roles = userStore.user?.roles;
    const isAdmin = roles?.includes(RoleTypes.ADMINISTRATOR)
    if (!ticket.products?.length) return null;

    return (
        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
            <div className="flex items-center gap-3 mb-4">
                <Package className="h-5 w-5 text-gray-400"/>
                <h2 className="text-lg font-semibold">Προϊόντα</h2>
            </div>
            <div className="space-y-4">
                {ticket.products.map((ticketProduct) => {
                    const product = ticketProduct.product_client.product;
                    return (
                        <div
                            key={ticketProduct.ticket_product_id}
                            className="flex flex-col bg-gray-50 rounded-lg border border-gray-200 p-4 hover:border-primary-300 transition-colors"
                        >
                            <div className="flex items-start justify-between">
                                <div className="flex-1">
                                    <h3 className="font-medium text-gray-900">
                                        {product.product_name}
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {product.description}
                                    </p>
                                </div>
                                <div className="ml-4">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    <Tag className="w-3 h-3 mr-1" />
                      {product.product_code}
                  </span>
                                </div>
                            </div>
                            <div className="mt-3 pt-3 border-t border-gray-200">
                                <div className="flex items-center justify-between text-sm">
                                    <span className="text-gray-500">Τύπος</span>
                                    <span className="font-medium text-gray-900">
                    {product.product_type.product_type_name}
                  </span>
                                </div>
                                {ticketProduct.product_client.price > 0 && isAdmin && (
                                    <div className="flex items-center justify-between text-sm mt-2">
                                        <span className="text-gray-500">Τιμή</span>
                                        <span className="font-medium text-gray-900">
                      €{ticketProduct.product_client.price.toLocaleString('el-GR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                    </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TicketProductsCard;
