import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { LogOut, User } from 'lucide-react';
import { useAuth0 } from "@auth0/auth0-react";
import useUserStore from "@/stores/useUserStore";
import { useNavigate } from 'react-router-dom';

const ProfileDropdown = () => {
  const { logout } = useAuth0();
  const { user } = useUserStore();
  const navigate = useNavigate();

  return (
      <Menu as="div" className="relative z-50">
        <Menu.Button className="flex items-center gap-x-2 rounded-full bg-white p-1.5 hover:bg-gray-50">
          <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src={user.profilePicture}
              alt={user.name}
          />
          <span className="hidden md:block text-sm font-medium text-gray-700">
          {user.name}
        </span>
        </Menu.Button>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {/* Profile Info Section */}
            <div className="px-4 py-3">
              <p className="text-sm font-medium text-gray-900">{user.name}</p>
              <p className="text-xs text-gray-500 truncate">{user.email}</p>
            </div>

            {/* Main Menu Items */}
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                    <button
                        onClick={() => navigate('/profile')}
                        className={`${
                            active ? 'bg-gray-50' : ''
                        } flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                    >
                        <User className="mr-2 h-4 w-4"/>
                        Προφίλ
                    </button>
                )}
              </Menu.Item>
            </div>

            {/* Logout Section */}
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                    <button
                        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                        className={`${
                            active ? 'bg-gray-50' : ''
                        } flex w-full items-center px-4 py-2 text-sm text-red-700`}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      Αποσύνδεση
                    </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
  );
};

export default ProfileDropdown;
