// src/components/ui/DataTable/RowActions.tsx
import React from 'react';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { Eye, Pencil, Trash2, type LucideIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

type ActionType = 'view' | 'edit' | 'delete' | 'custom';

interface BaseAction {
  type: ActionType;
  show?: boolean;
  onClick?: () => void;
  className?: string;
  label?: string;
  icon?: LucideIcon;
}

interface ViewAction extends BaseAction {
  type: 'view';
  path: string;
}

interface EditAction extends BaseAction {
  type: 'edit';
  onClick: () => void;
}

interface DeleteAction extends BaseAction {
  type: 'delete';
  onClick: () => void;
}

interface CustomAction extends BaseAction {
  type: 'custom';
  icon: LucideIcon;
  onClick: () => void;
}

type Action = ViewAction | EditAction | DeleteAction | CustomAction;

interface RowActionsProps {
  actions: Action[];
}

export const RowActions = ({ actions }: RowActionsProps) => {
  const navigate = useNavigate();

  const getActionProps = (action: Action) => {
    const baseProps = {
      variant: ButtonVariant.GHOST,
      size: ButtonSize.SM,
      className: action.className,
      title: action.label,
    };

    switch (action.type) {
      case 'view':
        return {
          ...baseProps,
          icon: Eye,
          onClick: () => navigate(action.path),
          label: action.label ?? 'Προβολή',
        };
      case 'edit':
        return {
          ...baseProps,
          icon: Pencil,
          onClick: action.onClick,
          label: action.label ?? 'Επεξεργασία',
        };
      case 'delete':
        return {
          ...baseProps,
          icon: Trash2,
          onClick: action.onClick,
          label: action.label ?? 'Διαγραφή',
          className: 'text-red-600 hover:text-red-700',
        };
      case 'custom':
        return {
          ...baseProps,
          icon: action.icon,
          onClick: action.onClick,
        };
    }
  };

  return (
    <div className="flex gap-2">
      {actions
        .filter(action => action.show !== false)
        .map((action, index) => {
          const { icon: Icon, ...props } = getActionProps(action);
          return (
            <Button key={index} {...props}>
              <Icon className="h-4 w-4" />
            </Button>
          );
        })}
    </div>
  );
};


/**
 * Usage Guide:
 *
 * 1. Basic Usage in Table Column:
 * ```typescript
* {
    *   id: 'actions',
    *   header: 'Ενέργειες',
    *   cell: ({ row }) => (
        *     <RowActions
 *       actions={[
    *         // View Action - Navigates to detail page
    *         {
        *           type: 'view',
        *           path: `/companies/${row.original.id}`,
        *         },
    *         // Edit Action - Handles edit logic
    *         {
        *           type: 'edit',
        *           onClick: () => handleEdit(row.original),
    *         },
*         // Delete Action - Handles delete logic
*         {
*           type: 'delete',
*           onClick: () => handleDelete(row.original.id),
*         },
*       ]}
*     />
*   ),
* }
*
* 2. With Conditional Rendering:
    * ```typescript
 * <RowActions
 *   actions={[
 *     {
 *       type: 'view',
 *       path: `/companies/${row.original.id}`,
 *       show: hasViewPermission, // Only show if user has permission
 *     },
 *     {
 *       type: 'edit',
 *       onClick: () => handleEdit(row.original),
 *       show: row.original.isEditable, // Only show if item can be edited
 *     },
 *   ]}
 * />
 *
 * 3. With Custom Action:
 * ```typescript
* <RowActions
 *   actions={[
    *     {
        *       type: 'custom',
        *       icon: Download, // Any Lucide icon
        *       onClick: () => handleDownload(row.original.id),
    *       label: 'Λήψη Αρχείου',
    *     },
*   ]}
* />
*
* 4. With Custom Styling:
    * ```typescript
 * <RowActions
 *   actions={[
 *     {
 *       type: 'edit',
 *       onClick: () => handleEdit(row.original),
 *       className: 'text-blue-600 hover:text-blue-700',
 *     },
 *   ]}
 * />
 */
