import React from "react";
import {
    FileText,
    FileImage,
    FileVideo,
    FileAudio,
    FileType2,
    FileSpreadsheet,
    File,
} from "lucide-react";
import {MessageAttachment, NoteAttachment} from "@/types/attachment.types";

interface AttachmentListProps {
    attachments: Array<MessageAttachment | NoteAttachment>;
    onAttachmentClick?: (id: string) => void;
    className?: string;
}

const getFileIcon = (fileName: string, fileType?: string) => {
    // Check MIME type first if available
    if (fileType) {
        if (fileType.startsWith('image/')) return FileImage;
        if (fileType.startsWith('video/')) return FileVideo;
        if (fileType.startsWith('audio/')) return FileAudio;
        if (fileType === 'application/pdf') return FileType2;
        if (fileType.includes('spreadsheet') || fileType.includes('excel')) return FileSpreadsheet;
    }

    // Fallback to extension checking
    const extension = fileName.toLowerCase().slice(fileName.lastIndexOf('.'));

    switch (extension) {
        case '.pdf':
            return FileType2;
        case '.jpg':
        case '.jpeg':
        case '.png':
        case '.gif':
        case '.webp':
            return FileImage;
        case '.mp4':
        case '.avi':
        case '.mov':
        case '.webm':
            return FileVideo;
        case '.mp3':
        case '.wav':
        case '.ogg':
            return FileAudio;
        case '.xlsx':
        case '.xls':
        case '.csv':
            return FileSpreadsheet;
        case '.doc':
        case '.docx':
        case '.txt':
            return FileText;
        default:
            return File;
    }
};

export const AttachmentList: React.FC<AttachmentListProps> = ({
                                                                  attachments,
                                                                  onAttachmentClick,
                                                                  className = ''
                                                              }) => {
    if (!attachments.length) return null;

    return (
        <div className={`space-y-2 ${className}`}>
            {attachments.map((attachment) => {
                const attachmentId = 'message_attachment_id' in attachment
                    ? attachment.message_attachment_id
                    : attachment.note_attachment_id;

                const IconComponent = getFileIcon(attachment.file_name, attachment.file_type);

                return (
                    <button
                        key={attachmentId || attachment.file_name}
                        onClick={() => attachmentId && onAttachmentClick?.(attachmentId)}
                        className="flex items-center space-x-2 text-sm text-gray-600 hover:text-gray-900 transition-colors group w-full"
                    >
                        <IconComponent className="w-4 h-4 text-gray-400 group-hover:text-gray-600" />
                        <span className="flex-1 text-left truncate">{attachment.file_name}</span>
                        {attachmentId && (
                            <span className="opacity-0 group-hover:opacity-100 text-xs text-primary-600">
                                Προβολή
                            </span>
                        )}
                    </button>
                );
            })}
        </div>
    );
};

export default AttachmentList;
