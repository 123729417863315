import React, { useMemo } from 'react';
import { Clock, BarChart } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import { getPriorityColorClasses } from '@/constants/colors.constants';
import {getPriorityIcon, PriorityEnum, PriorityName} from "@/types/priority.types";
import { useGetCompanySlaQuery } from "@/components/features/companies/services/companies.queries";
import useUserStore from "@/stores/useUserStore";
import { SLA } from "@/types/sla.types";

interface PriorityMetadata {
    use_cases: string[];
    escalation: string;
    impact: string;
}

const priorityOrder: PriorityName[] = [
    PriorityEnum.URGENT,  // 'Επείγον'
    PriorityEnum.HIGH,    // 'Υψηλή'
    PriorityEnum.MEDIUM,  // 'Μεσαία'
    PriorityEnum.LOW      // 'Χαμηλή'
];

const sortByPriority = (a: { priority_name: PriorityName }, b: { priority_name: PriorityName }) => {
    return priorityOrder.indexOf(a.priority_name) - priorityOrder.indexOf(b.priority_name);
};

const priorityMetadata: Record<PriorityName, PriorityMetadata> = {
    [PriorityEnum.URGENT]: {
        use_cases: [
            'Διακοπή παραγωγικών συστημάτων',
            'Προβλήματα ασφαλείας υψηλού κινδύνου',
            'Ζητήματα που επηρεάζουν όλους τους χρήστες',
            'Σημαντική οικονομική επίπτωση'
        ],
        escalation: 'Άμεση ειδοποίηση διευθυντικής ομάδας',
        impact: 'Κρίσιμη επίδραση στη λειτουργία της επιχείρησης'
    },
    [PriorityEnum.HIGH]: {
        use_cases: [
            'Σημαντική μείωση απόδοσης συστήματος',
            'Προβλήματα που επηρεάζουν πολλούς χρήστες',
            'Σφάλματα σε βασικές λειτουργίες',
            'Επαναλαμβανόμενα προβλήματα'
        ],
        escalation: 'Ενημέρωση υπεύθυνου τμήματος',
        impact: 'Σημαντική επίδραση στην καθημερινή λειτουργία'
    },
    [PriorityEnum.MEDIUM]: {
        use_cases: [
            'Μη κρίσιμα σφάλματα λειτουργικότητας',
            'Προβλήματα που επηρεάζουν μεμονωμένους χρήστες',
            'Αιτήματα για μικρές βελτιώσεις',
            'Ζητήματα με διαθέσιμη προσωρινή λύση'
        ],
        escalation: 'Τυπική διαδικασία επίλυσης',
        impact: 'Μέτρια επίδραση στη λειτουργία'
    },
    [PriorityEnum.LOW]: {
        use_cases: [
            'Αιτήματα για documentation',
            'Κοσμητικά προβλήματα διεπαφής',
            'Γενικές ερωτήσεις',
            'Προτάσεις βελτίωσης'
        ],
        escalation: 'Δεν απαιτείται κλιμάκωση',
        impact: 'Ελάχιστη επίδραση στη λειτουργία'
    }
};

const formatHours = (hours: number): string => {
    if (hours < 1) {
        return `${hours * 60} λεπτά`;
    }
    return `${hours} ${hours === 1 ? 'ώρα' : 'ώρες'}`;
};

const TicketPriorityGuide: React.FC = () => {
    const userStore = useUserStore();
    const companyId = userStore.user?.company_id;
    const { data: slas = [], isLoading } = useGetCompanySlaQuery(companyId);

    const priorities = useMemo(() => {
        return slas
            .map((sla: SLA) => ({
                priority_id: sla.priority.priority_id,
                priority_name: sla.priority.priority_name as PriorityName,
                description: sla.priority.description,
                response_time: formatHours(sla.response_time),
                resolution_time: formatHours(sla.resolution_time),
                ...priorityMetadata[sla.priority.priority_name as PriorityName],
                created_at: sla.created_at,
                updated_at: sla.updated_at
            }))
            .sort(sortByPriority);
    }, [slas]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (priorities.length === 0) {
        return <div>No SLA data available.</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
                <div className="mb-8 text-center">
                    <h1 className="text-3xl font-bold text-gray-900">
                        Επίπεδα Προτεραιότητας Αιτημάτων Υποστήριξης {slas[0]?.company?.company_name}
                    </h1>
                    <p className="mt-2 text-lg text-gray-600">
                        Οδηγός για την κατανόηση και χρήση των επιπέδων προτεραιότητας
                    </p>
                </div>

                <Card className="mb-12">
                    <CardHeader>
                        <CardTitle className="flex items-center">
                            <BarChart className="w-5 h-5 mr-2" />
                            Επισκόπηση Χρόνων Απόκρισης
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            {priorities.map((priority) => {
                                const Icon = getPriorityIcon(priority.priority_name);
                                const colors = getPriorityColorClasses(priority.priority_name);

                                return (
                                    <div
                                        key={priority.priority_id}
                                        className={`${colors.bg} ${colors.text} rounded-lg p-4`}
                                    >
                                        <div className="flex items-center mb-2">
                                            <Icon className={`w-5 h-5 mr-2 ${colors.text}`} />
                                            <span className="font-medium">{priority.priority_name}</span>
                                        </div>
                                        <div className="space-y-1 text-sm">
                                            <div className="flex items-center">
                                                <Clock className="w-4 h-4 mr-1" />
                                                <span>Απόκριση: {priority.response_time}</span>
                                            </div>
                                            <div className="flex items-center">
                                                <Clock className="w-4 h-4 mr-1" />
                                                <span>Επίλυση: {priority.resolution_time}</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </CardContent>
                </Card>

                <div className="grid grid-cols-1 gap-6">
                    {priorities.map((priority) => {
                        const Icon = getPriorityIcon(priority.priority_name);
                        const colors = getPriorityColorClasses(priority.priority_name);

                        return (
                            <Card key={priority.priority_id}>
                                <CardHeader className={`${colors.bg} rounded-t-lg`}>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-3">
                                            <div className={`p-2 ${colors.dark} rounded-lg`}>
                                                <Icon className={`w-6 h-6 text-white`} />
                                            </div>
                                            <div>
                                                <CardTitle>{priority.priority_name}</CardTitle>
                                                <p className={`text-sm ${colors.text}`}>
                                                    {priority.description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <div className="text-sm font-medium">Χρόνος Απόκρισης</div>
                                            <div className={`text-lg font-bold ${colors.text}`}>
                                                {priority.response_time}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardContent className="pt-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <h3 className="font-medium text-gray-900 mb-2">
                                                Περιπτώσεις Χρήσης
                                            </h3>
                                            <ul className="space-y-2">
                                                {priority.use_cases.map((useCase, index) => (
                                                    <li key={index} className="flex items-start">
                                                        <span className="mr-2">•</span>
                                                        <span className="text-gray-600">{useCase}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="space-y-4">
                                            <div>
                                                <h3 className="font-medium text-gray-900 mb-2">
                                                    Κλιμάκωση
                                                </h3>
                                                <p className="text-gray-600">{priority.escalation}</p>
                                            </div>
                                            <div>
                                                <h3 className="font-medium text-gray-900 mb-2">
                                                    Επίδραση
                                                </h3>
                                                <p className="text-gray-600">{priority.impact}</p>
                                            </div>
                                            <div>
                                                <h3 className="font-medium text-gray-900 mb-2">
                                                    Χρόνος Επίλυσης
                                                </h3>
                                                <p className="text-gray-600">{priority.resolution_time}</p>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TicketPriorityGuide;
