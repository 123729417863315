import { z } from 'zod';
import type { CategoryFormFields } from '@/types/category.types';

export const categorySchema = z.object({
    category_name: z.string()
        .min(1, 'Το όνομα της κατηγορίας είναι υποχρεωτικό')
        .max(150, 'Το όνομα δεν μπορεί να υπερβαίνει τους 150 χαρακτήρες'),
    description: z.string()
        .min(1, 'Η περιγραφή είναι υποχρεωτική')
}) satisfies z.ZodType<CategoryFormFields>;
