import { z } from 'zod';
import type { CreateCaseRequest } from '@/types/case.types';

export const createCaseSchema = z.object({
    title: z.string()
        .min(1, 'Ο τίτλος είναι υποχρεωτικός')
        .max(200, 'Ο τίτλος δεν μπορεί να υπερβαίνει τους 200 χαρακτήρες')
        .trim(),
    description: z.string()
        .min(1, 'Η περιγραφή είναι υποχρεωτική')
        .trim(),
    category_id: z.string().uuid('Μη έγκυρη κατηγορία'),
    priority_id: z.string().uuid('Μη έγκυρη προτεραιότητα'),
    assignee_id: z.string().uuid('Μη έγκυρος χρήστης').optional(),
    ticket_ids: z.array(z.string().uuid('Μη έγκυρο αίτημα'))
}) satisfies z.ZodType<CreateCaseRequest>;

export const caseFiltersSchema = z.object({
    page_number: z.number().optional().default(1),
    page_size: z.number().optional().default(10),
    category_id: z.string().uuid('Μη έγκυρη κατηγορία').optional(),
    priority_id: z.string().uuid('Μη έγκυρη προτεραιότητα').optional(),
    status_id: z.string().uuid('Μη έγκυρη κατάσταση').optional(),
});
