// hooks/usePermissions.ts
import useUserStore from '@/stores/useUserStore';

export const usePermissions = () => {
    const { user } = useUserStore();
    const permissions = user?.permissions || [];
    const hasPermission = (permission: string): boolean => {
        const resourceType = permission.split(':')[1];
        const managePermission = `manage:${resourceType}`;

        return permissions.includes(permission) || permissions.includes(managePermission);
    };

    const hasAnyPermission = (requiredPermissions: string[]): boolean => {
        return requiredPermissions.some(hasPermission);
    };

    const hasAllPermissions = (requiredPermissions: string[]): boolean => {
        return requiredPermissions.every(hasPermission);
    };

    return {
        hasPermission,
        hasAnyPermission,
        hasAllPermissions,
    };
};
