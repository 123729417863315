import React from 'react';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {useNavigate} from 'react-router-dom';
import {createUserSchema} from "@/schemas/user.schemas";
import {Building, ChevronLeft, Lock, Mail, Phone, Shield, User} from "lucide-react";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/Card";
import InputWithLabel from "@/components/ui/InputWithLabel";
import Button from "@/components/ui/Button";
import {ButtonSize, ButtonType, ButtonVariant} from "@/components/ui/Button/ButtonSize";
import RoleDropdown from "@/components/ui/DropdownWithLabel/RoleDropdown";
import CompanyDropdown from "@/components/ui/DropdownWithLabel/CompanyDropdown";
import DepartmentDropdown from "@/components/ui/DropdownWithLabel/DepartmentDropdown";
import {CreateUserFormData} from "@/types/user.types";
import Checkbox from "@/components/ui/Checkbox";
import {useCreateUserMutation} from "@/components/features/users/services/users.mutations";

export const CreateUser = () => {
    const navigate = useNavigate();
    const [blocked, setBlocked] = React.useState(true);
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm<CreateUserFormData>({
        resolver: zodResolver(createUserSchema),
        defaultValues: {
            blocked: false
        }
    });

    const selectedCompanyId = watch('company_id');

    const createUser = useCreateUserMutation();

    const onSubmit = (data: CreateUserFormData) => {
        createUser.mutate({
            ...data,
            blocked: blocked
        });
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <header className="bg-white border-b border-gray-200 sticky top-0 z-10">
                <div className="max-w-3xl mx-auto px-4 py-4">
                    <div className="flex items-center space-x-4">
                        <button
                            className="text-gray-500 hover:text-gray-700"
                            onClick={() => navigate(-1)}
                        >
                            <ChevronLeft className="w-6 h-6"/>
                        </button>
                        <div>
                            <h1 className="text-2xl font-semibold text-gray-900">
                                Προσθήκη Νέου Χρήστη
                            </h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Δημιουργία νέου λογαριασμού χρήστη
                            </p>
                        </div>
                    </div>
                </div>
            </header>

            <main className="max-w-3xl mx-auto px-4 py-6">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center">
                                <User className="w-5 h-5 mr-2"/>
                                Βασικές Πληροφορίες
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <InputWithLabel
                                    label="Όνομα"
                                    {...register('first_name')}
                                    error={errors.first_name?.message}
                                    required
                                    placeholder="Εισάγετε όνομα"
                                />
                                <InputWithLabel
                                    label="Επώνυμο"
                                    {...register('last_name')}
                                    error={errors.last_name?.message}
                                    required
                                    placeholder="Εισάγετε επώνυμο"
                                />
                            </div>
                            <InputWithLabel
                                label="Διεύθυνση Email"
                                type="email"
                                {...register('email_address')}
                                error={errors.email_address?.message}
                                required
                                placeholder="Εισάγετε email"
                                icon={Mail}
                            />
                            <InputWithLabel
                                label="Τηλέφωνο"
                                {...register('phone_number')}
                                error={errors.phone_number?.message}
                                placeholder="Εισάγετε τηλέφωνο"
                                icon={Phone}
                            />
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center">
                                <Building className="w-5 h-5 mr-2"/>
                                Στοιχεία Οργανισμού
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <CompanyDropdown
                                required
                                error={errors.company_id?.message}
                                onChange={(value) => setValue('company_id', value)}
                            />
                            <DepartmentDropdown
                                required
                                company_id={selectedCompanyId}
                                error={errors.department_id?.message}
                                onChange={(value) => setValue('department_id', value)}
                            />
                            <RoleDropdown
                                required
                                error={errors.role_id?.message}
                                value={watch('role_id')}  // Add this line
                                onChange={(value) => setValue('role_id', value)}
                            />
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center">
                                <Shield className="w-5 h-5 mr-2"/>
                                Πρόσβαση & Ασφάλεια
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="flex items-center justify-between">
                                <Checkbox
                                    label="Ενεργός Λογαριασμός"
                                    description="Οι ενεργοί λογαριασμοί έχουν πρόσβαση στο σύστημα"
                                    checked={!blocked}
                                    onChange={(e) => setBlocked(!e.target.checked)}
                                    size="md"
                                />
                            </div>
                            <InputWithLabel
                                label="Αρχικός Κωδικός"
                                type="password"
                                {...register('password')}
                                error={errors.password?.message}
                                required
                                placeholder="Εισάγετε κωδικό"
                                icon={Lock}
                            />
                        </CardContent>
                    </Card>

                    <div className="flex justify-end space-x-4">
                        <Button
                            type={ButtonType.BUTTON}
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.LG}
                            onClick={() => navigate(-1)}
                        >
                            Ακύρωση
                        </Button>
                        <Button
                            type={ButtonType.SUBMIT}
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LG}
                            disabled={createUser.isPending}
                        >
                            {createUser.isPending ? 'Δημιουργία...' : 'Δημιουργία Χρήστη'}
                        </Button>
                    </div>
                </form>
            </main>
        </div>
    );
};

export default CreateUser;
