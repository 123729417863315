import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Department, CreateDepartmentRequest } from '@/types/department.types';
import { createDepartmentSchema } from '@/schemas/department.schemas';
import Drawer from '@/components/ui/Drawer/Drawer';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonType, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { useCreateDepartmentMutation, useUpdateDepartmentMutation } from '@/components/features/departments/services/departments.mutations';

interface DepartmentDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    editingDepartment: Department | null;
    companyId: string;
}

const DepartmentDrawer = ({ isOpen, onClose, editingDepartment, companyId }: DepartmentDrawerProps) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<CreateDepartmentRequest>({
        resolver: zodResolver(createDepartmentSchema),
        defaultValues: {
            department_name: editingDepartment?.department_name || '',
            description: editingDepartment?.description || '',
            company_id: companyId,
        },
    });

    React.useEffect(() => {
        if (editingDepartment) {
            reset({
                department_name: editingDepartment.department_name,
                description: editingDepartment.description,
                company_id: companyId,
            });
        } else {
            reset({
                department_name: '',
                description: '',
                company_id: companyId,
            });
        }
    }, [editingDepartment, companyId, reset]);

    const createMutation = useCreateDepartmentMutation(companyId);
    const updateMutation = useUpdateDepartmentMutation(companyId);

    const onSubmit = async (data: CreateDepartmentRequest) => {
        try {
            if (editingDepartment) {
                await updateMutation.mutateAsync({
                    id: editingDepartment.department_id,
                    data: {
                        department_name: data.department_name,
                        description: data.description,
                        company_id: companyId,
                    }
                });
            } else {
                await createMutation.mutateAsync(data);
            }
            onClose();
            reset();
        } catch (error) {
            console.error('Failed to save department:', error);
        }
    };

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            title={editingDepartment ? 'Επεξεργασία Τμήματος' : 'Νέο Τμήμα'}
        >
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <InputWithLabel
                    label="Όνομα Τμήματος"
                    required
                    {...register('department_name')}
                    error={errors.department_name?.message}
                />

                <TextArea
                    label="Περιγραφή"
                    rows={4}
                    {...register('description')}
                    error={errors.description?.message}
                />

                <div className="flex justify-end space-x-4">
                    <Button
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.LG}
                        onClick={() => {
                            onClose();
                            reset();
                        }}
                        type={ButtonType.BUTTON}
                    >
                        Ακύρωση
                    </Button>
                    <Button
                        type={ButtonType.SUBMIT}
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LG}
                        disabled={createMutation.isPending || updateMutation.isPending}
                    >
                        {editingDepartment ? 'Αποθήκευση' : 'Δημιουργία'}
                    </Button>
                </div>
            </form>
        </Drawer>
    );
};

export default DepartmentDrawer;
