import React from 'react';
import { type ColumnDef } from '@tanstack/react-table';
import { Plus, Search} from 'lucide-react';
import ColumnTitle from '@/components/ui/DataTable/ColumnTitle';
import { TimeAgo } from '@/components/ui/TimeAgo/TimeAgo';
import DataTable from "@/components/ui/DataTable";
import { PageToolbar } from "@/components/layouts/PageLayout/PageToolbar";
import { PageTitle } from "@/components/layouts/PageLayout/PageTitle";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes.constants";
import { PriorityName } from "@/types/priority.types";
import { StatusName } from "@/types/status.types";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import { useGetTicketsQuery } from '@/components/features/tickets/services/tickets.queries';
import { useQueryClient } from '@tanstack/react-query';
import { TICKET_KEYS } from '@/components/features/tickets/services/tickets.queryKeys';
import type { Ticket, TicketFilters } from '@/types/ticket.types';
import useDebounce from "@/hooks/useDebounce";
import {ButtonVariant} from "@/components/ui/Button/ButtonSize";
import InputWithLabel from "@/components/ui/InputWithLabel";
import StatusDropdown from "@/components/ui/DropdownWithLabel/StatusDropdown";
import PriorityDropdown from "@/components/ui/DropdownWithLabel/PriorityDropdown";
import CategoryDropdown from "@/components/ui/DropdownWithLabel/CategoryDropdown";
import Button from "@/components/ui/Button";

interface TicketRow {
    id: string;
    number: string;
    title: string;
    status: StatusName;
    priority: PriorityName;
    company: string;
    assignee?: string;
    createdAt: string;
    updatedAt: string;
    category: string;
}

const mapTicketToRow = (ticket: Ticket): TicketRow => ({
    id: ticket.ticket_id,
    number: ticket.ticket_number.toString(),
    title: ticket.title,
    status: ticket.status.status_name as StatusName,
    priority: ticket.priority.priority_name as PriorityName,
    company: ticket.company.company_name,
    assignee: ticket.assignee ? `${ticket.assignee.first_name} ${ticket.assignee.last_name}` : undefined,
    category: ticket.category.category_name,
    createdAt: ticket.created_at,
    updatedAt: ticket.updated_at
});

const columns: ColumnDef<TicketRow, string>[] = [
    {
        id: 'number',
        header: ({ column }) => (
            <ColumnTitle title="Αριθμός" isSortable column={column} />
        ),
        accessorFn: (row) => row.number,
        cell: ({ row }) => (
            <div className="flex flex-col">
                <span className="font-medium text-gray-900">#{row.original.number}</span>
                <span className="text-sm text-gray-500 truncate max-w-md">
                    {row.original.title}
                </span>
            </div>
        ),
    },
    {
        id: 'status',
        header: ({ column }) => (
            <ColumnTitle title="Κατάσταση" isSortable column={column} />
        ),
        accessorFn: (row) => row.status,
        cell: ({ row }) => (
            <StatusBadge status={row.original.status} />
        ),
    },
    {
        id: 'priority',
        header: ({ column }) => (
            <ColumnTitle title="Προτεραιότητα" isSortable column={column} />
        ),
        accessorFn: (row) => row.priority,
        cell: ({ row }) => (
            <PriorityBadge priority={row.original.priority} />
        ),
    },
    {
        id: 'company',
        header: ({ column }) => (
            <ColumnTitle title="Εταιρεία" isSortable column={column} />
        ),
        accessorKey: 'company',
    },
    {
        id: 'assignee',
        header: ({ column }) => (
            <ColumnTitle title="Ανατέθηκε" isSortable column={column} />
        ),
        accessorKey: 'assignee',
        cell: ({ row }) => (
            row.original.assignee ||
            <span className="text-gray-400 italic">Μη ανατεθειμένο</span>
        ),
    },
    {
        id: 'category',
        header: ({ column }) => (
            <ColumnTitle title="Κατηγορία" isSortable column={column} />
        ),
        accessorKey: 'category',
    },
    {
        id: 'updatedAt',
        header: ({ column }) => (
            <ColumnTitle title="Τελευταία Ενημέρωση" isSortable column={column} />
        ),
        accessorFn: (row) => new Date(row.updatedAt).getTime().toString(),
        cell: ({ row }) => (
            <TimeAgo date={row.original.updatedAt} />
        ),
    },
];


const FiltersPanel: React.FC<{
    filters: TicketFilters;
    onFiltersChange: (filters: TicketFilters) => void;
    onSearchChange: (search: string) => void;
    searchTerm: string;
    onClear: () => void;
}> = ({ filters, onFiltersChange, onSearchChange, searchTerm, onClear }) => {
    const handleFilterChange = (key: keyof TicketFilters, value: string) => {
        onFiltersChange({
            ...filters,
            [key]: value || undefined,
            page_number: 1
        });
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <InputWithLabel
                    label="Αναζήτηση"
                    placeholder="Αναζήτηση με αριθμό, τίτλο..."
                    value={searchTerm}
                    onChange={(e) => onSearchChange(e.target.value)}
                    icon={Search}
                />
                <StatusDropdown
                    value={filters.status_id ?? ''}
                    onChange={(value) => handleFilterChange('status_id', value)}
                    placeholder="Όλες οι καταστάσεις"
                />
                <PriorityDropdown
                    value={filters.priority_id ?? ''}
                    onChange={(value) => handleFilterChange('priority_id', value)}
                    placeholder="Όλες οι προτεραιότητες"
                />
                <CategoryDropdown
                    value={filters.category_id ?? ''}
                    onChange={(value) => handleFilterChange('category_id', value)}
                    placeholder="Όλες οι κατηγορίες"
                />
            </div>
            <div className="flex justify-end space-x-2">
                <Button
                    variant={ButtonVariant.OUTLINE}
                    onClick={onClear}
                >
                    Καθαρισμός
                </Button>
            </div>
        </div>
    );
};

const TicketsList = () => {
    const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [filters, setFilters] = React.useState<TicketFilters>({
        page_number: 1,
        page_size: 100
    });

    const [searchTerm, setSearchTerm] = React.useState('');
    const debouncedSearch = useDebounce(searchTerm, 500);

    // Fetch tickets with filters
    const { data, isLoading, isError } = useGetTicketsQuery({
        ...filters,
        search: debouncedSearch || undefined
    });

    const tickets = React.useMemo(() => {
        if (!data?.data) return [];
        return data.data.map(mapTicketToRow);
    }, [data]);

    const handleFiltersChange = (newFilters: TicketFilters) => {
        setFilters(newFilters);
    };

    const handleClearFilters = () => {
        // Clear all filter values
        setFilters({
            page_number: 1,
            page_size: filters.page_size
        });
        setSearchTerm('');

        // Force clear dropdown values
        const dropdowns = document.querySelectorAll('select');
        dropdowns.forEach(dropdown => {
            dropdown.value = '';
        });
    };

    const getActiveFiltersCount = () => {
        return Object.entries(filters).filter(([key, value]) =>
            key !== 'page_number' &&
            key !== 'page_size' &&
            value !== undefined &&
            value !== ''
        ).length + (searchTerm ? 1 : 0);
    };

    const handleAddNew = () => {
        navigate(ROUTES.TICKETS.NEW);
    };

    const handleRefresh = async () => {
        await queryClient.refetchQueries({
            queryKey: [TICKET_KEYS.base]
        });
    };

    const handleRowClick = (row: TicketRow) => {
        navigate(ROUTES.TICKETS.DETAILS(row.id));
    };

    // const handlePageChange = (newPage: number) => {
    //     setFilters(prev => ({
    //         ...prev,
    //         page_number: newPage + 1
    //     }));
    // };
    //
    // const handlePageSizeChange = (newSize: number) => {
    //     setFilters(prev => ({
    //         ...prev,
    //         page_size: newSize,
    //         page_number: 1 // Reset to first page when changing page size
    //     }));
    // };

    if (isError) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <p className="text-error-500">Σφάλμα φόρτωσης δεδομένων</p>
            </div>
        );
    }

    return (
        <div className="h-full w-full mx-auto p-4 sm:p-6 lg:p-8">
            <PageTitle
                title="Αιτήματα"
                description="Διαχείριση και παρακολούθηση όλων των αιτημάτων υποστήριξης"
            />
            <PageToolbar
                primaryAction={{
                    label: 'Νέο Αίτημα',
                    icon: Plus,
                    onClick: handleAddNew
                }}
                onRefreshClick={handleRefresh}
                onFilterClick={() => setIsFiltersOpen(!isFiltersOpen)}
                activeFiltersCount={getActiveFiltersCount()}
            />
            {isFiltersOpen && (
                <div className="mb-4">
                    <FiltersPanel
                        filters={filters}
                        onFiltersChange={handleFiltersChange}
                        searchTerm={searchTerm}
                        onSearchChange={setSearchTerm}
                        onClear={handleClearFilters}
                    />
                </div>
            )}
            <DataTable
                columns={columns}
                data={tickets}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                noDataMessage={
                    Object.keys(filters).length > 2 || searchTerm
                        ? "Δεν βρέθηκαν αποτελέσματα με τα επιλεγμένα φίλτρα"
                        : "Δεν υπάρχουν αιτήματα προς εμφάνιση"
                }
                onRowClick={handleRowClick}
                isLoading={isLoading}
            />
        </div>
    );
};

export default TicketsList;
