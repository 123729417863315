import {AlertCircle, CheckCircle2, Clock, History, LucideIcon, PlayCircle, XCircle} from "lucide-react";

export type Status = {
    status_id: string;
    status_name: StatusName;
    description: string;
}

export const StatusEnum = {
    NEW: 'Νέο',
    OPEN: 'Ανοιχτό',
    PENDING: 'Σε Εκκρεμότητα',
    RESOLVED: 'Επιλυμένο',
    CLOSED: 'Κλειστό',
    CANCELLED: 'Ακυρωμένο',
    REOPENED: 'Επανανοίχτηκε'
} as const;

export type StatusName = typeof StatusEnum[keyof typeof StatusEnum];

// Status icons mapping similar to PRIORITY_ICONS
export const STATUS_ICONS: Record<StatusName, LucideIcon> = {
    [StatusEnum.NEW]: AlertCircle,
    [StatusEnum.OPEN]: PlayCircle,
    [StatusEnum.PENDING]: Clock,
    [StatusEnum.RESOLVED]: CheckCircle2,
    [StatusEnum.CLOSED]: XCircle,
    [StatusEnum.CANCELLED]: XCircle,
    [StatusEnum.REOPENED]: History
} as const;

// Status arrays for checking ticket states
export const OPEN_STATUSES = [
    StatusEnum.NEW,
    StatusEnum.OPEN,
    StatusEnum.PENDING,
    StatusEnum.REOPENED
] as const;

export const CLOSED_STATUSES = [
    StatusEnum.CLOSED,
    StatusEnum.CANCELLED
] as const;

export const REOPENABLE_STATUSES = [
    StatusEnum.CLOSED,
    StatusEnum.RESOLVED
] as const;

// Utility functions
// export const getStatusKey = (value: StatusName): keyof typeof StatusEnum => {
//     return Object.entries(StatusEnum).find(([_, val]) => val === value)?.[0] as keyof typeof StatusEnum;
// };

export const getStatusIcon = (status: StatusName): LucideIcon => {
    return STATUS_ICONS[status] || AlertCircle;
};

export const mapStatusToEnum = (status: string): StatusName => {
    const statusMap: Record<string, StatusName> = {
        'Νέο': StatusEnum.NEW,
        'Ανοιχτό': StatusEnum.OPEN,
        'Σε Εκκρεμότητα': StatusEnum.PENDING,
        'Επιλυμένο': StatusEnum.RESOLVED,
        'Κλειστό': StatusEnum.CLOSED,
        'Ακυρωμένο': StatusEnum.CANCELLED,
        'Επανανοίχτηκε': StatusEnum.REOPENED
    };

    return statusMap[status] || StatusEnum.NEW; // Fallback to NEW if status not found
};

export const isTicketOpen = (status: StatusName): boolean =>
    OPEN_STATUSES.includes(status as (typeof OPEN_STATUSES)[number]);

export const isTicketClosed = (status: StatusName): boolean =>
    CLOSED_STATUSES.includes(status as (typeof CLOSED_STATUSES)[number]);

export const canReopen = (status: StatusName): boolean =>
    REOPENABLE_STATUSES.includes(status as (typeof REOPENABLE_STATUSES)[number]);
