import { z } from 'zod';
import type { CompanyFormFields } from '@/types/company.types';

export const createCompanySchema = z.object({
  company_name: z.string()
      .min(3, 'Η επωνυμία πρέπει να έχει τουλάχιστον 3 χαρακτήρες')
      .max(150, 'Η επωνυμία δεν μπορεί να υπερβαίνει τους 150 χαρακτήρες'), // Updated to match backend
  address: z.string()
      .min(3, 'Η διεύθυνση πρέπει να έχει τουλάχιστον 3 χαρακτήρες')
      .max(150, 'Η διεύθυνση δεν μπορεί να υπερβαίνει τους 150 χαρακτήρες'), // Updated to match backend
  phone_number: z.string()
      .min(8, 'Το τηλέφωνο πρέπει να έχει τουλάχιστον 8 ψηφία')
      .max(20, 'Το τηλέφωνο δεν μπορεί να υπερβαίνει τους 20 χαρακτήρες') // Added to match backend
      .regex(/^[0-9+\-\s()]*$/, 'Μη έγκυρη μορφή τηλεφώνου'),
  email_address: z.string()
      .email('Μη έγκυρη διεύθυνση email')
}) satisfies z.ZodType<CompanyFormFields>;
