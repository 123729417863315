import React, { useState } from 'react';
import { Plus, Pencil, Trash2, Search, Timer, Building2 } from 'lucide-react';
import { type ColumnDef } from '@tanstack/react-table';
import DataTable from '@/components/ui/DataTable';
import Button from '@/components/ui/Button';
import {ButtonSize, ButtonType, ButtonVariant} from '@/components/ui/Button/ButtonSize';
import { Card } from '@/components/ui/Card';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';
import { Badge } from '@/components/ui/Badge/Badge';
import Drawer from "@/components/ui/Drawer/Drawer";

import {PriorityEnum} from "@/types/priority.types";

interface SLAPolicy {
    id: string;
    name: string;
    description: string;
    priorityId: string;
    responseTime: string;
    resolutionTime: string;
    company_id?: string;
    company?: {
        name: string;
        isClient: boolean;
    };
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
}

const SLAPoliciesManagement = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editingPolicy, setEditingPolicy] = useState<SLAPolicy | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    // Mock data for priorities dropdown
    const priorityOptions = [
        { value: PriorityEnum.URGENT, label: 'Επείγον' },
        { value: PriorityEnum.HIGH, label: 'Υψηλή' },
        { value: PriorityEnum.MEDIUM, label: 'Μεσαία' },
        { value: PriorityEnum.LOW, label: 'Χαμηλή' },
    ];

    // Mock data for companies dropdown
    const companyOptions = [
        { value: 'all', label: 'Όλες οι εταιρείες' },
        { value: 'company1', label: 'Cosmote' },
        { value: 'company2', label: 'Vodafone' },
        { value: 'company3', label: 'Wind' },
    ];

    // Mock data for SLA policies
    const slaPolicies: SLAPolicy[] = [
        {
            id: '1',
            name: 'Βασικό SLA Επείγοντος',
            description: 'Βασική πολιτική για επείγοντα αιτήματα',
            priorityId: PriorityEnum.URGENT,
            responseTime: '1 ώρα',
            resolutionTime: '4 ώρες',
            isActive: true,
            createdAt: '2024-02-15T10:00:00Z',
            updatedAt: '2024-02-15T10:00:00Z'
        },
        {
            id: '2',
            name: 'Cosmote Premium SLA',
            description: 'Ειδική πολιτική για Premium πελάτες',
            priorityId: PriorityEnum.HIGH,
            responseTime: '2 ώρες',
            resolutionTime: '8 ώρες',
            company_id: 'company1',
            company: {
                name: 'Cosmote',
                isClient: true
            },
            isActive: true,
            createdAt: '2024-02-15T10:00:00Z',
            updatedAt: '2024-02-15T10:00:00Z'
        },
        {
            id: '3',
            name: 'Τυπικό SLA Χαμηλής Προτεραιότητας',
            description: 'Πολιτική για αιτήματα χαμηλής προτεραιότητας',
            priorityId: PriorityEnum.LOW,
            responseTime: '24 ώρες',
            resolutionTime: '72 ώρες',
            isActive: true,
            createdAt: '2024-02-15T10:00:00Z',
            updatedAt: '2024-02-15T10:00:00Z'
        }
    ];

    const columns: ColumnDef<SLAPolicy, string>[] = [
        {
            id: 'name',
            header: 'Όνομα Πολιτικής',
            accessorKey: 'name',
        },
        {
            id: 'priority',
            header: 'Προτεραιότητα',
            accessorKey: 'priorityId',
            cell: ({ row }) => {
                const priorityLabel = priorityOptions.find(
                    p => p.value === row.original.priorityId
                )?.label;
                return (
                    <Badge
                        variant="outline"
                        className={`
                            ${row.original.priorityId === PriorityEnum.URGENT ? 'bg-red-50 text-red-700 border-red-200' :
                            row.original.priorityId === PriorityEnum.HIGH ? 'bg-orange-50 text-orange-700 border-orange-200' :
                                row.original.priorityId === PriorityEnum.MEDIUM ? 'bg-yellow-50 text-yellow-700 border-yellow-200' :
                                    'bg-blue-50 text-blue-700 border-blue-200'}
                        `}
                    >
                        {priorityLabel}
                    </Badge>
                );
            },
        },
        {
            id: 'response',
            header: 'Χρόνος Απόκρισης',
            accessorKey: 'responseTime',
            cell: ({ row }) => (
                <div className="flex items-center">
                    <Timer className="w-4 h-4 text-gray-400 mr-2" />
                    {row.original.responseTime}
                </div>
            ),
        },
        {
            id: 'resolution',
            header: 'Χρόνος Επίλυσης',
            accessorKey: 'resolutionTime',
            cell: ({ row }) => (
                <div className="flex items-center">
                    <Timer className="w-4 h-4 text-gray-400 mr-2" />
                    {row.original.resolutionTime}
                </div>
            ),
        },
        {
            id: 'company',
            header: 'Εταιρεία',
            accessorFn: (row) => row.company?.name ?? 'Όλες οι εταιρείες',
            cell: ({ row }) => (
                <div className="flex items-center">
                    <Building2 className="w-4 h-4 text-gray-400 mr-2" />
                    {row.original.company?.name ?? 'Όλες οι εταιρείες'}
                </div>
            ),
        },
        {
            id: 'actions',
            header: 'Ενέργειες',
            cell: ({ row }) => (
                <div className="flex gap-2">
                    <Button
                        variant={ButtonVariant.GHOST}
                        size={ButtonSize.SM}
                        onClick={() => handleEdit(row.original)}
                    >
                        <Pencil className="h-4 w-4" />
                    </Button>
                    <Button
                        variant={ButtonVariant.GHOST}
                        size={ButtonSize.SM}
                        className="text-red-600 hover:text-red-700"
                    >
                        <Trash2 className="h-4 w-4" />
                    </Button>
                </div>
            ),
        },
    ];

    const handleEdit = (policy: SLAPolicy) => {
        setEditingPolicy(policy);
        setIsDrawerOpen(true);
    };

    const handleAddNew = () => {
        setEditingPolicy(null);
        setIsDrawerOpen(true);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Handle form submission
        setIsDrawerOpen(false);
    };

    return (
        <div className="h-full w-full bg-gray-50">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">Πολιτικές SLA</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Διαχείριση πολιτικών επιπέδου εξυπηρέτησης (SLA)
                    </p>
                </div>

                <Card>
                    {/* Actions Bar */}
                    <div className="p-4 border-b border-gray-200 flex flex-col sm:flex-row justify-between gap-4">
                        <div className="relative">
                            <Search className="absolute left-3 top-1/2 -mt-2 h-4 w-4 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Αναζήτηση πολιτικής..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 w-full sm:w-96"
                            />
                        </div>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SM}
                            className="flex items-center"
                            onClick={handleAddNew}
                        >
                            <Plus className="h-4 w-4 mr-2" />
                            Νέα Πολιτική SLA
                        </Button>
                    </div>

                    {/* Table */}
                    <div className="p-4">
                        <DataTable
                            columns={columns}
                            data={slaPolicies.filter(policy =>
                                policy.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                policy.description.toLowerCase().includes(searchTerm.toLowerCase())
                            )}
                        />
                    </div>
                </Card>
            </div>

            {/* Drawer */}
            <Drawer
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                title={editingPolicy ? 'Επεξεργασία Πολιτικής SLA' : 'Νέα Πολιτική SLA'}
            >
                <form onSubmit={handleSubmit} className="space-y-6">
                    <InputWithLabel
                        label="Όνομα Πολιτικής"
                        required
                        defaultValue={editingPolicy?.name}
                        placeholder="π.χ. Premium SLA"
                    />

                    <TextArea
                        label="Περιγραφή"
                        required
                        rows={4}
                        defaultValue={editingPolicy?.description}
                        placeholder="Περιγράψτε την πολιτική SLA..."
                    />

                    <DropdownWithLabel
                        label="Προτεραιότητα"
                        options={priorityOptions}
                        required
                        value={editingPolicy?.priorityId}
                    />

                    <InputWithLabel
                        label="Χρόνος Απόκρισης"
                        required
                        defaultValue={editingPolicy?.responseTime}
                        placeholder="π.χ. 1 ώρα"
                    />

                    <InputWithLabel
                        label="Χρόνος Επίλυσης"
                        required
                        defaultValue={editingPolicy?.resolutionTime}
                        placeholder="π.χ. 4 ώρες"
                    />

                    <DropdownWithLabel
                        label="Εταιρεία (προαιρετικό)"
                        options={companyOptions}
                        value={editingPolicy?.company_id}
                        placeholder="Επιλέξτε εταιρεία..."
                    />

                    <div className="flex justify-end space-x-4">
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.LG}
                            onClick={() => setIsDrawerOpen(false)}
                        >
                            Ακύρωση
                        </Button>
                        <Button
                            type={ButtonType.SUBMIT}
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LG}
                        >
                            {editingPolicy ? 'Αποθήκευση' : 'Δημιουργία'}
                        </Button>
                    </div>
                </form>
            </Drawer>
        </div>
    );
};

export default SLAPoliciesManagement;
