import React from 'react';
import { getPriorityIcon, Priority, PriorityEnum, PriorityName } from '@/types/priority.types';
import { useGetPrioritiesQuery } from "@/components/features/priorities/services/priorities.queries";
import DropdownWithLabel from "@/components/ui/DropdownWithLabel/DropdownWithLabel";

interface PriorityDropdownProps {
    label?: string;
    value?: string;
    onChange?: (value: string) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    helperText?: string;
}

// Use PriorityName instead of PriorityEnum for the array type
const priorityOrder: PriorityName[] = [
    PriorityEnum.URGENT,  // 'Επείγον'
    PriorityEnum.HIGH,    // 'Υψηλή'
    PriorityEnum.MEDIUM,  // 'Μεσαία'
    PriorityEnum.LOW      // 'Χαμηλή'
];

const sortByPriority = (a: Priority, b: Priority) => {
    return priorityOrder.indexOf(a.priority_name as PriorityName) - priorityOrder.indexOf(b.priority_name as PriorityName);
};

const PriorityDropdown = React.forwardRef<HTMLSelectElement, PriorityDropdownProps>(
    ({
         label = 'Προτεραιότητα',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε προτεραιότητα',
         helperText,
         ...props
     }, ref) => {
        const { data: priorities, isLoading, isError } = useGetPrioritiesQuery();

        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange?.(event.target.value);
        };

        const priorityOptions = React.useMemo(() => {
            if (!priorities) return [];

            return priorities
                .sort(sortByPriority)
                .map((priority: Priority) => ({
                    value: priority.priority_id,
                    label: priority.priority_name,
                    icon: getPriorityIcon(priority.priority_name as PriorityName)
                }));
        }, [priorities]);

        if (isError) {
            return (
                <DropdownWithLabel
                    ref={ref}
                    label={label}
                    options={[]}
                    error="Σφάλμα φόρτωσης προτεραιοτήτων"
                    disabled
                    required={required}
                    {...props}
                />
            );
        }

        return (
            <DropdownWithLabel
                ref={ref}
                label={label}
                options={priorityOptions}
                value={value}
                onChange={handleChange}
                error={error}
                disabled={disabled || isLoading}
                required={required}
                placeholder={placeholder}
                helperText={helperText}
                {...props}
            />
        );
    }
);

PriorityDropdown.displayName = 'PriorityDropdown';

export default PriorityDropdown;
