import React, { useState } from 'react'
import {
    type ColumnDef,
    type ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    type SortingState,
    useReactTable,
} from '@tanstack/react-table'
import { Search, Inbox } from 'lucide-react'
import DatatablePagination from "./DatatablePagination"
import ColumnFilter from "./ColumnFilters"
import { Card } from '@/components/ui/Card'

/**
 * DataTable Component - A flexible and feature-rich table component built with TanStack Table.
 *
 * @example
 * // Basic usage
 * const columns: ColumnDef<User>[] = [
 *   { accessorKey: 'name', header: 'Name' },
 *   { accessorKey: 'email', header: 'Email' },
 * ];
 *
 * <DataTable
 *   columns={columns}
 *   data={users}
 *   title="Users List"
 * />
 *
 * @example
 * // With row click handler and custom initial state
 * <DataTable
 *   columns={columns}
 *   data={users}
 *   onRowClick={(user) => console.log(user)}
 *   initialState={{
 *     sorting: [{ id: 'name', desc: false }],
 *     pagination: { pageSize: 25 }
 *   }}
 * />
 *
 * @example
 * // Column definition with custom rendering and filter control
 * const columns: ColumnDef<User>[] = [
 *   {
 *     accessorKey: 'name',
 *     header: 'Name',
 *     enableColumnFilter: true, // Enable filter for this column only
 *     cell: ({ row }) => <div className="font-bold">{row.original.name}</div>
 *   },
 *   {
 *     accessorKey: 'status',
 *     header: 'Status',
 *     enableColumnFilter: false, // Disable filter for this column
 *     cell: ({ row }) => <StatusBadge status={row.original.status} />
 *   }
 * ];
 */
interface DataTableProps<TData, TValue> {
    /** Column definitions for the table */
    columns: Array<ColumnDef<TData, TValue>>
    /** Data to be displayed in the table */
    data: TData[]
    /** Shows loading skeleton when true */
    isLoading?: boolean
    /** Initial state for sorting and pagination */
    initialState?: {
        sorting?: SortingState
        pagination?: {
            pageIndex?: number
            pageSize?: number
        }
    }
    /** Additional classes for the table wrapper */
    className?: string
    /** Additional classes for table rows */
    rowClassName?: string
    /** Handler for row click events */
    onRowClick?: (row: TData) => void
    /** Custom component to show when no results found */
    noResults?: React.ReactNode
    /** Message to show when table is empty */
    noDataMessage?: string
    /** Enable/disable global search functionality */
    enableGlobalFilter?: boolean
    /**
     * Enable/disable column filters globally.
     * Can be overridden per column using enableColumnFilter in column definition
     */
    enableColumnFilters?: boolean
    /** Table title */
    title?: string
    /** Table description */
    description?: string
}

/**
 * Table loading skeleton component
 * @internal
 */
const TableSkeleton = () => (
    <div className="animate-pulse">
        <div className="h-10 bg-gray-200 rounded mb-4"/>
        {[...Array(5)].map((_, i) => (
            <div key={i} className="h-16 bg-gray-100 rounded mb-2"/>
        ))}
    </div>
)

/**
 * Empty state component
 * @internal
 */
const EmptyState = ({ message }: { message: string }) => (
    <div className="flex flex-col items-center justify-center py-12 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
        <Inbox className="h-12 w-12 text-gray-400 mb-4"/>
        <h3 className="text-lg font-medium text-gray-900 mb-1">
            Δεν βρέθηκαν αποτελέσματα
        </h3>
        <p className="text-sm text-gray-500">{message}</p>
    </div>
)

export function DataTable<TData, TValue>({
    columns,
    data,
    isLoading = false,
    initialState = {},
    className = '',
    rowClassName = '',
    onRowClick,
    noDataMessage = 'Δεν βρέθηκαν αποτελέσματα',
    enableGlobalFilter = false,
    enableColumnFilters = false,
    title,
    description,
}: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>(initialState.sorting || [])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnFilters,
            globalFilter,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: 10,
                ...initialState.pagination,
            },
            sorting: initialState.sorting,
        },
    })

    return (
        <div className="bg-white rounded-lg shadow">
        <Card className={className}>
            {/* Header */}
            {(title || description || enableGlobalFilter) && (
                <div className="p-4 border-b border-gray-200">
                    <div className="flex items-center justify-between gap-4">
                        {(title || description) && (
                            <div>
                                {title && <h2 className="text-lg font-semibold">{title}</h2>}
                                {description && <p className="text-sm text-gray-500">{description}</p>}
                            </div>
                        )}
                        {enableGlobalFilter && (
                            <div className="relative flex-1 max-w-md">
                                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400"/>
                                <input
                                    type="text"
                                    placeholder="Αναζήτηση..."
                                    value={globalFilter}
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg 
                                             focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Table Content */}
            <div className="overflow-x-auto">
                {isLoading ? (
                    <div className="p-4">
                        <TableSkeleton/>
                    </div>
                ) : table.getRowModel().rows.length === 0 ? (
                    <div className="p-4">
                        <EmptyState message={noDataMessage}/>
                    </div>
                ) : (
                    <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className="px-6 py-3 text-left text-sm font-medium text-gray-500"
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {enableColumnFilters && header.column.getCanFilter() && (
                                                        <div className="mt-2">
                                                            <ColumnFilter column={header.column}/>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    key={row.id}
                                    onClick={() => onRowClick?.(row.original)}
                                    className={`
${onRowClick ? 'cursor-pointer' : ''}
${row.index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
hover:bg-gray-100
transition-colors
    ${rowClassName}
    `}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            key={cell.id}
                                            className="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            {/* Pagination */}
            {!isLoading && table.getRowModel().rows.length > 0 && (
                <DatatablePagination table={table}/>
            )}
        </Card>
        </div>
    )
}

/**
 * Usage Notes:
 *
 * 1. Column Filtering:
 * - Global control: Use enableColumnFilters prop
 * - Per-column control: Use enableColumnFilter in column definition
 * - Priority: Column-level setting > Global prop > Default (enabled)
 *
 * @example
 * const columns = [
 *   {
 *     accessorKey: 'name',
 *     enableColumnFilter: true // This column will always have a filter
 *   },
 *   {
 *     accessorKey: 'email',
 *     enableColumnFilter: false // This column never has a filter
 *   }
 * ];
 *
 * 2. Sorting:
 * - Enabled by default for all columns
 * - Control using enableSorting in column definition
 *
 * @example
 * const columns = [
 *   {
 *     accessorKey: 'name',
 *     enableSorting: false // Disable sorting for this column
 *   }
 * ];
 *
 * 3. Custom Cell Rendering:
 * @example
 * const columns = [
 *   {
 *     accessorKey: 'status',
 *     cell: ({ row }) => (
 *       <StatusBadge status={row.original.status} />
 *     )
 *   }
 * ];
 *
 * 4. Row Click Handling:
 * @example
 * <DataTable
 *   onRowClick={(row) => {
 *     console.log('Clicked row:', row);
 *     // Navigate or perform action
 *   }}
 * />
 */

export default DataTable
