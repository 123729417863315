import React from 'react';
import { getPriorityColorClasses } from '@/constants/colors.constants';
import { cn } from '@/lib/utils';
import { PriorityName} from '@/types/priority.types';

interface PriorityBadgeProps {
    priority: PriorityName;
    className?: string;
}

export const PriorityBadge: React.FC<PriorityBadgeProps> = ({ priority, className }) => {
    const colors = getPriorityColorClasses(priority);

    return (
        <span
            className={cn(
                'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border',
                colors?.bg,
                colors?.text,
                colors?.border,
                className
            )}
        >
            {priority}
        </span>
    );
};

export default PriorityBadge;
