import logo from '@/assets/logo_color.svg'
import {Link} from 'react-router-dom'
import Button from "@/components/ui/Button";
import {ButtonVariant} from "@/components/ui/Button/ButtonSize";

export default function NotFound() {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 px-4">
            <Link to="/" className="mb-8">
                <span className="sr-only">Η Εταιρεία σας</span>
                <img
                    className="h-12 w-auto"
                    src={logo}
                    alt="Company Logo"
                />
            </Link>

            <div className="text-center">
                {/*<p className="text-sm font-medium text-primary-600">404</p>*/}
                <h1 className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                    Η σελίδα δεν βρέθηκε
                </h1>
                <p className="mt-4 text-base text-gray-500">
                    Λυπούμαστε, η σελίδα που ψάχνετε δεν υπάρχει.
                </p>
                <Button
                    variant={ButtonVariant.OUTLINE}
                    className="mt-8"
                >
                    <Link to="/">
                        {/*<HomeIcon className="mr-2 h-4 w-4" />*/}
                        Επιστροφή στην αρχική
                    </Link>
                </Button>
            </div>
        </div>
    );
}
