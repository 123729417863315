// src/components/ui/Drawer/Drawer.tsx
import React from 'react';
import { X } from 'lucide-react';
import  Button  from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';

interface DrawerProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({
                                           isOpen,
                                           onClose,
                                           title,
                                           children
                                       }) => {
    return (
        <>
            {/* Overlay */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 transition-opacity z-40"
                    onClick={onClose}
                />
            )}

            {/* Drawer */}
            <div className={`
                fixed right-0 top-0 h-full w-full sm:w-96 bg-white shadow-xl z-50
                transform transition-transform duration-300 ease-in-out
                ${isOpen ? 'translate-x-0' : 'translate-x-full'}
            `}>
                <div className="h-full flex flex-col">
                    {/* Header */}
                    <div className="px-4 py-6 border-b border-gray-200">
                        <div className="flex items-center justify-between">
                            <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
                            <Button
                                variant={ButtonVariant.GHOST}
                                size={ButtonSize.SM}
                                onClick={onClose}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <X className="h-5 w-5" />
                            </Button>
                        </div>
                    </div>

                    {/* Content */}
                    <div className="flex-1 overflow-y-auto p-4">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Drawer;
