import { client } from "@/lib/httpClient";
import { ROUTES } from "@/constants/routes.constants";
import { Role } from "@/types/role.types";

export const rolesApi = {
    getAll: async (): Promise<Role[]> => {
        const response = await client.get<Role[]>(ROUTES.ROLES.API.BASE);
        return response.data ?? [];
    },
};
