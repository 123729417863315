import React from 'react';
import {
    AlertCircle,
    ArrowDown,
    ArrowUp,
    Building2,
    Clock,
    FileText,
    Filter,
    MessageSquare,
    Plus,
    Search,
    Timer,
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import { Badge } from "@/components/ui/Badge/Badge";
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { TimeAgo } from "@/components/ui/TimeAgo/TimeAgo";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import CategoryDropdown from "@/components/ui/DropdownWithLabel/CategoryDropdown";
import PriorityDropdown from "@/components/ui/DropdownWithLabel/PriorityDropdown";
import InputWithLabel from '@/components/ui/InputWithLabel';
import {PriorityEnum} from "@/types/priority.types";
import {StatusEnum} from "@/types/status.types";

export default function ClientAdministratorDashboard() {
    // State for quick ticket creation
    const [showQuickCreate, setShowQuickCreate] = React.useState(false);
    const [quickTicket, setQuickTicket] = React.useState({
        title: '',
        category_id: '',
        priority_id: ''
    });

    const stats = {
        totalTickets: {
            count: 145,
            trend: 12,
            trendUp: true
        },
        activeTickets: {
            count: 28,
            trend: -3,
            trendUp: false
        },
        avgResponseTime: {
            value: "2.5 ώρες",
            trend: -10,
            trendUp: false
        },
        slaCompliance: {
            value: "94%",
            trend: 2,
            trendUp: true
        }
    };

    const departmentStats = [
        {
            name: "Τεχνικό",
            tickets: 45,
            percentage: 31,
            resolved: 38
        },
        {
            name: "Οικονομικό",
            tickets: 32,
            percentage: 22,
            resolved: 28
        }
    ];

    const recentTickets = [
        {
            id: "TK-2345",
            title: "Πρόβλημα πρόσβασης στο portal πελατών",
            status: StatusEnum.OPEN,
            priority: PriorityEnum.HIGH,
            department: "Τεχνικό",
            createdAt: "2024-02-15T10:30:00Z",
            lastUpdate: "2024-02-15T11:30:00Z"
        }
    ];

    const recentActivity = [
        {
            id: "ACT-1",
            type: "comment",
            ticketId: "TK-2345",
            user: "Μαρία Κ.",
            description: "Προστέθηκε νέο σχόλιο",
            timestamp: "2024-02-15T11:30:00Z"
        }
    ];

    return (
        <div className="h-full w-full bg-gray-100">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                {/* Header Section */}
                <div className="mb-8">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">Επισκόπηση Εταιρείας</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Διαχείριση και παρακολούθηση αιτημάτων
                            </p>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.SM}
                                onClick={() => setShowQuickCreate(!showQuickCreate)}
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Νέο Αίτημα
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Quick Ticket Creation Form */}
                {showQuickCreate && (
                    <Card className="mb-8">
                        <CardHeader>
                            <CardTitle className="text-lg">Γρήγορη Δημιουργία Αιτήματος</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <InputWithLabel
                                    label="Τίτλος"
                                    required
                                    placeholder="Σύντομη περιγραφή του ζητήματος"
                                    value={quickTicket.title}
                                    onChange={(e) => setQuickTicket(prev => ({
                                        ...prev,
                                        title: e.target.value
                                    }))}
                                />
                                <CategoryDropdown
                                    required
                                    value={quickTicket.category_id}
                                    onChange={(value) => setQuickTicket(prev => ({
                                        ...prev,
                                        category_id: value
                                    }))}
                                />
                                <PriorityDropdown
                                    required
                                    value={quickTicket.priority_id}
                                    onChange={(value) => setQuickTicket(prev => ({
                                        ...prev,
                                        priority_id: value
                                    }))}
                                />
                            </div>
                            <div className="mt-4 flex justify-end">
                                <Button
                                    variant={ButtonVariant.PRIMARY}
                                    size={ButtonSize.SM}
                                >
                                    Δημιουργία
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                )}

                {/* Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Total Tickets */}
                    <Card className="bg-blue-50 border-blue-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-blue-700">Συνολικά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-blue-900">
                                            {stats.totalTickets.count}
                                        </p>
                                        <p className={`ml-2 text-sm ${stats.totalTickets.trendUp ? 'text-error-600' : 'text-success-600'}`}>
                                            {stats.totalTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.totalTickets.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-blue-100 rounded-lg">
                                    <FileText className="h-6 w-6 text-blue-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Active Tickets */}
                    <Card className="bg-green-50 border-green-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-green-700">Ενεργά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-green-900">
                                            {stats.activeTickets.count}
                                        </p>
                                        <p className={`ml-2 text-sm ${!stats.activeTickets.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.activeTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {Math.abs(stats.activeTickets.trend)}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-green-100 rounded-lg">
                                    <MessageSquare className="h-6 w-6 text-green-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Response Time */}
                    <Card className="bg-purple-50 border-purple-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-purple-700">Μέσος Χρόνος Απόκρισης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-purple-900">
                                            {stats.avgResponseTime.value}
                                        </p>
                                        <p className={`ml-2 text-sm ${!stats.avgResponseTime.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.avgResponseTime.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.avgResponseTime.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-purple-100 rounded-lg">
                                    <Timer className="h-6 w-6 text-purple-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Compliance */}
                    <Card className="bg-yellow-50 border-yellow-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-yellow-700">SLA Συμμόρφωση</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-yellow-900">
                                            {stats.slaCompliance.value}
                                        </p>
                                        <p className={`ml-2 text-sm ${stats.slaCompliance.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.slaCompliance.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.slaCompliance.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-yellow-100 rounded-lg">
                                    <Clock className="h-6 w-6 text-yellow-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                    {/* Department Distribution */}
                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center justify-between">
                                <span>Κατανομή ανά Τμήμα</span>
                                <Badge variant="outline" className="ml-2">
                                    {departmentStats.length} τμήματα
                                </Badge>
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-6">
                                {departmentStats.map((dept) => (
                                    <div key={dept.name}>
                                        <div className="flex justify-between items-center mb-2">
                                            <div className="flex items-center">
                                                <Building2 className="h-5 w-5 text-gray-400 mr-2" />
                                                <span className="font-medium">{dept.name}</span>
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {dept.tickets} αιτήματα
                                            </div>
                                        </div>
                                        <div className="w-full bg-gray-200 rounded-full h-2">
                                            <div
                                                className="bg-blue-600 h-2 rounded-full"
                                                style={{ width: `${dept.percentage}%` }}
                                            />
                                        </div>
                                        <div className="mt-1 text-sm text-gray-500">
                                            {dept.resolved} επιλυμένα
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* Recent Activity */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Πρόσφατη Δραστηριότητα</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flow-root">
                                <ul className="-mb-8">
                                    {recentActivity.map((activity) => (
                                        <li key={activity.id}>
                                            <div className="relative pb-8">
                                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                                <div className="relative flex space-x-3">
                                                    <div>
                                                        <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
                                                            <MessageSquare className="h-5 w-5 text-white" />
                                                        </span>
                                                    </div>
                                                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                        <div>
                                                            <p className="text-sm text-gray-500">
                                                                {activity.description} στο{' '}
                                                                <span className="font-medium text-gray-900">
                                                                    #{activity.ticketId}
                                                                </span>
                                                                {' '}από{' '}
                                                                <span className="font-medium text-gray-900">
                                                                    {activity.user}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                            <TimeAgo date={activity.timestamp} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Recent Tickets */}
                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center justify-between">
                                <span>Πρόσφατα Αιτήματα</span>
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                >
                                    Προβολή Όλων
                                </Button>
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {recentTickets.map((ticket) => (
                                    <div key={ticket.id} className="flex items-start justify-between border-b pb-4">
                                        <div className="space-y-1">
                                            <div className="flex items-center space-x-2">
                                                <span className="font-medium">#{ticket.id}</span>
                                                <PriorityBadge priority={ticket.priority} />
                                                <StatusBadge status={ticket.status} />
                                            </div>
                                            <p className="text-sm text-gray-900">{ticket.title}</p>
                                            <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                <Building2 className="h-4 w-4" />
                                                <span>{ticket.department}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <TimeAgo date={ticket.createdAt} className="text-sm text-gray-500" />
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                size={ButtonSize.SM}
                                                className="mt-2"
                                            >
                                                Προβολή
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Performance */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Απόδοση SLA</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {/* SLA Warning */}
                                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                                    <div className="flex items-center">
                                        <AlertCircle className="h-5 w-5 text-yellow-400" />
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-yellow-800">
                                                Επερχόμενες Λήξεις SLA
                                            </h3>
                                            <p className="text-sm text-yellow-700 mt-1">
                                                3 αιτήματα πλησιάζουν στη λήξη του SLA
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* SLA Stats */}
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="bg-green-50 rounded-lg p-4">
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-green-700">
                                                Εντός SLA
                                            </span>
                                            <span className="text-lg font-semibold text-green-700">
                                                89%
                                            </span>
                                        </div>
                                    </div>
                                    <div className="bg-red-50 rounded-lg p-4">
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-red-700">
                                                Εκτός SLA
                                            </span>
                                            <span className="text-lg font-semibold text-red-700">
                                                11%
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* SLA Response Time */}
                                <div className="bg-gray-50 rounded-lg p-4">
                                    <div className="mb-2 text-sm font-medium text-gray-700">
                                        Μέσος Χρόνος Απόκρισης ανά Προτεραιότητα
                                    </div>
                                    <div className="space-y-2">
                                        {/* High Priority */}
                                        <div>
                                            <div className="flex justify-between text-sm mb-1">
                                                <span>Υψηλή</span>
                                                <span>1.2 ώρες</span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div className="bg-green-600 h-2 rounded-full w-[85%]" />
                                            </div>
                                        </div>
                                        {/* Medium Priority */}
                                        <div>
                                            <div className="flex justify-between text-sm mb-1">
                                                <span>Μεσαία</span>
                                                <span>3.5 ώρες</span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div className="bg-green-600 h-2 rounded-full w-[92%]" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}

