import {ProductTypeFormFields} from "@/types/productTypes.types";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {productTypesApi} from "@/components/features/productTypes/services/productTypes.api";
import {PRODUCT_TYPE_KEYS} from "@/components/features/productTypes/services/productTypes.queryKeys";

export const useCreateProductTypeMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: ProductTypeFormFields) => productTypesApi.create(data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: PRODUCT_TYPE_KEYS.list});
        }
    });
};

export const useUpdateProductTypeMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id, data }: { id: string; data: ProductTypeFormFields }) =>
            productTypesApi.update(id, data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: PRODUCT_TYPE_KEYS.list});
        }
    });
};

export const useDeleteProductTypeMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: string) => productTypesApi.delete(id),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: PRODUCT_TYPE_KEYS.list});
        }
    });
};
