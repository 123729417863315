import { z } from 'zod';
import type { ProductFormFields } from '@/types/product.types';

export const productSchema = z.object({
    product_code: z.string()
        .min(2, 'Ο κωδικός πρέπει να έχει τουλάχιστον 2 χαρακτήρες')
        .max(50, 'Ο κωδικός δεν μπορεί να υπερβαίνει τους 50 χαρακτήρες'),
    product_name: z.string()
        .min(2, 'Το όνομα πρέπει να έχει τουλάχιστον 2 χαρακτήρες')
        .max(150, 'Το όνομα δεν μπορεί να υπερβαίνει τους 150 χαρακτήρες'),
    product_type_id: z.string().uuid('Μη έγκυρος τύπος προϊόντος'),
    description: z.string()
        .min(2, 'Η περιγραφή πρέπει να έχει τουλάχιστον 2 χαρακτήρες')
        .max(500, 'Η περιγραφή δεν μπορεί να υπερβαίνει τους 500 χαρακτήρες'),
    price: z.number()
        .min(0, 'Η τιμή δεν μπορεί να είναι αρνητική')
        .max(1000000, 'Η τιμή είναι πολύ μεγάλη'),
    is_active: z.boolean()
}) satisfies z.ZodType<ProductFormFields>;
