import { useMemo } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { type Table } from '@tanstack/react-table'

interface DatatablePaginationProps<TData> {
    table: Table<TData>
}

export default function DatatablePagination<TData>({
    table,
}: DatatablePaginationProps<TData>) {
    const totalPages = table.getPageCount()
    const currentPage = table.getState().pagination.pageIndex + 1
    const maxPagesToShow = 7

    const pagesToShow = useMemo(() => {
        if (totalPages <= maxPagesToShow) {
            return Array.from({ length: totalPages }, (_, index) => index + 1)
        }

        const startPage = Math.max(currentPage - 2, 1)
        const endPage = Math.min(currentPage + 2, totalPages)
        let pages: (number | string)[] = []

        pages = Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i,
        )

        if (startPage > 2) pages = [1, '...', ...pages]
        if (endPage < totalPages - 1) pages = [...pages, '...', totalPages]

        return pages
    }, [currentPage, totalPages])

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            {/* Mobile version */}
            <div className="flex flex-1 justify-between sm:hidden">
                <button
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-50 disabled:opacity-50"
                >
                    Previous
                </button>
                <button
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-50 disabled:opacity-50"
                >
                    Next
                </button>
            </div>

            {/* Desktop version */}
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="flex items-center gap-2">
                    <p className="text-sm text-gray-700">Results per page:</p>
                    <select
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => table.setPageSize(Number(e.target.value))}
                        className="rounded-md border-gray-300 py-1 pl-2 pr-8 text-sm focus:border-primary-500 focus:ring-primary-500"
                    >
                        {[10, 20, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm">
                        <button
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeft className="h-5 w-5" />
                        </button>

                        {pagesToShow.map((page, index) => {
                            if (page === '...') {
                                return (
                                    <span
                                        key={`ellipsis-${index}`}
                                        className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 ring-1 ring-inset ring-gray-300"
                                    >
                                        ...
                                    </span>
                                )
                            }

                            const isCurrentPage = currentPage === page
                            return (
                                <button
                                    key={`page-${page}`}
                                    onClick={() => table.setPageIndex((page as number) - 1)}
                                    className={`
                                        relative inline-flex items-center px-4 py-2 text-sm font-medium 
                                        ${isCurrentPage
                                            ? 'z-10 bg-primary-600 text-white focus:z-20'
                                            : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                                        }
                                    `}
                                >
                                    {page}
                                </button>
                            )
                        })}

                        <button
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRight className="h-5 w-5" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    )
}
