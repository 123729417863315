import React from 'react';
import { AlertCircle, CheckCircle2, Clock } from 'lucide-react';
import { SLA, SLAStatus } from '@/types/sla.types';

interface SLAStatusDisplayProps {
    ticket: {
        created_at: string;
        responded_at: string | null;
        closed_at: string | null;
        sla: SLA;
    };
}

const StatusColors = {
    [SLAStatus.MET]: 'text-green-600',
    [SLAStatus.PENDING]: 'text-gray-500',
    [SLAStatus.BREACHED]: 'text-red-600'
} as const;

const StatusIcons = {
    [SLAStatus.MET]: CheckCircle2,
    [SLAStatus.PENDING]: Clock,
    [SLAStatus.BREACHED]: AlertCircle
} as const;

const formatTimeInGreek = (hours: number): string => {
    if (hours < 1/60) { // less than a minute
        return 'μόλις τώρα';
    }
    if (hours < 1) {
        const minutes = Math.round(hours * 60);
        return `${minutes} ${minutes === 1 ? 'λεπτό' : 'λεπτά'}`;
    }
    if (hours < 2) {
        return '1 ώρα';
    }
    return `${Math.round(hours)} ώρες`;
};

const calculateTimeDifference = (start: string, end: string | null, limit: number): {
    status: SLAStatus,
    displayText: string,
    subText?: string
} => {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : new Date();
    const diffInHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);

    // If there's no end date (still pending)
    if (!end) {
        if (diffInHours < limit) {
            const remainingHours = limit - diffInHours;
            return {
                status: SLAStatus.PENDING,
                displayText: 'Σε εξέλιξη',
                subText: `Απομένουν ${formatTimeInGreek(remainingHours)}`
            };
        }
        return {
            status: SLAStatus.BREACHED,
            displayText: 'Εκτός SLA',
            subText: `Καθυστέρηση ${formatTimeInGreek(diffInHours - limit)}`
        };
    }

    // If completed
    const timeText = formatTimeInGreek(diffInHours);
    if (diffInHours <= limit) {
        return {
            status: SLAStatus.MET,
            displayText: timeText
        };
    }

    return {
        status: SLAStatus.BREACHED,
        displayText: timeText,
        subText: `Καθυστέρηση ${formatTimeInGreek(diffInHours - limit)}`
    };
};

export const SLAStatusDisplay: React.FC<SLAStatusDisplayProps> = ({ ticket }) => {
    const responseTime = calculateTimeDifference(
        ticket.created_at,
        ticket.responded_at,
        ticket.sla.response_time
    );

    const resolutionTime = calculateTimeDifference(
        ticket.created_at,
        ticket.closed_at,
        ticket.sla.resolution_time
    );

    const renderStatusRow = (
        status: SLAStatus,
        label: string,
        displayText: string,
        subText?: string
    ) => {
        const IconComponent = StatusIcons[status];
        const colorClass = StatusColors[status];

        return (
            <div className="flex items-center justify-between">
                <div className="flex items-center text-sm text-gray-700 font-medium">
                    <IconComponent className={`w-5 h-5 mr-2 ${colorClass}`} />
                    {label}
                </div>
                <div className="text-right">
                    <div className={`text-sm font-medium ${colorClass}`}>
                        {displayText}
                    </div>
                    {subText && (
                        <div className="text-xs text-gray-500">
                            {subText}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="bg-white rounded-lg shadow p-4 sm:p-6">
            <h2 className="text-lg font-semibold mb-4">Κατάσταση SLA</h2>
            <div className="space-y-4">
                {renderStatusRow(
                    responseTime.status,
                    "Πρώτη Απάντηση",
                    responseTime.displayText,
                    responseTime.subText
                )}
                {renderStatusRow(
                    resolutionTime.status,
                    "Χρόνος Επίλυσης",
                    resolutionTime.displayText,
                    resolutionTime.subText
                )}
            </div>
        </div>
    );
};
