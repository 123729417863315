// src/components/ui/PageToolbar/PageToolbar.tsx
import React, { useState } from 'react';
import { Filter, RefreshCcw, ChevronDown, type LucideIcon } from 'lucide-react';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';

interface Action {
  label: string;
  icon?: LucideIcon;
  onClick: () => void;
  variant?: ButtonVariant;
  show?: boolean;
}

interface PageToolbarProps {
  primaryAction?: Action;
  secondaryActions?: Action[];
  showFilters?: boolean;
  onFilterClick?: () => void;
  showRefresh?: boolean;
  onRefreshClick?: () => void;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  activeFiltersCount?: number;
}

export const PageToolbar = ({
  primaryAction,
  secondaryActions = [],
  showFilters = true,
  onFilterClick,
  showRefresh = true,
  onRefreshClick,
  leftContent,
  rightContent,
}: PageToolbarProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const filteredActions = secondaryActions.filter(action => action.show !== false);

  return (
    <div className="mb-6 flex flex-col sm:flex-row justify-between gap-4">
      {/* Left Side */}
      <div className="flex flex-wrap gap-2">
        {leftContent}
        
        {showFilters && (
          <Button
            variant={ButtonVariant.OUTLINE}
            size={ButtonSize.SM}
            onClick={onFilterClick}
            className="flex items-center"
          >
            <Filter className="w-4 h-4 mr-2" />
            Φίλτρα
          </Button>
        )}
        
        {showRefresh && (
          <Button
            variant={ButtonVariant.OUTLINE}
            size={ButtonSize.SM}
            onClick={onRefreshClick}
            className="flex items-center"
          >
            <RefreshCcw className="w-4 h-4 mr-2" />
            Ανανέωση
          </Button>
        )}
      </div>

      {/* Right Side */}
      <div className="flex gap-2">
        {rightContent}

        {filteredActions.length > 0 && (
          <div className="relative z-20">
            <Button
              variant={ButtonVariant.OUTLINE}
              size={ButtonSize.SM}
              onClick={() => filteredActions.length === 1 
                ? filteredActions[0].onClick()
                : setIsMenuOpen(!isMenuOpen)
              }
              className="flex items-center"
            >
              {filteredActions.length === 1 ? (
                <>
                  {filteredActions[0].icon && 
                    React.createElement(filteredActions[0].icon, {
                      className: "w-4 h-4 mr-2"
                    })
                  }
                  {filteredActions[0].label}
                </>
              ) : (
                <>
                  Ενέργειες
                  <ChevronDown className="w-4 h-4 ml-2" />
                </>
              )}
            </Button>

            {isMenuOpen && filteredActions.length > 1 && (
              <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu">
                  {filteredActions.map((action, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        action.onClick();
                        setIsMenuOpen(false);
                      }}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                      role="menuitem"
                    >
                      {action.icon && 
                        React.createElement(action.icon, {
                          className: "w-4 h-4 mr-2"
                        })
                      }
                      {action.label}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {primaryAction && (
            <Button
                variant={primaryAction.variant ?? ButtonVariant.PRIMARY}
                size={ButtonSize.SM}
                onClick={primaryAction.onClick}
                className="flex items-center"
            >
              {primaryAction.icon &&
                  React.createElement(primaryAction.icon, {
                    className: "w-4 h-4 mr-2"
                  })
              }
              {primaryAction.label}
            </Button>
        )}
      </div>
    </div>
  );
};
