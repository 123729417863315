import { client } from "@/lib/httpClient"
import {
    AssignProductRequest,
    AssignProductResponse,
    Company,
    CompanyEntryResponse,
    CompanyFormFields,
    CompanyProduct
} from "@/types/company.types";
import { ActionStatus } from "@/types/api.types";
import { ROUTES } from "@/constants/routes.constants";
import {createCompanySchema} from "@/schemas/company.schemas";
import {SLA} from "@/types/sla.types";

export const companiesApi = {
    getAll: async (): Promise<Company[]> => {
        const response = await client.get<Company[]>(ROUTES.COMPANIES.API.BASE);
        return response.data ?? [];
    },

    getById: async (id: string): Promise<Company> => {
        const response = await client.get<Company>(ROUTES.COMPANIES.API.DETAIL(id));
        return response.data;
    },

    create: async (data: CompanyFormFields): Promise<CompanyEntryResponse> => {
        const validated = createCompanySchema.parse(data);
        const response = await client.post<CompanyEntryResponse>(
            ROUTES.COMPANIES.API.BASE,
            validated
        );
        return response.data;
    },

    update: async ({ id, company }: { id: string; company: CompanyFormFields }): Promise<ActionStatus> => {
        const response = await client.put<ActionStatus>(
            ROUTES.COMPANIES.API.DETAIL(id),
            company
        );
        return response.data;
    },

    delete: async (id: string): Promise<ActionStatus> => {
        const response = await client.delete<ActionStatus>(ROUTES.COMPANIES.API.DETAIL(id));
        return response.data;
    },

    getCompanyProducts: async (id: string): Promise<CompanyProduct[]> => {
        const response = await client.get<CompanyProduct[]>(ROUTES.COMPANIES.API.PRODUCTS(id));
        return response.data;
    },

    getCompanySla: async (id: string): Promise<SLA[]> => {
        const response = await client.get<SLA[]>(ROUTES.COMPANIES.API.SLA(id));
        return response.data;
    },

    assignProduct: async (companyId: string, data: AssignProductRequest) => {
        const response = await client.post<AssignProductResponse>(
            `/api/v1/companies/${companyId}/products`,
            data
        );
        return response.data;
    }
};
