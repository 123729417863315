import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usersApi } from "@/components/features/users/services/users.api";
import { toast } from "react-toastify";
import { ROUTES } from "@/constants/routes.constants";
import { useNavigate } from "react-router-dom";
import {USER_KEYS} from "@/components/features/users/services/users.queryKeys";

export const useCreateUserMutation = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: usersApi.create,
        onSuccess: async () => {
            toast.success('Ο χρήστης δημιουργήθηκε επιτυχώς');
            await queryClient.invalidateQueries({queryKey: USER_KEYS.list});
            navigate(ROUTES.USERS.LIST);
        },
        onError: (error) => {
            toast.error('Σφάλμα κατά τη δημιουργία του χρήστη');
            console.error('Create user error:', error);
        }
    });
};

export const useHeartbeatMutation = () => {
    return useMutation( {
        mutationFn: usersApi.sendHeartbeat,
        onSuccess: async () => {
        },
        retry: 3,
        retryDelay: 2000
    });
};
