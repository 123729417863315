import React from 'react';
import {
    ClipboardList,
    ArrowRight,
    Clock,
    CheckCircle2,
    AlertCircle,
    XCircle,
    RefreshCw,
    ChevronRight, PlayCircle
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";

const TicketStatusGuide = () => {
    const statuses = [
        {
            id: 'new',
            name: 'Νέο',
            nameEn: 'NEW',
            icon: ClipboardList,
            color: 'bg-blue-500',
            lightColor: 'bg-blue-50',
            textColor: 'text-blue-700',
            description: 'Το αίτημα έχει μόλις δημιουργηθεί και περιμένει ανάθεση.',
            points: [
                'Όταν ο πελάτης/χρήστης δημιουργεί ένα νέο αίτημα',
                'Δεν έχει ακόμη ανατεθεί σε κάποιον agent',
                'Δεν έχει γίνει καμία ενέργεια από την ομάδα υποστήριξης'
            ],
            nextStatus: 'OPEN',
            nextCondition: 'όταν ανατίθεται σε agent'
        },
        {
            id: 'open',
            name: 'Ανοιχτό',
            nameEn: 'OPEN',
            icon: PlayCircle,
            color: 'bg-amber-500',
            lightColor: 'bg-amber-50',
            textColor: 'text-amber-700',
            description: 'Το αίτημα έχει ανατεθεί και βρίσκεται σε επεξεργασία.',
            points: [
                'Όταν το αίτημα ανατίθεται σε agent',
                'O agent επεξεργάζεται ενεργά το αίτημα',
                'Γίνονται ενέργειες για την επίλυση του προβλήματος'
            ],
            nextStatus: 'PENDING ή RESOLVED',
            nextCondition: 'ανάλογα με την πορεία επίλυσης'
        },
        {
            id: 'pending',
            name: 'Σε Εκκρεμότητα',
            nameEn: 'PENDING',
            icon: Clock,
            color: 'bg-orange-500',
            lightColor: 'bg-orange-50',
            textColor: 'text-orange-700',
            description: 'Το αίτημα περιμένει ενέργειες από τον πελάτη ή τρίτους.',
            points: [
                'Όταν περιμένουμε απάντηση/πληροφορίες από τον πελάτη',
                'Όταν περιμένουμε έγκριση για κάποια ενέργεια',
                'Όταν το αίτημα εξαρτάται από εξωτερικό παράγοντα'
            ],
            nextStatus: 'OPEN ή RESOLVED ή CANCELLED',
            nextCondition: 'ανάλογα με την εξέλιξη του αιτήματος'
        },
        {
            id: 'resolved',
            name: 'Επιλυμένο',
            nameEn: 'RESOLVED',
            icon: AlertCircle,
            color: 'bg-teal-500',
            lightColor: 'bg-teal-50',
            textColor: 'text-teal-700',
            description: 'Η λύση έχει προταθεί και περιμένει επιβεβαίωση.',
            points: [
                'Όταν ο agent έχει ολοκληρώσει όλες τις απαραίτητες ενέργειες',
                'Η λύση έχει προταθεί/εφαρμοστεί',
                'Περιμένουμε επιβεβαίωση από τον πελάτη'
            ],
            nextStatus: 'CLOSED ή REOPENED ή PENDING',
            nextCondition: 'ανάλογα με την ανταπόκριση του πελάτη'
        },
        {
            id: 'closed',
            name: 'Κλειστό',
            nameEn: 'CLOSED',
            icon: CheckCircle2,
            color: 'bg-green-500',
            lightColor: 'bg-green-50',
            textColor: 'text-green-700',
            description: 'Το αίτημα έχει επιλυθεί επιτυχώς και επιβεβαιωθεί.',
            points: [
                'Το αίτημα έχει επιλυθεί επιτυχώς',
                'Ο πελάτης έχει επιβεβαιώσει την επίλυση',
                'Δεν απαιτούνται άλλες ενέργειες'
            ],
            nextStatus: 'REOPENED',
            nextCondition: 'αν προκύψει ξανά το ίδιο ζήτημα'
        },
        {
            id: 'cancelled',
            name: 'Ακυρωμένο',
            nameEn: 'CANCELLED',
            icon: XCircle,
            color: 'bg-red-500',
            lightColor: 'bg-red-50',
            textColor: 'text-red-700',
            description: 'Το αίτημα έχει ακυρωθεί ή τερματιστεί.',
            points: [
                'Το αίτημα ακυρώθηκε από τον πελάτη',
                'Το αίτημα είναι διπλότυπο',
                'Δεν υπήρξε απάντηση από τον πελάτη για μεγάλο χρονικό διάστημα'
            ],
            nextStatus: 'Τελική κατάσταση',
            nextCondition: 'δεν μεταβαίνει σε άλλη'
        },
        {
            id: 'reopened',
            name: 'Επανανοίχτηκε',
            nameEn: 'REOPENED',
            icon: RefreshCw,
            color: 'bg-purple-500',
            lightColor: 'bg-purple-50',
            textColor: 'text-purple-700',
            description: 'Το αίτημα χρειάζεται περαιτέρω διερεύνηση μετά το κλείσιμο.',
            points: [
                'Όταν ένα κλειστό αίτημα χρειάζεται επιπλέον εργασία',
                'Ο πελάτης αναφέρει ότι το πρόβλημα εξακολουθεί να υπάρχει',
                'Η λύση που δόθηκε δεν ήταν επαρκής'
            ],
            nextStatus: 'OPEN',
            nextCondition: 'επιστρέφει στην κανονική ροή'
        }
    ];

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
                {/* Header */}
                <div className="mb-8 text-center">
                    <h1 className="text-3xl font-bold text-gray-900">Καταστάσεις Αιτημάτων</h1>
                    <p className="mt-2 text-lg text-gray-600">
                        Κατανοήστε τις διαφορετικές καταστάσεις των αιτημάτων και τη ροή τους
                    </p>
                </div>

                {/* Status Flow Diagram */}
                <Card className="mb-12">
                    <CardHeader>
                        <CardTitle>Ροή Καταστάσεων</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex items-center justify-between flex-wrap gap-4">
                            {statuses.map((status, index) => (
                                <React.Fragment key={status.id}>
                                    <div className={`flex items-center px-4 py-2 rounded-lg ${status.lightColor} ${status.textColor}`}>
                                        <status.icon className="w-5 h-5 mr-2" />
                                        <span className="font-medium">{status.name}</span>
                                    </div>
                                    {index < statuses.length - 1 && (
                                        <ChevronRight className="w-5 h-5 text-gray-400 hidden sm:block" />
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </CardContent>
                </Card>

                {/* Status Details */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {statuses.map((status) => (
                        <Card key={status.id}>
                            <CardHeader className={`${status.lightColor} ${status.textColor} rounded-t-lg`}>
                                <div className="flex items-center space-x-3">
                                    <status.icon className="w-6 h-6" />
                                    <div>
                                        <CardTitle className="flex items-center">
                                            {status.name}
                                            <span className="ml-2 text-sm font-normal">({status.nameEn})</span>
                                        </CardTitle>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent className="pt-4">
                                <p className="text-gray-600 mb-4">{status.description}</p>
                                <ul className="space-y-2">
                                    {status.points.map((point, index) => (
                                        <li key={index} className="flex items-start">
                                            <span className="mr-2">•</span>
                                            <span className="text-gray-600">{point}</span>
                                        </li>
                                    ))}
                                </ul>
                                <div className="mt-4 pt-4 border-t border-gray-200">
                                    <div className="flex items-center text-sm">
                                        <span className="font-medium text-gray-700">Επόμενη κατάσταση:</span>
                                        <ArrowRight className="w-4 h-4 mx-2 text-gray-400" />
                                        <span className="text-gray-600">
                                            {status.nextStatus}
                                            <span className="text-gray-400 ml-1">
                                                ({status.nextCondition})
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TicketStatusGuide;
