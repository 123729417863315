// src/constants/colors.constants.ts

// Define color constants
import {RoleTypes, UserRole} from "@/types/role.types";
import {PriorityEnum, PriorityName} from "@/types/priority.types";
import {StatusEnum, StatusName} from "@/types/status.types";

export const ColorsConstants = {
    // Base colors
    RED: 'red',
    BLUE: 'blue',
    GREEN: 'green',
    TEAL: 'teal',
    YELLOW: 'yellow',
    PURPLE: 'purple',
    GRAY: 'gray',
    AMBER: 'amber',
    ORANGE: 'orange',
    WHITE: 'white',
} as const;

export type Color = typeof ColorsConstants[keyof typeof ColorsConstants];

// Status color mapping
export const STATUS_COLORS: Record<StatusName, Color> = {
    [StatusEnum.NEW]: ColorsConstants.BLUE,
    [StatusEnum.OPEN]: ColorsConstants.AMBER,
    [StatusEnum.PENDING]: ColorsConstants.ORANGE,
    [StatusEnum.RESOLVED]: ColorsConstants.TEAL,
    [StatusEnum.CLOSED]: ColorsConstants.GREEN,
    [StatusEnum.CANCELLED]: ColorsConstants.RED,
    [StatusEnum.REOPENED]: ColorsConstants.PURPLE
} as const;

// Priority color mapping
export const PRIORITY_COLORS: Record<PriorityName, Color> = {
    [PriorityEnum.URGENT]: ColorsConstants.RED,
    [PriorityEnum.HIGH]: ColorsConstants.ORANGE,
    [PriorityEnum.MEDIUM]: ColorsConstants.AMBER,
    [PriorityEnum.LOW]: ColorsConstants.BLUE
} as const;

// Role color mapping
export const ROLE_COLORS: Record<UserRole, Color> = {
    [RoleTypes.ADMINISTRATOR]: ColorsConstants.PURPLE,
    [RoleTypes.HELPDESK]: ColorsConstants.RED,
    [RoleTypes.MANAGER]: ColorsConstants.BLUE,
    [RoleTypes.AGENT]: ColorsConstants.GREEN,
    [RoleTypes.CLIENT_ADMINISTRATOR]: ColorsConstants.AMBER,
    [RoleTypes.CLIENT_MANAGER]: ColorsConstants.ORANGE,
    [RoleTypes.CLIENT_EMPLOYEE]: ColorsConstants.TEAL
} as const;

// Utility function to generate Tailwind classes
export const getColorClasses = (color: Color) => ({
    bg: `bg-${color}-50 hover:bg-${color}-100`,
    dark: `bg-${color}-500 hover:bg-${color}-200`,
    text: `text-${color}-700`,
    border: `border-${color}-200`,
    icon: `text-${color}-500`  // Useful for icons
});

export const getBgColor = (color: Color) => `bg-${color}-50`;
export const getTextColor = (color: Color) => `text-${color}-700`;
export const getBorderColor = (color: Color) => `border-${color}-200`;

// Type-safe color helpers
export const getStatusColorClasses = (status: StatusName) =>
    getColorClasses(STATUS_COLORS[status]);

export const getPriorityColorClasses = (priority: PriorityName) =>
    getColorClasses(PRIORITY_COLORS[priority]);

export const getRoleColorClasses = (role: UserRole) =>
    getColorClasses(ROLE_COLORS[role]);

// Usage examples:
/*
import { RoleTypes } from '@/constants/roles';
import { TicketStatus } from '@/constants/tickets';
import { TicketPriority } from '@/constants/tickets';
import { getStatusColorClasses, getPriorityColorClasses, getRoleColorClasses } from '@/constants/colors';

// Status Badge
const StatusBadge = ({ status }: { status: TicketStatus }) => {
    const colors = getStatusColorClasses(status);
    return (
        <span className={`
            ${colors.bg}
            ${colors.text}
            ${colors.border}
            px-2 py-1 rounded-full
        `}>
            {status}
        </span>
    );
};

// Priority Badge
const PriorityBadge = ({ priority }: { priority: TicketPriority }) => {
    const colors = getPriorityColorClasses(priority);
    return (
        <span className={`
            ${colors.bg}
            ${colors.text}
            ${colors.border}
            px-2 py-1 rounded-full
        `}>
            {priority}
        </span>
    );
};

// Role Badge
const RoleBadge = ({ role }: { role: RoleTypes }) => {
    const colors = getRoleColorClasses(role);
    return (
        <span className={`
            ${colors.bg}
            ${colors.text}
            ${colors.border}
            px-2 py-1 rounded-full
        `}>
            {roleLabels[role]}
        </span>
    );
};
*/
