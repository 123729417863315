import { useQuery } from '@tanstack/react-query';
import { productsApi } from './products.api';
import { PRODUCT_KEYS } from './products.queryKeys';

export const useGetProductsQuery = () => {
    return useQuery({
        queryKey: PRODUCT_KEYS.list,
        queryFn: productsApi.getAll
    });
};

export const useGetProductQuery = (id: string) => {
    return useQuery({
        queryKey: PRODUCT_KEYS.detail(id),
        queryFn: () => productsApi.getById(id),
        enabled: !!id
    });
};

export const useGetProductClientsQuery = (id: string) => {
    return useQuery({
        queryKey: PRODUCT_KEYS.clients(id),
        queryFn: () => productsApi.getClients(id),
        enabled: !!id
    });
};
