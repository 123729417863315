import { useQuery } from '@tanstack/react-query';
import { casesApi } from './cases.api';
import { CASE_KEYS } from './cases.queryKeys';
import type { CaseFilters } from '@/types/case.types';

export function useGetCasesQuery(filters?: CaseFilters) {
    return useQuery({
        queryKey: CASE_KEYS.list(filters),
        queryFn: () => casesApi.getAll(filters),
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: true
    });
}

export function useGetCaseQuery(id: string) {
    return useQuery({
        queryKey: CASE_KEYS.detail(id),
        queryFn: () => casesApi.getById(id),
        enabled: Boolean(id),
        staleTime: 1000 * 60 * 5
    });
}
