// notes.api.ts
import type { Note } from '@/types/note.types';
import type { CreateNoteRequest } from '@/schemas/note.schemas';
import { client } from "@/lib/httpClient";

export const notesApi = {
    getAll: async (ticketId: string) => {
        const response = await client.get<Note[]>(
            `/api/v1/tickets/${ticketId}/notes`
        );
        return response.data;
    },

    create: async (ticketId: string, data: CreateNoteRequest) => {
        // const validated = createNoteSchema.parse(data);
        const formData = new FormData();
        formData.append('note_content', data.note_content);
        if (data.files) {
            for (const file of data.files) {
                formData.append('files', file);
            }
        }

        const response = await client.post(
            `/api/v1/tickets/${ticketId}/notes`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    },

    getAttachment: async (noteAttachmentId: string) => {
        const response = await client.get<Blob>(
            `/api/v1/tickets/notes/attachments/${noteAttachmentId}`,
            { responseType: 'blob' }
        );
        return response.data;
    }
};
