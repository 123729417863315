// Sidebar.tsx
import { getNavigationByRoles } from '@/routes/navigation';
import NavItem from "@/components/ui/NavItem/NavItem";
import {User} from 'lucide-react';
import { useEffect } from 'react';
import { cn } from "@/lib/utils";
import useGlobalStore from "@/stores/useGlobalStore";
import useUserStore from "@/stores/useUserStore";
import { useLocation } from "react-router-dom";
import Logo from "@/components/ui/Logo";

export function Sidebar() {
    const { isSidebarOpen, toggleSidebar } = useGlobalStore();
    const { user } = useUserStore();
    const location = useLocation();

    // Get navigation items based on user roles
    const navigationItems = getNavigationByRoles(user?.roles || []);

    // Close sidebar on route change for mobile
    useEffect(() => {
        if (window.innerWidth < 768) {
            toggleSidebar(false);
        }
    }, [location.pathname, toggleSidebar]);

    // Handle resize
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                toggleSidebar(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [toggleSidebar]);

    return (
        <>
            <div
                className={cn(
                    "fixed inset-y-0 left-0 z-50 flex w-64 flex-col",
                    "transition-transform duration-300 ease-in-out",
                    "bg-white border-r border-gray-200",
                    "md:relative md:translate-x-0",
                    !isSidebarOpen && "-translate-x-full"
                )}
            >
                <div className="flex h-16 shrink-0 items-center justify-center px-6">
                    <Logo />
                </div>

                <nav className="flex-1 space-y-1 px-2 py-2 overflow-y-auto">
                    {navigationItems.map((item) => (
                        <NavItem key={item.name} item={item} />
                    ))}
                </nav>

                <div className="border-t border-gray-200 p-3">
                    <NavItem
                    item={{
                        name: 'Προφίλ',
                        href: '/profile',
                        icon: User,
                    }}
                />
                </div>
            </div>

            {isSidebarOpen && (
                <div
                    className="fixed inset-0 z-40 bg-black/30 md:hidden"
                    onClick={() => toggleSidebar(false)}
                />
            )}
        </>
    );
}
