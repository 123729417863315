import axios, {AxiosInstance} from 'axios';
import config from "@/lib/config";

let getAccessToken: (() => Promise<string>) | null = null;

const client: AxiosInstance = axios.create({
    baseURL: config.apiBaseURL,
});

client.interceptors.request.use(
    async (config) => {
        if (getAccessToken) {
            const accessToken = await getAccessToken();
            if (import.meta.env.DEV) {
                console.log('Access Token', accessToken)
            }
            config.headers!['Authorization'] = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const setAccessTokenGetter = (accessTokenGetter: () => Promise<string>) => {
    getAccessToken = accessTokenGetter
}

export {client, setAccessTokenGetter}
