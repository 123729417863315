// components/NavItem.tsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from "@/lib/utils";

interface NavItemProps {
    item: {
        name: string;
        href?: string;
        icon?: React.ComponentType<{ className?: string }>;
        children?: Array<{
            name: string;
            href?: string;
            icon?: React.ComponentType<{ className?: string }>;
        }>;
    };
    isChild?: boolean;
}

const NavItem = ({ item, isChild = false }: NavItemProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const location = useLocation();
    const isActive = location.pathname === item.href;
    const Icon = item.icon;
    const hasChildren = item.children && item.children.length > 0;

    const itemContent = (
        <>
            {Icon && (
                <Icon className={cn(
                    "h-5 w-5 shrink-0",
                    isActive ? "text-blue-600" : "text-gray-500",
                    !isChild && "mr-3"
                )} />
            )}
            <span className={cn("truncate", isActive ? "font-semibold text-blue-600" : "text-gray-700")}>
                {item.name}
            </span>
            {hasChildren && (
                <svg
                    className={cn(
                        "ml-auto h-5 w-5 shrink-0 transition-transform duration-200",
                        isOpen && "rotate-180"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
            )}
        </>
    );

    return (
        <div>
            {item.href && !hasChildren ? (
                <Link
                    to={item.href}
                    className={cn(
                        "flex items-center gap-x-2 rounded-md px-3 py-2 text-sm",
                        isActive
                            ? "bg-blue-100 text-blue-600"
                            : "text-gray-700 hover:bg-gray-100 hover:text-blue-600",
                        isChild && "pl-11"
                    )}
                >
                    {itemContent}
                </Link>
            ) : (
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={cn(
                        "flex w-full items-center gap-x-2 rounded-md px-3 py-2 text-sm",
                        isActive
                            ? "bg-blue-100 text-blue-600"
                            : "text-gray-700 hover:bg-gray-100 hover:text-blue-600",
                        isChild && "pl-11"
                    )}
                >
                    {itemContent}
                </button>
            )}

            {hasChildren && isOpen && (
                <div className="mt-1 space-y-1 pl-4 border-l border-gray-300">
                    {item.children?.map((child) => (
                        <NavItem key={child.name} item={child} isChild />
                    ))}
                </div>
            )}
        </div>
    );
};

export default NavItem;
