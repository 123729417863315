import { useQuery } from "@tanstack/react-query";
import { PRIORITY_KEYS } from "./priorities.queryKeys";
import { prioritiesApi } from "./priorities.api";
import {Priority} from "@/types/priority.types";

export const useGetPrioritiesQuery = () => {
    return useQuery<Priority[]>({
        queryKey: PRIORITY_KEYS.list,
        queryFn: prioritiesApi.getAll,
    });
};
