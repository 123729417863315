import React from 'react';
import SelectMenu from '@/components/ui/SelectMenu';
import { Department } from '@/types/department.types';
import { useGetDepartmentsByCompanyQuery } from "@/components/features/departments/services/departments.queries";

interface DepartmentDropdownProps {
    label?: string;
    value?: string;
    onChange?: (departmentId: string) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    company_id?: string;
}

const DepartmentDropdown = React.forwardRef<HTMLButtonElement, DepartmentDropdownProps>(
    ({
         label = 'Τμήμα',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε τμήμα',
         company_id,
         ...props
     }, ref) => {
        const { data: departments, isLoading, isError } = useGetDepartmentsByCompanyQuery(company_id);

        const handleChange = (selected: { id: string; label: string }) => {
            onChange?.(selected.id);
        };

        const departmentOptions = React.useMemo(() => {
            if (!departments) return [];

            return departments.map((dept: Department) => ({
                id: dept.department_id,
                label: dept.department_name,
            }));
        }, [departments]);

        return (
            <SelectMenu
                ref={ref}
                label={label}
                menuItems={departmentOptions}
                onChange={handleChange}
                defaultValue={value}
                error={isError ? 'Σφάλμα φόρτωσης τμημάτων' : error}
                disabled={disabled || isLoading || !company_id}
                required={required}
                placeholder={!company_id ? 'Επιλέξτε πρώτα εταιρεία' : isLoading ? 'Φόρτωση...' : placeholder}
                searchPlaceholder="Αναζήτηση τμήματος..."
                {...props}
            />
        );
    }
);

DepartmentDropdown.displayName = 'DepartmentDropdown';

export default DepartmentDropdown;
