import React from 'react';
import {Dialog} from '@headlessui/react';
import { Package, X, Search, Plus } from 'lucide-react';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { Product } from '@/types/product.types';
import {toast} from "react-toastify";
import InputWithLabel from "@/components/ui/InputWithLabel";

interface AssignProductModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    companyId?: string;
    products?: Product[];
    onAssign: (productIds: string[], price: number) => Promise<void>;
    isLoading?: boolean;
}

export const AssignProductModal: React.FC<AssignProductModalProps> = ({
                                                                          open,
                                                                          setOpen,
                                                                          products = [],
                                                                          onAssign,
                                                                          isLoading = false,
                                                                      }) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
    const [price, setPrice] = React.useState<string>('');
    const [error, setError] = React.useState<string>('');

    const filteredProducts = products.filter(product =>
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.product_code.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAssign = async () => {
        if (!price || selectedProducts.length === 0) {
            setError('Παρακαλώ επιλέξτε προϊόντα και εισάγετε τιμή');
            return;
        }

        try {
            await onAssign(selectedProducts, parseFloat(price));
            toast.success('Τα προϊόντα ανατέθηκαν επιτυχώς');
            setOpen(false);
            resetForm();
        } catch (error) {
            toast.error('Σφάλμα κατά την ανάθεση των προϊόντων');
        }
    };

    const resetForm = () => {
        setSearchTerm('');
        setSelectedProducts([]);
        setPrice('');
        setError('');
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="relative z-50"
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="mx-auto max-w-2xl w-full rounded-lg bg-white shadow-xl">
                    <div className="flex items-center justify-between p-6 border-b">
                        <Dialog.Title className="text-lg font-medium flex items-center">
                            <Package className="w-5 h-5 mr-2 text-gray-500" />
                            Ανάθεση Προϊόντων
                        </Dialog.Title>
                        <Button
                            variant={ButtonVariant.GHOST}
                            size={ButtonSize.SM}
                            onClick={() => setOpen(false)}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    </div>

                    <div className="p-6 space-y-6">
                        <div className="space-y-4">
                            <div className="relative">
                                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                                <InputWithLabel
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Αναζήτηση προϊόντων..."
                                    className="pl-10"
                                />
                            </div>

                            <div className="border rounded-lg divide-y max-h-64 overflow-y-auto">
                                {filteredProducts.map((product) => (
                                    <div
                                        key={product.product_id}
                                        className={`p-4 flex items-center justify-between hover:bg-gray-50 cursor-pointer ${
                                            selectedProducts.includes(product.product_id)
                                                ? 'bg-primary-50'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            setSelectedProducts(prev =>
                                                prev.includes(product.product_id)
                                                    ? prev.filter(id => id !== product.product_id)
                                                    : [...prev, product.product_id]
                                            );
                                        }}
                                    >
                                        <div className="flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                                <Package className="h-5 w-5 text-gray-400" />
                                            </div>
                                            <div>
                                                <p className="font-medium text-gray-900">
                                                    {product.product_name}
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                    {product.product_code}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            {selectedProducts.includes(product.product_id) && (
                                                <Plus className="h-5 w-5 text-primary-600 rotate-45" />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="flex items-center space-x-4">
                                <div className="flex-1">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Τιμή (€)
                                    </label>
                                    <InputWithLabel
                                        type="number"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        placeholder="0.00"
                                        min="0"
                                        step="0.01"
                                    />
                                </div>
                            </div>

                            {error && (
                                <p className="text-sm text-error-500">{error}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center justify-end gap-3 p-6 bg-gray-50 rounded-b-lg">
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            onClick={() => setOpen(false)}
                        >
                            Ακύρωση
                        </Button>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={handleAssign}
                            disabled={isLoading || selectedProducts.length === 0}
                        >
                            {isLoading ? 'Αποθήκευση...' : 'Αποθήκευση'}
                        </Button>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};

export default AssignProductModal;
