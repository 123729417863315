// src/schemas/ticket.schemas.ts

import { z } from 'zod';
import type { CreateTicketRequest } from '@/types/ticket.types';

export const createTicketSchema = z.object({
    title: z.string()
        .min(1, 'Ο τίτλος είναι υποχρεωτικός')
        .max(200, 'Ο τίτλος δεν μπορεί να υπερβαίνει τους 200 χαρακτήρες')
        .trim(),
    description: z.string()
        .min(1, 'Η περιγραφή είναι υποχρεωτική')
        .trim(),
    category_id: z.string().uuid('Μη έγκυρη κατηγορία'),
    priority_id: z.string().uuid('Μη έγκυρη προτεραιότητα'),
    company_id: z.string().uuid('Μη έγκυρη εταιρεία').optional(),
    contact_id: z.string().uuid('Μη έγκυρος χρήστης').optional(),
    products: z.array(z.string().uuid('Μη έγκυρο προϊόν'))
}) satisfies z.ZodType<CreateTicketRequest>;

export const ticketFiltersSchema = z.object({
    page_number: z.number().optional().default(1),
    page_size: z.number().optional().default(10),
    category_id: z.string().uuid('Μη έγκυρη κατηγορία').optional(),
    priority_id: z.string().uuid('Μη έγκυρη προτεραιότητα').optional(),
    status_id: z.string().uuid('Μη έγκυρη κατάσταση').optional(),
    company_id: z.string().uuid('Μη έγκυρη εταιρεία').optional(),
});
