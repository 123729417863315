import React, {useState} from 'react';
import { type ColumnDef } from '@tanstack/react-table';
import {Filter, Plus, RefreshCcw, Mail,  Trash2, Pencil, Eye} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '@/components/features/users/services/users.queries';
import { ROUTES } from '@/constants/routes.constants';
import type { User } from '@/types/user.types';
import { RoleBadge } from '@/components/ui/Badge/RoleBadge';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import ColumnTitle from '@/components/ui/DataTable/ColumnTitle';
import DataTable from '@/components/ui/DataTable';
import {Avatar} from "@/components/ui/Avatar/Avatar";
import {UserStatusBadge} from "@/components/ui/Badge/UserStatusBadge";
import Modal from "@/components/ui/Modal";
import {PermissionGuard} from "@/components/guards/PermissionGuard";
import {usePermissions} from "@/hooks/usePermissions";

interface UsersListProps {
    companyId?: string;
}

const UsersList: React.FC<UsersListProps> = ({ companyId }) => {
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();

    const { data: users = [], isLoading, refetch, isError } = useGetUsersQuery({
        company_id: companyId, // Pass the company_id as a filter if provided
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const handleCreateUser = () => navigate(ROUTES.USERS.NEW);
    // const handleRowClick = (user: User) => navigate(ROUTES.USERS.DETAILS(user.user_id));

    const deleteUser = async () => {
        if (!userToDelete) return;
        try {
            console.log('Deleting user:', userToDelete);
            // await deleteUserMutation.mutateAsync(userToDelete.user_id);
            setDeleteModalOpen(false);
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const columns: ColumnDef<User, string>[] = [
        {
            id: 'user',
            header: ({ column }) => <ColumnTitle title="Χρήστης" isSortable column={column} />,
            accessorFn: (row) => `${row.first_name} ${row.last_name}`,
            cell: ({ row }) => (
                <div className="flex items-center gap-3">
                    <Avatar
                        user={row.original}
                        size="md"
                        className="flex-shrink-0"
                    />
                    <div className="flex flex-col">
                    <span className="font-medium text-gray-900">
                        {row.original.first_name} {row.original.last_name}
                    </span>
                        <span className="text-sm text-gray-500 flex items-center">
                        <Mail className="w-3 h-3 mr-1" />
                            {row.original.email_address}
                    </span>
                    </div>
                </div>
            ),
        },
        {
            id: 'role',
            header: ({ column }) => <ColumnTitle title="Ρόλος" isSortable column={column} />,
            cell: ({ row }) => <RoleBadge role={row.original.role} />,    },
        {
            id: 'company',
            header: ({ column }) => <ColumnTitle title="Εταιρεία" isSortable column={column} />,
            accessorFn: (row) => row.company?.company_name ?? '-', // Add fallback
        },
        {
            id: 'department',
            header: ({ column }) => <ColumnTitle title="Τμήμα" isSortable column={column} />,
            accessorFn: (row) => row.department?.department_name ?? '-', // Add fallback
        },
        {
            id: 'status',
            header: ({ column }) => <ColumnTitle title="Κατάσταση" isSortable column={column} />,
            // accessorFn: (row) => !row.blocked,
            cell: ({ row }) => <UserStatusBadge blocked={row.original.blocked} />
        },
        {
            id: 'actions',
            header: '',
            cell: ({ row }) => {
                const user = row.original;
                return (
                    <div className="flex justify-end gap-2" onClick={e => e.stopPropagation()}>
                        <Button
                            variant={ButtonVariant.GHOST}
                            size={ButtonSize.SM}
                            onClick={() => navigate(ROUTES.USERS.DETAILS(user.user_id))}
                            title="Προβολή"
                        >
                            <Eye className="h-4 w-4" />
                        </Button>
                        {hasPermission('update:users') && (
                            <Button
                                variant={ButtonVariant.GHOST}
                                size={ButtonSize.SM}
                                onClick={() => navigate(`${ROUTES.USERS.DETAILS(user.user_id)}/edit`)}
                                title="Επεξεργασία"
                            >
                                <Pencil className="h-4 w-4" />
                            </Button>
                        )}
                        {hasPermission('delete:users') && (
                            <Button
                            variant={ButtonVariant.GHOST}
                            size={ButtonSize.SM}
                            onClick={() => {
                                setUserToDelete(user);
                                setDeleteModalOpen(true);
                            }}
                            className="text-red-600 hover:text-red-700"
                            title="Διαγραφή"
                            >
                             <Trash2 className="h-4 w-4" />
                             </Button>
                        )}
                    </div>
                );
            }
        }
    ];

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (isError) {
        return <p>Error loading users.</p>;
    }

    if (!users || users.length === 0) {
        return <p>No users found.</p>;
    }

    return (
        <div className="h-full w-full">
            <div className="mx-auto bg-gray-100 p-4 sm:p-6 lg:p-8">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">Χρήστες</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Διαχείριση χρηστών και δικαιωμάτων πρόσβασης
                    </p>
                </div>

                <div className="mb-6 flex flex-col sm:flex-row justify-between gap-4">
                    <div className="flex flex-wrap gap-2">
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            className="flex items-center"
                        >
                            <Filter className="w-4 h-4 mr-2"/>
                            Φίλτρα
                        </Button>
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            className="flex items-center"
                            onClick={() => refetch()}
                            disabled={isLoading}
                        >
                            <RefreshCcw className="w-4 h-4 mr-2"/>
                            Ανανέωση
                        </Button>
                    </div>
                    <PermissionGuard
                        requiredPermissions={['create:users']}
                        requireAll={false}
                    >
                    <div className="flex gap-2">
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SM}
                            className="flex items-center"
                            onClick={handleCreateUser}
                        >
                            <Plus className="w-4 h-4 mr-2"/>
                            Προσθήκη Χρήστη
                        </Button>
                    </div>
                    </PermissionGuard>
                </div>

                <div className="bg-white rounded-lg shadow">
                    <DataTable
                        columns={columns}
                        data={users}
                        isLoading={isLoading}
                        // onRowClick={(row) => handleRowClick(row.original)}
                    />
                </div>
            </div>

            <Modal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                title="Διαγραφή Χρήστη"
                type="delete"
                primaryAction={{
                    text: 'Διαγραφή',
                    onClick: deleteUser
                }}
                secondaryAction={{
                    text: 'Ακύρωση',
                    onClick: () => setDeleteModalOpen(false)
                }}
                icon={<Trash2 className="h-6 w-6 text-red-600" />}
            >
                <p className="text-sm text-gray-500">
                    Είστε βέβαιοι ότι θέλετε να διαγράψετε τον χρήστη{' '}
                    <span className="font-medium text-gray-900">
           {userToDelete?.first_name} {userToDelete?.last_name}
       </span>
                    ; Αυτή η ενέργεια δεν μπορεί να αναιρεθεί.
                </p>
            </Modal>
        </div>
    );
};

export default UsersList;
