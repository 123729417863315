import React from 'react'
import { ArrowDown, ArrowUp, ArrowUpDown } from 'lucide-react'
import { type Column } from '@tanstack/react-table'

interface ColumnTitleProps<T> {
    title: string
    isSortable?: boolean
    column?: Column<T>
}

const ColumnTitle = <T,>({ title, isSortable = false, column }: ColumnTitleProps<T>) => {
    if (!isSortable) {
        return <span className="font-medium">{title}</span>
    }

    const sortDirection = column?.getIsSorted()
    
    return (
        <button
            type="button"
            onClick={() => column?.toggleSorting(column.getIsSorted() === 'asc')}
            className="inline-flex items-center gap-1 hover:text-primary-500"
        >
            <span className="font-medium">{title}</span>
            {sortDirection === 'asc' && <ArrowUp className="h-4 w-4" />}
            {sortDirection === 'desc' && <ArrowDown className="h-4 w-4" />}
            {!sortDirection && <ArrowUpDown className="h-4 w-4 text-gray-400" />}
        </button>
    )
}

export default ColumnTitle
