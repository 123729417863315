import React from 'react';
import { getStatusColorClasses } from '@/constants/colors.constants';
import { cn } from '@/lib/utils';
import { StatusName } from '@/types/status.types';

interface StatusBadgeProps {
    status: StatusName;
    className?: string;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status, className }) => {
    const colors = getStatusColorClasses(status);
    return (
        <span
            className={cn(
                'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border',
                colors?.bg,
                colors?.text,
                colors?.border,
                className
            )}
        >
            {status}
        </span>
    );
};

export default StatusBadge;
