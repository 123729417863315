import React from 'react';
import { SLA } from '@/types/sla.types';
import {PriorityEnum, PriorityName} from '@/types/priority.types';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';
import Drawer from "@/components/ui/Drawer/Drawer";
import Button from "@/components/ui/Button";
import {ButtonSize, ButtonType, ButtonVariant} from "@/components/ui/Button/ButtonSize";

interface SLADrawerProps {
    isOpen: boolean;
    onClose: () => void;
    editingSla: SLA | null;
    onSubmit: (data: Partial<SLA>) => Promise<void>;
}

const SLADrawer = ({ isOpen, onClose, editingSla, onSubmit }: SLADrawerProps) => {
    const priorityOptions = React.useMemo(() => {
        return Object.entries(PriorityEnum).map(([, value]) => ({
            value: value,
            label: value,
        }));
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);

        const priorityName = formData.get('priority') as PriorityName;

        const data: Partial<SLA> = {
            sla_name: formData.get('sla_name') as string,
            description: formData.get('description') as string,
            priority: {
                priority_id: editingSla?.priority.priority_id ?? '',
                priority_name: priorityName,
                description: editingSla?.priority.description ?? '',
                response_time: Number(formData.get('response_time')),
                resolution_time: Number(formData.get('resolution_time'))
            },
            response_time: Number(formData.get('response_time')),
            resolution_time: Number(formData.get('resolution_time'))
        };

        await onSubmit(data);
        onClose();
    };

 return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            title="Επεξεργασία SLA"
        >
            <form onSubmit={handleSubmit} className="space-y-6">
                <InputWithLabel
                    name="sla_name"
                    label="Όνομα SLA"
                    required
                    defaultValue={editingSla?.sla_name}
                    placeholder="π.χ. Premium SLA"
                />

                <TextArea
                    name="description"
                    label="Περιγραφή"
                    required
                    rows={4}
                    defaultValue={editingSla?.description}
                    placeholder="Περιγράψτε το SLA..."
                />

                <DropdownWithLabel
                    name="priority"
                    label="Προτεραιότητα"
                    options={priorityOptions}
                    required
                    value={editingSla?.priority.priority_name}
                />

                <InputWithLabel
                    name="response_time"
                    label="Χρόνος Απόκρισης (ώρες)"
                    type="number"
                    required
                    defaultValue={editingSla?.response_time}
                    min={0}
                    step={0.5}
                />

                <InputWithLabel
                    name="resolution_time"
                    label="Χρόνος Επίλυσης (ώρες)"
                    type="number"
                    required
                    defaultValue={editingSla?.resolution_time}
                    min={0}
                    step={0.5}
                />

                <div className="flex justify-end space-x-4">
                    <Button
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.LG}
                        onClick={onClose}
                        type={ButtonType.BUTTON}
                    >
                        Ακύρωση
                    </Button>
                    <Button
                        type={ButtonType.SUBMIT}
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LG}
                    >
                        Αποθήκευση
                    </Button>
                </div>
            </form>
        </Drawer>
    );
};

export default SLADrawer;
