// user.schemas.ts
import { z } from 'zod';
import type { CreateUserRequest, UpdateUserRequest } from '@/types/user.types';

export const createUserSchema = z.object({
  email_address: z.string().email('Μη έγκυρη διεύθυνση email'),
  first_name: z.string()
      .min(2, 'Το όνομα πρέπει να έχει τουλάχιστον 2 χαρακτήρες')
      .max(50, 'Το όνομα δεν μπορεί να υπερβαίνει τους 50 χαρακτήρες'),
  last_name: z.string()
      .min(2, 'Το επώνυμο πρέπει να έχει τουλάχιστον 2 χαρακτήρες')
      .max(50, 'Το επώνυμο δεν μπορεί να υπερβαίνει τους 50 χαρακτήρες'),
  phone_number: z.string()
      .max(20, 'Το τηλέφωνο δεν μπορεί να υπερβαίνει τους 20 χαρακτήρες')
      .optional(),
  role_id: z.string().uuid('Μη έγκυρο ID ρόλου'),
  department_id: z.string().uuid('Μη έγκυρο ID τμήματος').optional(),
  company_id: z.string().uuid('Μη έγκυρο ID εταιρείας'),
  password: z.string().min(6, 'Ο κωδικός πρέπει να έχει τουλάχιστον 6 χαρακτήρες'),
  blocked: z.boolean()
}) satisfies z.ZodType<CreateUserRequest>;

export const updateUserSchema = createUserSchema.partial() satisfies z.ZodType<UpdateUserRequest>;
