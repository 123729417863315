// components/guards/PermissionGuard.tsx
import { ReactNode } from 'react';
import useUserStore from '@/stores/useUserStore';

interface PermissionGuardProps {
    children: ReactNode;
    fallback?: ReactNode;
    requiredPermissions?: string | string[];
    requireAll?: boolean;
}

export const PermissionGuard = ({
                                    children,
                                    fallback = null,
                                    requiredPermissions = [],
                                    requireAll = false
                                }: PermissionGuardProps) => {
    const { user } = useUserStore();
    const permissions = user?.permissions || [];
    const hasPermission = (permission: string): boolean => {
        // Check for manage:* permissions first as they grant full access
        const resourceType = permission.split(':')[1];
        const managePermission = `manage:${resourceType}`;

        return permissions.includes(permission) || permissions.includes(managePermission);
    };

    const checkPermissions = (): boolean => {
        if (!requiredPermissions) return true;

        const permissionList = Array.isArray(requiredPermissions)
            ? requiredPermissions
            : [requiredPermissions];

        if (permissionList.length === 0) return true;

        return requireAll
            ? permissionList.every(hasPermission)
            : permissionList.some(hasPermission);
    };

    return checkPermissions() ? <>{children}</> : <>{fallback}</>;
};

