import { useMutation, useQueryClient } from '@tanstack/react-query';
import { departmentsApi } from './departments.api';
import { DEPARTMENT_KEYS } from './departments.queryKeys';

export const useCreateDepartmentMutation = (companyId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: departmentsApi.create,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: DEPARTMENT_KEYS.byCompany(companyId) });
        }
    });
};

export const useUpdateDepartmentMutation = (companyId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: departmentsApi.update,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: DEPARTMENT_KEYS.byCompany(companyId) });
        }
    });
};

export const useDeleteDepartmentMutation = (companyId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: departmentsApi.delete,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: DEPARTMENT_KEYS.byCompany(companyId) });
        }
    });
};
