import { useQuery } from "@tanstack/react-query";
import {Company, CompanyProduct} from "@/types/company.types";
import {COMPANY_KEYS} from "@/components/features/companies/services/companies.queryKeys";
import {companiesApi} from "@/components/features/companies/services/companies.api";
import {SLA} from "@/types/sla.types";

export const useGetCompaniesQuery = () => {
    return useQuery<Company[]>({
        queryKey: COMPANY_KEYS.list,
        queryFn: companiesApi.getAll,
    });
};

export const useGetCompanyQuery = (id: string) => {
    return useQuery<Company>({
        queryKey: COMPANY_KEYS.detail(id),
        queryFn: () => companiesApi.getById(id),
        enabled: !!id,
    });
};

export const useGetCompanyProductsQuery = (id: string) => {
    return useQuery<CompanyProduct[]>({
        queryKey: COMPANY_KEYS.products(id),
        queryFn: () => companiesApi.getCompanyProducts(id),
        enabled: !!id,
    });
};

export const useGetCompanySlaQuery = (id: string) => {
    return useQuery<SLA[]>({
        queryKey: COMPANY_KEYS.sla(id),
        queryFn: () => companiesApi.getCompanySla(id),
        enabled: !!id,
    });
};
