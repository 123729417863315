import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Clock,
    User,
    Link,
    Building2,
    Activity
} from 'lucide-react';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import { TimeAgo } from "@/components/ui/TimeAgo/TimeAgo";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import { Avatar } from "@/components/ui/Avatar/Avatar";
import { StatusEnum } from "@/types/status.types";
import {useGetCaseQuery} from "@/components/features/cases/services/cases.queries";

export default function CaseDetails() {
    const { id } = useParams<{ id: string }>();
    const { data: caseData, isLoading } = useGetCaseQuery(id!);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!caseData) {
        return <div>Case not found</div>;
    }

    return (
        <div className="h-full w-full bg-gray-50">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                {/* Header */}
                <div className="mb-6 flex justify-between items-start">
                    <div>
                        <div className="flex items-center gap-3 mb-2">
                            <h1 className="text-2xl font-bold text-gray-900">
                                {caseData.title}
                            </h1>
                            <StatusBadge status={caseData.status.status_name} />
                            <PriorityBadge priority={caseData.priority.priority_name} />
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                            <Building2 className="h-4 w-4" />
                            <span>Company name</span>
                            <span>•</span>
                            <Clock className="h-4 w-4" />
                            <TimeAgo date={caseData.created_at} />
                        </div>
                    </div>

                    <Button
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.SM}
                    >
                        Επεξεργασία
                    </Button>
                </div>

                <div className="grid grid-cols-3 gap-6">
                    {/* Main Content */}
                    <div className="col-span-2 space-y-6">
                        {/* Description */}
                        <Card>
                            <CardHeader>
                                <CardTitle>Περιγραφή</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <p className="text-gray-700 whitespace-pre-wrap">
                                    {caseData.description}
                                </p>
                            </CardContent>
                        </Card>

                        {/* Related Tickets */}
                        <Card>
                            <CardHeader>
                                <div className="flex justify-between items-center">
                                    <CardTitle>Σχετικά Αιτήματα</CardTitle>
                                    <Button
                                        variant={ButtonVariant.OUTLINE}
                                        size={ButtonSize.SM}
                                    >
                                        <Link className="h-4 w-4 mr-2" />
                                        Σύνδεση Αιτήματος
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardContent>
                                <div className="space-y-4">
                                    {caseData.tickets.map((ticket) => (
                                        <div key={ticket.ticket_id} className="flex items-start justify-between p-4 bg-gray-50 rounded-lg">
                                            <div className="space-y-1">
                                                <div className="flex items-center gap-2">
                                                    <span className="font-medium">#{ticket.ticket_number}</span>
                                                    <StatusBadge status={ticket.status.status_name} />
                                                    <PriorityBadge priority={ticket.priority.priority_name} />
                                                </div>
                                                <p className="text-gray-900">{ticket.title}</p>
                                                {ticket.assignee && (
                                                    <div className="flex items-center gap-2">
                                                        <Avatar
                                                            firstName={ticket.assignee.first_name}
                                                            lastName={ticket.assignee.last_name}
                                                            roleName={ticket.assignee.role.role_name}
                                                        />
                                                        {/*<span className="text-sm text-gray-500">*/}
                                                        {/*    {ticket.assignee.name}*/}
                                                        {/*</span>*/}
                                                    </div>
                                                )}
                                            </div>
                                            <TimeAgo date={ticket.created_at} className="text-sm text-gray-500" />
                                        </div>
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    {/* Sidebar */}
                    <div className="space-y-6">
                        {/* Status Card */}
                        <Card>
                            <CardContent className="p-6">
                                <div className="space-y-4">
                                    {/* Assignment */}
                                    <div>
                                        <label className="text-sm font-medium text-gray-700">
                                            Υπεύθυνος Υπόθεσης
                                        </label>
                                        {caseData.assignee ? (
                                            <div className="mt-2 flex items-center gap-3">
                                                <Avatar
                                                    firstName={caseData.assignee.first_name}
                                                    lastName={caseData.assignee.last_name}
                                                    roleName={caseData.assignee.role.role_name}
                                                />
                                                {/*<div>*/}
                                                {/*    <div className="font-medium">*/}
                                                {/*        {caseData.assignedTo.name}*/}
                                                {/*    </div>*/}
                                                {/*    <div className="text-sm text-gray-500">*/}
                                                {/*        {caseData.assignedTo.role}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        ) : (
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                size={ButtonSize.SM}
                                                className="mt-2 w-full"
                                            >
                                                <User className="h-4 w-4 mr-2" />
                                                Ανάθεση
                                            </Button>
                                        )}
                                    </div>

                                    {/* Category */}
                                    <div>
                                        <label className="text-sm font-medium text-gray-700">
                                            Κατηγορία
                                        </label>
                                        <div className="mt-1 text-gray-900">
                                            {caseData.category.category_name}
                                        </div>
                                    </div>

                                    {/* Activity Stats */}
                                    <div className="border-t pt-4 mt-4">
                                        <div className="space-y-3">
                                            <div className="flex justify-between text-sm">
                                                <span className="text-gray-500">Συνολικά Αιτήματα</span>
                                                <span className="font-medium">{caseData.tickets.length}</span>
                                            </div>
                                            <div className="flex justify-between text-sm">
                                                <span className="text-gray-500">Επιλυμένα Αιτήματα</span>
                                                <span className="font-medium">
                                                    {caseData.tickets.filter(t => t.status.status_name === StatusEnum.RESOLVED).length}
                                                </span>
                                            </div>
                                            <div className="flex justify-between text-sm">
                                                <span className="text-gray-500">Χρόνος Ανοίγματος</span>
                                                <TimeAgo date={caseData.created_at} className="font-medium" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        {/* Activity Timeline */}
                        <Card>
                            <CardHeader>
                                <CardTitle className="flex items-center gap-2">
                                    <Activity className="h-5 w-5" />
                                    Ιστορικό Ενεργειών
                                </CardTitle>
                            </CardHeader>
                            <CardContent>
                                {/*<div className="space-y-4">*/}
                                {/*    {caseData.activityLogs?.map((log) => (*/}
                                {/*        <div key={log.id} className="relative pb-4">*/}
                                {/*            /!* Timeline line *!/*/}
                                {/*            <div className="absolute left-2.5 top-2.5 h-full w-px bg-gray-200"></div>*/}

                                {/*            <div className="flex gap-3">*/}
                                {/*                <div className={`*/}
                                {/*                    h-5 w-5 rounded-full flex items-center justify-center*/}
                                {/*                    ${getActivityColor(log.type)}*/}
                                {/*                `}>*/}
                                {/*                    {getActivityIcon(log.type)}*/}
                                {/*                </div>*/}
                                {/*                <div>*/}
                                {/*                    <p className="text-sm text-gray-900">{log.message}</p>*/}
                                {/*                    <div className="flex items-center gap-2 mt-1">*/}
                                {/*                        <span className="text-xs text-gray-500">{log.user.name}</span>*/}
                                {/*                        <span className="text-xs text-gray-500">•</span>*/}
                                {/*                        <TimeAgo date={log.createdAt} className="text-xs text-gray-500" />*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    ))}*/}
                                {/*</div>*/}
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}
//
// function getActivityColor(type: string): string {
//     switch (type) {
//         case 'ticket_linked':
//             return 'bg-blue-100 text-blue-600';
//         case 'status_changed':
//             return 'bg-purple-100 text-purple-600';
//         case 'assigned':
//             return 'bg-green-100 text-green-600';
//         default:
//             return 'bg-gray-100 text-gray-600';
//     }
// }
//
// function getActivityIcon(type: string) {
//     switch (type) {
//         case 'ticket_linked':
//             return <Link className="h-3 w-3" />;
//         case 'status_changed':
//             return <Activity className="h-3 w-3" />;
//         case 'assigned':
//             return <User className="h-3 w-3" />;
//         default:
//             return <MessageSquare className="h-3 w-3" />;
//     }
// }
