import React, { useState, DragEvent } from 'react';
import { X, Paperclip, Upload } from 'lucide-react';

export interface FileUploadProps {
    onFilesChange: (files: File[]) => void;
    existingFiles?: File[];
    maxSize?: number;
    compact?: boolean;
}

export interface AttachmentPreviewProps {
    files: File[];
    onRemove: (index: number) => void;
    compact?: boolean;
}

// File List component remains the same
export const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({ files, onRemove, compact = false }) => {
    if (files.length === 0) return null;

    return (
        <div className={`space-y-2 ${compact ? 'mt-2' : 'mt-4'}`}>
            {files.map((file, index) => (
                <div
                    key={`${file.name}-${index}`}
                    className="flex items-center justify-between bg-gray-50 rounded-md p-2 text-sm"
                >
                    <div className="flex items-center space-x-2 truncate">
                        <Paperclip className="h-4 w-4 text-gray-400 flex-shrink-0" />
                        <span className="truncate text-gray-600">{file.name}</span>
                        <span className="text-gray-400 text-xs">
                            ({Math.round(file.size / 1024)} KB)
                        </span>
                    </div>
                    <button
                        onClick={() => onRemove(index)}
                        className="ml-2 text-gray-400 hover:text-gray-600 p-1 rounded-md hover:bg-gray-100"
                        title="Αφαίρεση αρχείου"
                    >
                        <X className="h-4 w-4" />
                    </button>
                </div>
            ))}
        </div>
    );
};

// Updated FileUpload component with drag and drop
export const FileUpload: React.FC<FileUploadProps> = ({
                                                          onFilesChange,
                                                          existingFiles = [],
                                                          maxSize = 20,
                                                          compact = false
                                                      }) => {
    const [currentFiles, setCurrentFiles] = useState<File[]>(existingFiles);
    const [isDragging, setIsDragging] = useState(false);

    const validateAndAddFiles = (files: File[]) => {
        // Validate file sizes
        const validFiles = files.filter(file => {
            const isValidSize = file.size <= maxSize * 1024 * 1024;
            if (!isValidSize) {
                console.warn(`File ${file.name} exceeds ${maxSize}MB limit`);
            }
            return isValidSize;
        });

        const updatedFiles = [...currentFiles, ...validFiles];
        setCurrentFiles(updatedFiles);
        onFilesChange(updatedFiles);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(e.target.files || []);
        if (selectedFiles.length === 0) return;
        validateAndAddFiles(selectedFiles);
    };

    const handleRemoveFile = (index: number) => {
        const updatedFiles = currentFiles.filter((_, i) => i !== index);
        setCurrentFiles(updatedFiles);
        onFilesChange(updatedFiles);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const droppedFiles = Array.from(e.dataTransfer.files);
        validateAndAddFiles(droppedFiles);
    };

    return (
        <div className="space-y-4">
            <div
                className={`border-2 border-dashed rounded-lg transition-colors
                    ${isDragging ? 'border-primary-500 bg-primary-50' : 'border-gray-300'}
                    ${compact ? 'p-4' : 'p-6'}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <div className="text-center">
                    <Upload className={`mx-auto text-gray-400 ${compact ? 'h-8 w-8' : 'h-12 w-12'}`} />
                    <div className="mt-4 flex text-sm justify-center">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500"
                        >
                            <span>Επιλέξτε αρχεία</span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                multiple
                                onChange={handleFileChange}
                            />
                        </label>
                        <p className="pl-1">ή σύρετε τα αρχεία εδώ</p>
                    </div>
                    <p className="text-xs text-gray-500 mt-2">
                        Μέγιστο μέγεθος: {maxSize}MB
                    </p>
                </div>
            </div>

            <AttachmentPreview
                files={currentFiles}
                onRemove={handleRemoveFile}
                compact={compact}
            />
        </div>
    );
};
