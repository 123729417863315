// CreateCaseForm.tsx
import React, { useState } from 'react';
import {FileText, Link, User, Check, X} from 'lucide-react';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import CategoryDropdown from '@/components/ui/DropdownWithLabel/CategoryDropdown';
import PriorityDropdown from '@/components/ui/DropdownWithLabel/PriorityDropdown';
import UserDropdown from '@/components/ui/DropdownWithLabel/UserDropdown';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import type { CreateCaseRequest } from '@/types/case.types';
import StatusBadge from '@/components/ui/Badge/StatusBadge';
import PriorityBadge from '@/components/ui/Badge/PriorityBadge';
import { TimeAgo } from '@/components/ui/TimeAgo/TimeAgo';
import {Card} from "@/components/ui/Card";
import {StatusEnum} from "@/types/status.types";
import {PriorityEnum} from "@/types/priority.types";
import {TicketLinkingModal} from "@/pages/Cases/TicketLinkingModal";
import {Ticket} from "@/types/ticket.types";
import useUserStore from "@/stores/useUserStore";

interface Step {
    id: string;
    name: string;
    status: 'complete' | 'current' | 'upcoming';
}

const CreateCaseForm: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [showTicketModal, setShowTicketModal] = useState(false);
    const [formData, setFormData] = useState<CreateCaseRequest>({
        title: '',
        description: '',
        category_id: '',
        priority_id: '',
        ticket_ids: [],
        assignee_id: undefined
    });

    const userStore = useUserStore();
    const companyId = userStore.user?.company_id;

    const [linkedTickets, setLinkedTickets] = useState<Ticket[]>([]);

    const steps: Step[] = [
        { id: '01', name: 'Βασικές Πληροφορίες', status: 'current' },
        { id: '02', name: 'Σχετικά Αιτήματα', status: 'upcoming' },
        { id: '03', name: 'Ανάθεση', status: 'upcoming' }
    ].map((step, index) => ({
        ...step,
        status: index + 1 < currentStep
            ? 'complete'
            : index + 1 === currentStep
                ? 'current'
                : 'upcoming'
    }));

    const handleNext = () => {
        setCurrentStep(prev => Math.min(prev + 1, 3));
    };

    const handleBack = () => {
        setCurrentStep(prev => Math.max(prev - 1, 1));
    };

    const handleSubmit = async () => {
        try {
            // Add your submission logic here
            console.log('Submitting:', formData);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleTicketLink = (tickets: Ticket[]) => {
        const ticketIds = tickets.map(t => t.ticket_id);
        setFormData(prev => ({
            ...prev,
            ticket_ids: [...prev.ticket_ids, ...ticketIds]
        }));
        setLinkedTickets(prev => [...prev, ...tickets]);
        setShowTicketModal(false);
    };

    const handleRemoveTicket = (ticketId: string) => {
        setFormData(prev => ({
            ...prev,
            ticket_ids: prev.ticket_ids.filter(id => id !== ticketId)
        }));
        setLinkedTickets(prev => prev.filter(ticket => ticket.ticket_id !== ticketId));
    };

    return (
        <div className="max-w-4xl mx-auto p-6">
            {/* Header */}
            <div className="mb-8">
                <h1 className="text-2xl font-bold text-gray-900">Δημιουργία Υπόθεσης</h1>
                <p className="mt-1 text-sm text-gray-500">
                    Δημιουργήστε μια νέα υπόθεση και συνδέστε σχετικά αιτήματα
                </p>
            </div>

            {/* Progress Navigation */}
            <nav aria-label="Progress" className="mb-8">
                <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                    {steps.map((step, stepIdx) => (
                        <li key={step.name} className="relative md:flex md:flex-1">
                            {step.status === 'complete' ? (
                                <div className="group flex w-full items-center">
                                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-600 group-hover:bg-primary-800">
                                            <Check className="h-6 w-6 text-white" />
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                                    </span>
                                </div>
                            ) : step.status === 'current' ? (
                                <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-600">
                                        <span className="text-primary-600">{step.id}</span>
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-primary-600">{step.name}</span>
                                </div>
                            ) : (
                                <div className="group flex items-center">
                                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                                            <span className="text-gray-500">{step.id}</span>
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-gray-500">{step.name}</span>
                                    </span>
                                </div>
                            )}
                            {stepIdx !== steps.length - 1 && (
                                <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                                    <svg
                                        className="h-full w-full text-gray-300"
                                        viewBox="0 0 22 80"
                                        fill="none"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>

            {/* Form Content */}
            <Card className="p-6">
                {currentStep === 1 && (
                    <div className="space-y-6">
                        <div className="flex items-center gap-2 mb-6">
                            <FileText className="w-5 h-5 text-gray-400" />
                            <h2 className="text-lg font-medium">Βασικές Πληροφορίες</h2>
                        </div>
                        <InputWithLabel
                            label="Τίτλος"
                            required
                            value={formData.title}
                            onChange={(e) => setFormData(prev => ({...prev, title: e.target.value}))}
                        />
                        <TextArea
                            label="Περιγραφή"
                            required
                            value={formData.description}
                            onChange={(e) => setFormData(prev => ({...prev, description: e.target.value}))}
                        />
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <CategoryDropdown
                                required
                                value={formData.category_id}
                                onChange={(value) => setFormData(prev => ({...prev, category_id: value}))}
                            />
                            <PriorityDropdown
                                required
                                value={formData.priority_id}
                                onChange={(value) => setFormData(prev => ({...prev, priority_id: value}))}
                            />
                        </div>
                    </div>
                )}

                {currentStep === 2 && (
                    <div className="space-y-6">
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center gap-2">
                                <Link className="w-5 h-5 text-gray-400" />
                                <h2 className="text-lg font-medium">Σχετικά Αιτήματα</h2>
                            </div>
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.SM}
                                onClick={() => setShowTicketModal(true)}
                            >
                                <Link className="w-4 h-4 mr-2" />
                                Σύνδεση Αιτήματος
                            </Button>
                        </div>

                        {linkedTickets.length === 0 ? (
                            <div className="text-center py-12 border-2 border-dashed border-gray-300 rounded-lg">
                                <p className="text-sm text-gray-500">
                                    Δεν έχουν συνδεθεί αιτήματα
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                    Πατήστε το κουμπί "Σύνδεση Αιτήματος" για να προσθέσετε αιτήματα
                                </p>
                            </div>
                        ) : (
                            <div className="space-y-3">
                                {linkedTickets.map(ticket => (
                                    <div
                                        key={ticket.ticket_id}
                                        className="flex items-start justify-between p-4 bg-gray-50 rounded-lg"
                                    >
                                        <div className="space-y-1">
                                            <div className="flex items-center gap-2">
                                                <span className="font-medium">#{ticket.ticket_number}</span>
                                                <StatusBadge status={StatusEnum.PENDING} />
                                                <PriorityBadge priority={PriorityEnum.LOW} />
                                            </div>
                                            <p className="text-sm text-gray-900">{ticket.title}</p>
                                            <div className="flex items-center gap-2 text-xs text-gray-500">
                                                <TimeAgo date={ticket.created_at} />
                                            </div>
                                        </div>
                                        <Button
                                            variant={ButtonVariant.GHOST}
                                            size={ButtonSize.SM}
                                            onClick={() => handleRemoveTicket(ticket.ticket_id)}
                                            className="text-gray-400 hover:text-gray-500"
                                        >
                                            <X className="h-4 w-4" />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {currentStep === 3 && (
                    <div className="space-y-6">
                        <div className="flex items-center gap-2 mb-6">
                            <User className="w-5 h-5 text-gray-400" />
                            <h2 className="text-lg font-medium">Ανάθεση</h2>
                        </div>
                        <UserDropdown
                            label="Υπεύθυνος Υπόθεσης"
                            value={formData.assignee_id}
                            company_id={companyId}
                            onChange={(value) => setFormData(prev => ({...prev, assignee_id: value}))}
                        />
                    </div>
                )}

                <div className="flex justify-between mt-8 pt-6 border-t">
                    <Button
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.LG}
                        onClick={handleBack}
                        disabled={currentStep === 1}
                    >
                        Προηγούμενο
                    </Button>
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LG}
                        onClick={currentStep === 3 ? handleSubmit : handleNext}
                    >
                        {currentStep === 3 ? 'Δημιουργία Υπόθεσης' : 'Επόμενο'}
                    </Button>
                </div>
            </Card>

            {showTicketModal && (
                <TicketLinkingModal
                    isOpen={showTicketModal}
                    onClose={() => setShowTicketModal(false)}
                    onLink={handleTicketLink}
                    excludeTicketIds={formData.ticket_ids}
                />
            )}
        </div>
    );
};

export default CreateCaseForm;
