import { useQuery } from "@tanstack/react-query";
import { STATUS_KEYS } from "./statuses.queryKeys";
import { statusesApi } from "./statuses.api";
import { Status } from "@/types/status.types";

export const useGetStatusesQuery = () => {
    return useQuery<Status[]>({
        queryKey: STATUS_KEYS.list,
        queryFn: statusesApi.getAll,
    });
};
