// src/pages/CompaniesList.tsx
import React from 'react';
import {type ColumnDef} from '@tanstack/react-table';
import {Building2,  Mail, MapPin, Phone, Plus, RefreshCcw} from 'lucide-react';
import ColumnTitle from '@/components/ui/DataTable/ColumnTitle';
import Button from '@/components/ui/Button';
import {ButtonSize, ButtonType, ButtonVariant} from '@/components/ui/Button/ButtonSize';
import DataTable from "@/components/ui/DataTable";
import {Company} from "@/types/company.types";
import {useGetCompaniesQuery} from "@/components/features/companies/services/companies.queries";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "@/constants/routes.constants";
import {RowActions} from "@/components/ui/DataTable/RowActions";


const CompaniesList = () => {
    const navigate = useNavigate();
    const {data: companies = [], isLoading, refetch} = useGetCompaniesQuery();

    const addCompany = () => {
        navigate(ROUTES.COMPANIES.NEW);
    }

    const columns: ColumnDef<Company, string>[] = [
        {
            id: 'company',
            header: ({column}) => (
                <ColumnTitle title="Εταιρία" isSortable column={column}/>
            ),
            accessorFn: (row) => `${row.company_name}`,
            cell: ({row}) => (
                <div className="flex items-center gap-2">
                    <Building2 className="w-4 h-4 text-gray-400"/>
                    <span>{row.original.company_name}</span>
                </div>
            ),
        },
        {
            id: 'address',
            header: ({column}) => (
                <ColumnTitle title="Διεύθυνση" isSortable column={column}/>
            ),
            accessorKey: 'address',
            cell: ({row}) => (
                <div className="flex items-center gap-2">
                    <MapPin className="w-4 h-4 text-gray-400"/>
                    <span>{row.original.address}</span>
                </div>
            ),
        },
        {
            id: 'phone_number',
            header: ({column}) => (
                <ColumnTitle title="Τηλ. Επικοινωνίας" isSortable column={column}/>
            ),
            accessorKey: 'phone_number',
            cell: ({row}) => (
                <div className="flex items-center gap-2">
                    <Phone className="w-4 h-4 text-gray-400"/>
                    <span>{row.original.phone_number}</span>
                </div>
            ),
        },
        {
            id: 'email_address',
            header: ({column}) => (
                <ColumnTitle title="Email" isSortable column={column}/>
            ),
            accessorKey: 'email_address',
            cell: ({row}) => (
                <div className="flex items-center gap-2">
                    <Mail className="w-4 h-4 text-gray-400"/>
                    <span>{row.original.email_address}</span>
                </div>
            ),
        },
        {
            id: 'actions',
            header: 'Ενέργειες',
            cell: ({ row }) => (
                <RowActions
                    actions={[
                        {
                            type: 'view',
                            path: ROUTES.COMPANIES.DETAILS(row.original.company_id),
                            show: true, // or some permission check
                        }
                    ]}
                />
            ),
        }
    ];

    if (isLoading) return

    return (
        <div className="h-full w-full">
            <div className="mx-auto bg-gray-100 p-4 sm:p-6 lg:p-8">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">Εταιρίες</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Διαχείριση Εταιριών
                    </p>
                </div>

                <div className="mb-6 flex flex-col sm:flex-row justify-between gap-4">
                    <div className="flex flex-wrap gap-2">
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            className="flex items-center"
                            onClick={() => refetch()}
                            type={ButtonType.BUTTON}
                        >
                            <RefreshCcw className="w-4 h-4 mr-2"/>
                            Ανανέωση
                        </Button>
                    </div>
                    <div className="flex gap-2">
                        <Button
                            onClick={addCompany}
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SM}
                            className="flex items-center"
                        >
                            <Plus className="w-4 h-4 mr-2"/>
                            Προσθήκη Εταιρίας
                        </Button>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow">
                    <DataTable
                        columns={columns}
                        data={companies}
                        enableColumnFilters={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default CompaniesList;
