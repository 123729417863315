import { z } from 'zod';
import {ProductTypeFormFields} from "@/types/productTypes.types";

export const productTypeSchema = z.object({
    product_type_name: z.string()
        .min(2, 'Το όνομα πρέπει να έχει τουλάχιστον 2 χαρακτήρες')
        .max(150, 'Το όνομα δεν μπορεί να υπερβαίνει τους 150 χαρακτήρες'),
    description: z.string()
        .min(2, 'Η περιγραφή πρέπει να έχει τουλάχιστον 2 χαρακτήρες')
        .max(500, 'Η περιγραφή δεν μπορεί να υπερβαίνει τους 500 χαρακτήρες')
}) satisfies z.ZodType<ProductTypeFormFields>;
