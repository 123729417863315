import React from 'react';
import {
    AlertCircle,
    ArrowDown,
    ArrowUp,
    Clock,
    FileText,
    Filter,
    MessageSquare,
    Plus,
    Search,
    Timer,
    Users
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { TimeAgo } from "@/components/ui/TimeAgo/TimeAgo";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import {PriorityEnum} from "@/types/priority.types";
import {StatusEnum} from "@/types/status.types";

export default function ClientManagerDashboard() {
    // State for quick ticket creation form
    const [showQuickCreate, setShowQuickCreate] = React.useState(false);

    const stats = {
        departmentTickets: {
            count: 45,
            trend: 5,
            trendUp: true
        },
        openTickets: {
            count: 12,
            trend: -2,
            trendUp: false
        },
        avgResponseTime: {
            value: "2.8 ώρες",
            trend: -15,
            trendUp: false
        },
        slaCompliance: {
            value: "92%",
            trend: 3,
            trendUp: true
        }
    };

    const teamMembers = [
        {
            name: "Γιώργος Κ.",
            position: "Υπεύθυνος Έργων",
            activeTickets: 5,
            resolvedToday: 3,
            status: "online"
        },
        {
            name: "Μαρία Π.",
            position: "Τεχνικός",
            activeTickets: 3,
            resolvedToday: 4,
            status: "online"
        }
    ];

    const departmentTickets = [
        {
            id: "TK-3456",
            title: "Αναβάθμιση συστήματος ασφαλείας",
            status: StatusEnum.OPEN,
            priority: PriorityEnum.HIGH,
            assignedTo: "Γιώργος Κ.",
            createdAt: "2024-02-15T10:30:00Z",
            slaRemaining: "4 ώρες"
        }
    ];

    return (
        <div className="h-full w-full bg-gray-100">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                {/* Header Section */}
                <div className="mb-8">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">Επισκόπηση Τμήματος</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Διαχείριση και παρακολούθηση αιτημάτων τμήματος
                            </p>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.SM}
                                onClick={() => setShowQuickCreate(!showQuickCreate)}
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Νέο Αίτημα
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Total Department Tickets */}
                    <Card className="bg-blue-50 border-blue-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-blue-700">Συνολικά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-blue-900">
                                            {stats.departmentTickets.count}
                                        </p>
                                        <p className={`ml-2 text-sm ${stats.departmentTickets.trendUp ? 'text-error-600' : 'text-success-600'}`}>
                                            {stats.departmentTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.departmentTickets.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-blue-100 rounded-lg">
                                    <FileText className="h-6 w-6 text-blue-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Open Tickets */}
                    <Card className="bg-green-50 border-green-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-green-700">Ανοιχτά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-green-900">
                                            {stats.openTickets.count}
                                        </p>
                                        <p className={`ml-2 text-sm ${!stats.openTickets.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.openTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {Math.abs(stats.openTickets.trend)}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-green-100 rounded-lg">
                                    <MessageSquare className="h-6 w-6 text-green-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Response Time */}
                    <Card className="bg-purple-50 border-purple-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-purple-700">Μέσος Χρόνος Απόκρισης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-purple-900">
                                            {stats.avgResponseTime.value}
                                        </p>
                                        <p className={`ml-2 text-sm ${!stats.avgResponseTime.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.avgResponseTime.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.avgResponseTime.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-purple-100 rounded-lg">
                                    <Timer className="h-6 w-6 text-purple-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Compliance */}
                    <Card className="bg-yellow-50 border-yellow-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-yellow-700">SLA Συμμόρφωση</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-yellow-900">
                                            {stats.slaCompliance.value}
                                        </p>
                                        <p className={`ml-2 text-sm ${stats.slaCompliance.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.slaCompliance.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.slaCompliance.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-yellow-100 rounded-lg">
                                    <Clock className="h-6 w-6 text-yellow-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                    {/* Team Overview */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Επισκόπηση Ομάδας</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {teamMembers.map((member) => (
                                    <div key={member.name} className="bg-gray-50 rounded-lg p-4">
                                        <div className="flex items-center justify-between mb-2">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                                                        <Users className="h-6 w-6 text-gray-600" />
                                                    </div>
                                                </div>
                                                <div className="ml-4">
                                                    <div className="flex items-center">
                                                        <h3 className="text-sm font-medium text-gray-900">
                                                            {member.name}
                                                        </h3>
                                                        <span className={`ml-2 h-2 w-2 rounded-full ${
                                                            member.status === 'online' ? 'bg-green-400' : 'bg-gray-400'
                                                        }`} />
                                                    </div>
                                                    <p className="text-sm text-gray-500">{member.position}</p>
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <div className="text-sm text-gray-900">{member.activeTickets} ενεργά</div>
                                                <div className="text-sm text-gray-500">{member.resolvedToday} επιλύθηκαν σήμερα</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Status */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Κατάσταση SLA</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {/* Alert Section */}
                                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                                    <div className="flex items-center">
                                        <AlertCircle className="h-5 w-5 text-yellow-400" />
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-yellow-800">
                                                Επερχόμενες Λήξεις SLA
                                            </h3>
                                            <p className="text-sm text-yellow-700 mt-1">
                                                2 αιτήματα πλησιάζουν στη λήξη του SLA
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Priority Response Times */}
                                <div className="bg-gray-50 rounded-lg p-4">
                                    <h4 className="text-sm font-medium text-gray-900 mb-4">
                                        Χρόνοι Απόκρισης ανά Προτεραιότητα
                                    </h4>
                                    <div className="space-y-4">
                                        {/* High Priority */}
                                        <div>
                                            <div className="flex justify-between mb-1">
                                                <span className="text-sm font-medium text-gray-600">Υψηλή</span>
                                                <span className="text-sm text-gray-600">1.5 ώρες</span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div className="bg-green-600 h-2 rounded-full w-[90%]"></div>
                                            </div>
                                        </div>
                                        {/* Medium Priority */}
                                        <div>
                                            <div className="flex justify-between mb-1">
                                                <span className="text-sm font-medium text-gray-600">Μεσαία</span>
                                                <span className="text-sm text-gray-600">3.2 ώρες</span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div className="bg-green-600 h-2 rounded-full w-[85%]"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Department Tickets */}
                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center justify-between">
                                <span>Αιτήματα Τμήματος</span>
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                >
                                    Προβολή Όλων
                                </Button>
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {departmentTickets.map((ticket) => (
                                    <div key={ticket.id} className="flex items-start justify-between border-b pb-4">
                                        <div className="space-y-1">
                                            <div className="flex items-center space-x-2">
                                                <span className="font-medium">#{ticket.id}</span>
                                                <PriorityBadge priority={ticket.priority} />
                                                <StatusBadge status={ticket.status} />
                                            </div>
                                            <p className="text-sm text-gray-900">{ticket.title}</p>
                                            <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                <Users className="h-4 w-4" />
                                                <span>{ticket.assignedTo}</span>
                                                <span className="text-error-600">
                                                    <Clock className="h-4 w-4 inline mr-1" />
                                                    SLA: {ticket.slaRemaining}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <TimeAgo date={ticket.createdAt} className="text-sm text-gray-500" />
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                size={ButtonSize.SM}
                                                className="mt-2"
                                            >
                                                Προβολή
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* Performance Metrics */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Μετρήσεις Απόδοσης</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-6">
                                {/* Weekly Stats */}
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <p className="text-sm font-medium text-gray-500">Επιλύθηκαν αυτή την εβδομάδα</p>
                                        <p className="mt-1 text-2xl font-semibold text-gray-900">28</p>
                                        <p className="mt-1 text-sm text-green-600">+12% από την προηγούμενη εβδομάδα</p>
                                    </div>
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <p className="text-sm font-medium text-gray-500">Μέσος χρόνος επίλυσης</p>
                                        <p className="mt-1 text-2xl font-semibold text-gray-900">5.2 ώρες</p>
                                        <p className="mt-1 text-sm text-green-600">-8% από την προηγούμενη εβδομάδα</p>
                                    </div>
                                </div>

                                {/* Resolution Rate */}
                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <div className="flex justify-between items-center mb-2">
                                        <h4 className="text-sm font-medium text-gray-900">Ποσοστό Επίλυσης</h4>
                                        <span className="text-sm font-medium text-green-600">88%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2">
                                        <div className="bg-green-600 h-2 rounded-full w-[88%]"></div>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-500">
                                        22 από 25 αιτήματα επιλύθηκαν εντός SLA
                                    </div>
                                </div>

                                {/* Priority Distribution */}
                                <div>
                                    <h4 className="text-sm font-medium text-gray-900 mb-4">Κατανομή ανά Προτεραιότητα</h4>
                                    <div className="space-y-2">
                                        <div className="flex items-center">
                                            <span className="w-24 text-sm text-gray-500">Υψηλή</span>
                                            <div className="flex-1 mx-2">
                                                <div className="w-full bg-gray-200 rounded-full h-2">
                                                    <div className="bg-red-600 h-2 rounded-full w-[30%]"></div>
                                                </div>
                                            </div>
                                            <span className="text-sm text-gray-500">30%</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="w-24 text-sm text-gray-500">Μεσαία</span>
                                            <div className="flex-1 mx-2">
                                                <div className="w-full bg-gray-200 rounded-full h-2">
                                                    <div className="bg-yellow-600 h-2 rounded-full w-[45%]"></div>
                                                </div>
                                            </div>
                                            <span className="text-sm text-gray-500">45%</span>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="w-24 text-sm text-gray-500">Χαμηλή</span>
                                            <div className="flex-1 mx-2">
                                                <div className="w-full bg-gray-200 rounded-full h-2">
                                                    <div className="bg-green-600 h-2 rounded-full w-[25%]"></div>
                                                </div>
                                            </div>
                                            <span className="text-sm text-gray-500">25%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}

