
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {slaApi} from "@/components/features/sla/services/sla.api";
import {SLA} from "@/types/sla.types";
import {COMPANY_KEYS} from "@/components/features/companies/services/companies.queryKeys";

export const useUpdateSlaMutation = (companyId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ slaId, data }: { slaId: string, data: Omit<SLA, 'sla_id' | 'created_at' | 'updated_at' | 'company' | 'priority'> }) =>
            slaApi.update(slaId, data),
        onSuccess: async () => {
            toast.success('Το SLA ενημερώθηκε επιτυχώς');
            // Invalidate the company SLAs query to refetch the updated data
            await queryClient.invalidateQueries({
                queryKey: COMPANY_KEYS.sla(companyId)
            });
        },
        onError: (error) => {
            toast.error('Σφάλμα κατά την ενημέρωση του SLA');
            console.error('Update SLA error:', error);
        }
    });
};
