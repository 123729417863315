import { useQuery } from "@tanstack/react-query";
import { ROLE_KEYS } from "./roles.queryKeys";
import { rolesApi } from "./roles.api";
import { Role } from "@/types/role.types";

export const useGetRolesQuery = () => {
    return useQuery<Role[]>({
        queryKey: ROLE_KEYS.list,
        queryFn: rolesApi.getAll,
    });
};
