import React, { useState } from 'react';
import { Briefcase, Plus, Pencil, Trash2, CalendarDays, AlertTriangle } from 'lucide-react';
import { type ColumnDef } from '@tanstack/react-table';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/Card';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import DataTable from '@/components/ui/DataTable';
import { Department } from '@/types/department.types';
import { useGetDepartmentsByCompanyQuery } from '@/components/features/departments/services/departments.queries';
import { useDeleteDepartmentMutation } from '@/components/features/departments/services/departments.mutations';
import DepartmentDrawer from "@/components/ui/Drawer/DepartmentDrawer";
import Modal from '@/components/ui/Modal';
import {PermissionGuard} from "@/components/guards/PermissionGuard";

interface CompanyDepartmentsTabProps {
    companyId: string;
}

const CompanyDepartmentsTab = ({ companyId }: CompanyDepartmentsTabProps) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editingDepartment, setEditingDepartment] = useState<Department | null>(null);
    const [departmentToDelete, setDepartmentToDelete] = useState<Department | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const { data: departments = [], isLoading } = useGetDepartmentsByCompanyQuery(companyId);
    const deleteMutation = useDeleteDepartmentMutation(companyId);

    const handleEdit = (department: Department) => {
        setEditingDepartment(department);
        setIsDrawerOpen(true);
    };

    const handleDeleteClick = (department: Department) => {
        setDepartmentToDelete(department);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!departmentToDelete) return;

        try {
            await deleteMutation.mutateAsync(departmentToDelete.department_id);
            setIsDeleteModalOpen(false);
            setDepartmentToDelete(null);
        } catch (error) {
            console.error('Failed to delete department:', error);
        }
    };

    const columns: ColumnDef<Department, string>[] = [
        {
            id: 'department',
            header: 'Τμήμα',
            accessorKey: 'department_name',
            cell: ({ row }) => (
                <div className="flex items-center space-x-3">
                    <div className="p-2 bg-primary-50 rounded-lg">
                        <Briefcase className="w-4 h-4 text-primary-600" />
                    </div>
                    <div>
                        <div className="font-medium text-gray-900">
                            {row.original.department_name}
                        </div>
                        {row.original.description && (
                            <div className="text-sm text-gray-500">
                                {row.original.description}
                            </div>
                        )}
                    </div>
                </div>
            ),
        },
        {
            id: 'created',
            header: 'Ημερομηνία Δημιουργίας',
            accessorKey: 'created_at',
            cell: ({ row }) => (
                <div className="flex items-center text-gray-500">
                    <CalendarDays className="w-4 h-4 mr-2" />
                    {new Date(row.original.created_at).toLocaleDateString('el-GR')}
                </div>
            ),
        },
        {
            id: 'actions',
            header: '',
            cell: ({ row }) => (
                <div className="flex justify-end gap-2">
                    <PermissionGuard requiredPermissions={['update:departments']}>
                        <Button
                        variant={ButtonVariant.GHOST}
                        size={ButtonSize.SM}
                        onClick={() => handleEdit(row.original)}
                    >
                        <Pencil className="h-4 w-4" />
                    </Button>
                    </PermissionGuard>
                    <PermissionGuard requiredPermissions={['delete:departments']}>
                    <Button
                        variant={ButtonVariant.GHOST}
                        size={ButtonSize.SM}
                        className="text-red-600 hover:text-red-700"
                        onClick={() => handleDeleteClick(row.original)}
                    >
                        <Trash2 className="h-4 w-4" />
                    </Button>
                    </PermissionGuard>
                </div>
            ),
        },
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    <div className="flex items-center justify-between">
                        <CardTitle className="flex items-center">
                            <Briefcase className="w-5 h-5 mr-2 text-gray-500" />
                            Τμήματα Εταιρίας
                        </CardTitle>
                        <PermissionGuard requiredPermissions={['create:departments']}>
                            <Button
                                variant={ButtonVariant.OUTLINE}
                                size={ButtonSize.SM}
                                onClick={() => {
                                    setEditingDepartment(null);
                                    setIsDrawerOpen(true);
                                }}
                            >
                                <Plus className="w-4 h-4 mr-2" />
                                Προσθήκη Τμήματος
                            </Button>
                        </PermissionGuard>
                    </div>
                </CardHeader>
                <CardContent>
                    <DataTable
                        columns={columns}
                        data={departments}
                        isLoading={isLoading}
                    />
                </CardContent>
            </Card>

            <DepartmentDrawer
                isOpen={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                    setEditingDepartment(null);
                }}
                editingDepartment={editingDepartment}
                companyId={companyId}
            />

            <Modal
                open={isDeleteModalOpen}
                setOpen={setIsDeleteModalOpen}
                title="Διαγραφή Τμήματος"
                type="delete"
                icon={<AlertTriangle className="h-6 w-6 text-error-600" />}
                primaryAction={{
                    text: "Διαγραφή",
                    onClick: handleDeleteConfirm,
                }}
                secondaryAction={{
                    text: "Άκυρο",
                    onClick: () => {
                        setIsDeleteModalOpen(false);
                        setDepartmentToDelete(null);
                    },
                }}
            >
                <p className="text-sm text-gray-500">
                    Είστε σίγουροι ότι θέλετε να διαγράψετε το τμήμα "{departmentToDelete?.department_name}";
                    <br />
                    Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.
                </p>
            </Modal>
        </>
    );
};

export default CompanyDepartmentsTab;
