import React from "react";

interface PageTitleProps {
    title?: string;
    description?: string;
    actions?: React.ReactNode;
}

export function PageTitle({ title, description, actions }: PageTitleProps) {
    return (
        <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
            <p className="mt-1 text-md text-gray-500">
                {description}
            </p>
            {actions}
        </div>
    );
}
