// AppRoutes.tsx
import {Route, Routes} from 'react-router-dom'
import {ProtectedRoute} from '@/components/ProtectedRoute'
import DashboardLayout from "@/components/layouts/DashboardLayout";
import Login from "@/pages/Login";
import NotFound from "@/pages/404";
import TicketDetails from "@/pages/Tickets/TicketDetails";
import TicketsList from "@/pages/Tickets/TicketsList";
import CreateTicket from "@/pages/Tickets/CreateTicket";
import UsersList from "@/pages/Users/UsersList";
import CreateUser from "@/pages/Users/CreateUser";
import UserProfile from "@/pages/Users/UserProfile";
import TicketStatusGuide from "@/pages/Information/TicketStatusGuide";
import TicketPriorityGuide from "@/pages/Information/TicketPriorityGuide";
import TicketCategories from "@/pages/Tickets/TicketCategories";
import SLAPoliciesManagement from "@/pages/SLA/SLAPoliciesManagement";
import CasesManagement from "@/pages/Cases/CasesManagement";
import RoleGuard from "@/components/guards/RoleGuard";
import ProductsList from "@/pages/Products/ProductsList";
import CreateProduct from "@/pages/Products/CreateProduct";
import ProductTypes from "@/pages/Products/ProductTypes";
import CompaniesList from "@/pages/Companies/CompaniesList";
import CreateCompany from "@/pages/Companies/CreateCompany";
import CompanyDetails from "@/pages/Companies/CompanyDetails";
import EditCompany from "@/pages/Companies/EditCompany";
// import TicketDetailsDummy from "@/pages/Tickets/TicketDetailsDummy";
// import HelpDeskDashboard from "@/components/layouts/Dashboard/HelpDeskDashboard";
// import ManagerDashboard from "@/components/layouts/Dashboard/ManagerDashboard";
// import AgentDashboard from "@/components/layouts/Dashboard/AgentDashboard";
// import ClientAdministratorDashboard from "@/components/layouts/Dashboard/ClientAdministratorDashboard";
// import ClientManagerDashboard from "@/components/layouts/Dashboard/ClientManagerDashboard";
// import ClientEmployeeDashboard from "@/components/layouts/Dashboard/ClientEmployeeDashboard";
import Dashboard from "@/components/layouts/Dashboard/Dashboard";
import CreateCase from "@/pages/Cases/CreateCase";
import CaseDetails from "@/pages/Cases/CaseDetails";
import ClientEmployeeDashboard from "@/components/layouts/Dashboard/ClientEmployeeDashboard";
import HelpDeskDashboard from "@/components/layouts/Dashboard/HelpDeskDashboard";
import ManagerDashboard from "@/components/layouts/Dashboard/ManagerDashboard";
import AgentDashboard from "@/components/layouts/Dashboard/AgentDashboard";
import ClientAdministratorDashboard from "@/components/layouts/Dashboard/ClientAdministratorDashboard";
import ClientManagerDashboard from "@/components/layouts/Dashboard/ClientManagerDashboard";

export default function AppRoutes() {

    return (
        <Routes>
            {/* Public routes */}
            <Route path="/login" element={
                <Login/>
            }/>

            {/* Protected routes */}
            <Route element={
                <ProtectedRoute>
                    <DashboardLayout/>
                </ProtectedRoute>
            }>
                {/*<Route index element={<Navigate to="/dashboard" replace />} />*/}
                <Route index path="" element={<Dashboard/>}/>

                {/*<Route path="users">*/}
                {/*    <Route index element={*/}
                {/*        <ProtectedRoute requiredPermission="canViewUsers">*/}
                {/*            <UsersList />*/}
                {/*        </ProtectedRoute>*/}
                {/*    } />*/}
                {/*    <Route path="new" element={*/}
                {/*        <ProtectedRoute requiredPermission="canManageUsers">*/}
                {/*            <CreateUser />*/}
                {/*        </ProtectedRoute>*/}
                {/*    } />*/}
                {/*</Route>*/}

                <Route path="companies">
                    <Route index element={
                        <RoleGuard>
                            <CompaniesList/>
                        </RoleGuard>
                    }/>
                    <Route path="new" element={
                        <RoleGuard>
                            <CreateCompany/>
                        </RoleGuard>
                    }/>
                    <Route path=":id" element={
                        <RoleGuard>
                            <CompanyDetails/>
                        </RoleGuard>
                    }/>
                    <Route path=":id/edit" element={
                        <RoleGuard>
                            <EditCompany/>
                        </RoleGuard>
                    }/>
                </Route>

                <Route path="company" element={
                    <RoleGuard>
                        <CompanyDetails isProfile={true} />
                    </RoleGuard>
                }/>

                <Route path="tickets">
                    <Route index element={
                        <RoleGuard>
                            <TicketsList/>
                        </RoleGuard>
                    }/>
                    <Route path="new" element={
                        <RoleGuard>
                            <CreateTicket/>
                        </RoleGuard>
                    }/>
                    <Route path="categories" element={
                        <RoleGuard>
                            <TicketCategories/>
                        </RoleGuard>
                    }/>
                    <Route path="priorities" element={
                        <RoleGuard>
                            <TicketPriorityGuide/>
                        </RoleGuard>
                    }/>
                    <Route path="statuses" element={
                        <RoleGuard>
                            <TicketStatusGuide/>
                        </RoleGuard>
                    }/>
                    {/*<Route path="id" element={*/}
                    {/*    <RoleGuard>*/}
                    {/*        <TicketDetailsDummy/>*/}
                    {/*    </RoleGuard>*/}
                    {/*}/>*/}
                    <Route path=":id" element={
                        <RoleGuard>
                            <TicketDetails/>
                        </RoleGuard>
                    }/>
                </Route>

                <Route path="cases">
                    <Route index element={
                        <RoleGuard>
                            <CasesManagement/>
                        </RoleGuard>
                    }/>
                    <Route path="new" element={
                        <RoleGuard>
                            <CreateCase />
                        </RoleGuard>
                    } />
                    <Route path="id" element={
                        <RoleGuard>
                            <CaseDetails />
                        </RoleGuard>
                    } />
                </Route>

                <Route path="users">
                    <Route index element={
                        <RoleGuard>
                            <UsersList/>
                        </RoleGuard>
                    }/>
                    <Route path="new" element={
                        <RoleGuard>
                            <CreateUser/>
                        </RoleGuard>
                    }/>
                    <Route path=":id" element={
                        <RoleGuard>
                            <UserProfile/>
                        </RoleGuard>
                    }/>
                    {/*<Route path="id" element={*/}
                    {/*    <RoleGuard>*/}
                    {/*        <UserProfileDummy/>*/}
                    {/*    </RoleGuard>*/}
                    {/*}/>*/}
                </Route>

                <Route path="profile" element={
                    <RoleGuard>
                        <UserProfile isProfile={true} />
                    </RoleGuard>
                }/>

                <Route path="products">
                    <Route index element={
                        <RoleGuard>
                            <ProductsList/>
                        </RoleGuard>
                    }/>
                    <Route path="new" element={
                        <RoleGuard>
                            <CreateProduct/>
                        </RoleGuard>
                    }/>
                    <Route path="types" element={
                        <RoleGuard>
                            <ProductTypes/>
                        </RoleGuard>
                    }/>
                </Route>

                <Route path="sla" element={<SLAPoliciesManagement/>}/>

                {/*<Route path="reports">*/}
                {/*    <Route path="tickets" element={*/}
                {/*        <RoleGuard>*/}
                {/*            <TicketsReport />*/}
                {/*        </RoleGuard>*/}
                {/*    } />*/}
                {/*    <Route path="sla" element={*/}
                {/*        <RoleGuard>*/}
                {/*            <SLAReport />*/}
                {/*        </RoleGuard>*/}
                {/*    } />*/}
                {/*</Route>*/}

                <Route path="dashboard">
                    <Route path="helpdesk" element={
                        <RoleGuard>
                            <HelpDeskDashboard />
                        </RoleGuard>
                    } />
                    <Route path="manager" element={
                        <RoleGuard>
                            <ManagerDashboard />
                        </RoleGuard>
                    } />
                    <Route path="agent" element={
                        <RoleGuard>
                            <AgentDashboard />
                        </RoleGuard>
                    } />
                    <Route path="client-administrator" element={
                        <RoleGuard>
                            <ClientAdministratorDashboard />
                        </RoleGuard>
                    } />
                    <Route path="client-manager" element={
                        <RoleGuard>
                            <ClientManagerDashboard />
                        </RoleGuard>
                    } />
                    <Route path="client-employee" element={
                        <RoleGuard>
                            <ClientEmployeeDashboard />
                        </RoleGuard>
                    } />
                </Route>

                <Route>
                    <Route path="priorities" element={
                        <RoleGuard>
                            <TicketPriorityGuide/>
                        </RoleGuard>
                    }/>
                    <Route path="status" element={
                        <RoleGuard>
                            <TicketStatusGuide/>
                        </RoleGuard>
                    }/>
                </Route>

                {/* Add other routes similarly */}
            </Route>

            {/*<Route path="unauthorized" element={<Unauthorized />} />*/}
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}
