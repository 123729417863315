import React, { useEffect } from 'react'

interface Tab {
    name: string
    href?: string
    content?: React.ReactNode
    disabled?: boolean
}

interface TabsComponentProps {
    tabs: Tab[]
    selectedTabName?: string
    onTabClick?: (tabName: string) => void
    className?: string
    fullWidth?: boolean
}

const TabsComponent: React.FC<TabsComponentProps> = ({
                                                         tabs,
                                                         selectedTabName,
                                                         onTabClick,
                                                         className = '',
                                                         fullWidth = false,
                                                     }) => {
    const [selectedTab, setSelectedTab] = React.useState<Tab>(
        tabs.find((tab) => tab.name === selectedTabName) || tabs[0]
    )

    useEffect(() => {
        const activeTab = tabs.find((tab) => tab.name === selectedTabName)
        if (activeTab) {
            setSelectedTab(activeTab)
        }
    }, [selectedTabName, tabs])

    const handleTabClick = (tab: Tab) => {
        if (tab.disabled) return
        setSelectedTab(tab)
        onTabClick?.(tab.name)
    }

    return (
        <div className={className}>
            {/* Mobile View */}
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500"
                    value={selectedTab.name}
                    onChange={(e) =>
                        handleTabClick(
                            tabs.find((tab) => tab.name === e.target.value) || tabs[0]
                        )
                    }
                >
                    {tabs.map((tab) => (
                        <option
                            key={tab.name}
                            value={tab.name}
                            disabled={tab.disabled}
                        >
                            {tab.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Desktop View */}
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                        {tabs.map((tab) => {
                            const isSelected = tab === selectedTab;
                            const isDisabled = tab.disabled;

                            return (
                                <button
                                    key={tab.name}
                                    onClick={() => handleTabClick(tab)}
                                    disabled={isDisabled}
                                    className={`
                                        ${fullWidth ? 'flex-1' : 'w-1/4'} 
                                        py-4 px-1 text-center border-b-2 font-medium text-sm
                                        transition-colors duration-200
                                        ${isSelected
                                        ? 'border-primary-500 text-primary-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                    }
                                        ${isDisabled
                                        ? 'opacity-50 cursor-not-allowed hover:text-gray-500 hover:border-transparent'
                                        : 'cursor-pointer'
                                    }
                                    `}
                                    aria-current={isSelected ? 'page' : undefined}
                                    aria-disabled={isDisabled}
                                >
                                    {tab.name}
                                </button>
                            );
                        })}
                    </nav>
                </div>
            </div>

            {/* Tab Content */}
            <div className="mt-6">
                {selectedTab?.content}
            </div>
        </div>
    )
}

export default TabsComponent
