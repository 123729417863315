import {Loader2} from 'lucide-react'
import {ButtonSize, ButtonType, ButtonVariant, LoadingType} from "@/components/ui/Button/ButtonSize";
import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text?: string
    variant?: ButtonVariant
    size?: ButtonSize
    type?: ButtonType
    className?: string
    isLoading?: boolean
    loadingType?: LoadingType
    children?: React.ReactNode
}

const buttonSizeClasses: Record<ButtonSize, string> = {
    [ButtonSize.XS]: 'px-2.5 py-1.5 text-xs',
    [ButtonSize.SM]: 'px-3 py-2 text-sm leading-4',
    [ButtonSize.MD]: 'px-4 py-2 text-sm',
    [ButtonSize.LG]: 'px-4 py-2 text-base',
    [ButtonSize.XL]: 'px-6 py-3 text-base',
}

const buttonVariantClasses: Record<ButtonVariant, string> = {
    [ButtonVariant.PRIMARY]:
        'bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-500 disabled:bg-primary-400',
    [ButtonVariant.SECONDARY]:
        'bg-primary-100 text-primary-700 hover:bg-primary-200 focus:ring-primary-500 disabled:bg-primary-50',
    [ButtonVariant.NEUTRAL]:
        'border border-gray-300 bg-white text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-50',
    [ButtonVariant.OUTLINE]:
        'border border-gray-300 bg-transparent text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-50 disabled:text-gray-400',
    [ButtonVariant.GHOST]:
        'border border-gray-300 bg-transparent text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-50 disabled:text-gray-400',
}

const LoadingSkeleton = () => (
    <div className="flex items-center space-x-2">
        <div className="h-4 w-4 animate-pulse rounded bg-current opacity-40" />
        <div className="h-4 w-16 animate-pulse rounded bg-current opacity-40" />
    </div>
)

const LoadingSpinner = () => (
    <>
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        <span>Loading...</span>
    </>
)

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            text,
            variant = ButtonVariant.PRIMARY,
            size = ButtonSize.MD,
            type = ButtonType.BUTTON,
            isLoading = false,
            loadingType = LoadingType.SPINNER,
            children,
            className = '',
            disabled,
            ...props
        },
        ref
    ) => {
        const sizeClasses = buttonSizeClasses[size]
        const variantClasses = buttonVariantClasses[variant]
        const commonClasses =
            'relative font-medium inline-flex items-center justify-center rounded shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed'

        // Remove shadow for outline variant
        const finalCommonClasses = variant === ButtonVariant.OUTLINE
            ? commonClasses.replace('shadow-sm', '')
            : commonClasses

        const combinedClassName = `${finalCommonClasses} ${sizeClasses} ${variantClasses} ${className}`

        const renderLoading = () => {
            return loadingType === LoadingType.SKELETON ? <LoadingSkeleton /> : <LoadingSpinner />
        }

        return (
            <button
                ref={ref}
                type={type}
                className={combinedClassName}
                disabled={isLoading || disabled}
                {...props}
            >
                {isLoading ? renderLoading() : (children ?? text)}
            </button>
        )
    }
)

Button.displayName = 'Button'

export default Button
