import { client } from '@/lib/httpClient';
import { Department, CreateDepartmentRequest, UpdateDepartmentRequest } from '@/types/department.types';
import { ROUTES } from '@/constants/routes.constants';
import { ActionStatus } from '@/types/api.types';

interface DepartmentResponse extends ActionStatus {
    department_id: string;
}

export const departmentsApi = {
    getAll: async (): Promise<Department[]> => {
        const response = await client.get<Department[]>(ROUTES.DEPARTMENTS.API.BASE);
        return response.data ?? [];
    },

    getDepartmentsByCompany: async (companyId: string): Promise<Department[]> => {
        const response = await client.get<Department[]>(
            `${ROUTES.DEPARTMENTS.API.BASE}?company_id=${companyId}`
        );
        return response.data ?? [];
    },

    getById: async (id: string): Promise<Department> => {
        const response = await client.get<Department>(
            ROUTES.DEPARTMENTS.API.DETAIL(id)
        );
        return response.data;
    },

    create: async (data: CreateDepartmentRequest): Promise<DepartmentResponse> => {
        const response = await client.post<DepartmentResponse>(
            ROUTES.DEPARTMENTS.API.BASE,
            data
        );
        return response.data;
    },

    update: async ({ id, data }: { id: string; data: UpdateDepartmentRequest }): Promise<ActionStatus> => {
        const response = await client.put<ActionStatus>(
            ROUTES.DEPARTMENTS.API.DETAIL(id),
            data
        );
        return response.data;
    },

    delete: async (id: string): Promise<ActionStatus> => {
        const response = await client.delete<ActionStatus>(
            ROUTES.DEPARTMENTS.API.DETAIL(id)
        );
        return response.data;
    }
};
