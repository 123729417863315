import React, { useState } from 'react';
import {
    Plus,
    Pencil,
    Trash2,
    Search,
    Building2,
    Link,
    Unlink,
    FileText,
    User
} from 'lucide-react';
import { type ColumnDef } from '@tanstack/react-table';
import DataTable from '@/components/ui/DataTable';
import Button from '@/components/ui/Button';
import {ButtonSize, ButtonType, ButtonVariant} from '@/components/ui/Button/ButtonSize';
import { Card  } from '@/components/ui/Card';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';
import { TimeAgo } from '@/components/ui/TimeAgo/TimeAgo';
import {RoleTypes, UserRole} from '@/types/role.types';
import Drawer from "@/components/ui/Drawer/Drawer";
import {PriorityEnum, PriorityName} from "@/types/priority.types";
import {StatusEnum, StatusName} from "@/types/status.types";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import StatusBadge from "@/components/ui/Badge/StatusBadge";

interface RelatedTicket {
    id: string;
    number: string;
    title: string;
    status: StatusName;
    priority: PriorityName;
    createdAt: string;
    assignee?: {
        id: string;
        name: string;
        avatar?: string;
        role: UserRole;
    };
}

interface Case {
    id: string;
    title: string;
    description: string;
    status: StatusName;
    priority: PriorityName;
    company: {
        id: string;
        name: string;
    };
    assignedTo?: {
        id: string;
        name: string;
        avatar?: string;
        role: UserRole;
    };
    createdBy: {
        id: string;
        name: string;
        avatar?: string;
        role: UserRole;
    };
    createdAt: string;
    updatedAt: string;
    relatedTickets: RelatedTicket[];
}

interface CaseDrawerContentProps {
    caseItem?: Case;  // Using optional type instead of union with null
    onSubmit?: (data: Partial<Case>) => void;
}

const CasesManagement = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editingCase, setEditingCase] = useState<Case | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState('');

    // Mock data for cases
    const cases: Case[] = [
        {
            id: '1',
            title: 'Πρόβλημα Πρόσβασης στο Σύστημα',
            description: 'Πολλαπλά αιτήματα σχετικά με προβλήματα πρόσβασης μετά την αναβάθμιση',
            status: StatusEnum.PENDING,
            priority: PriorityEnum.HIGH,
            company: {
                id: '1',
                name: 'Cosmote'
            },
            assignedTo: {
                id: '1',
                name: 'Μαρία Παπαδοπούλου',
                role: RoleTypes.AGENT
            },
            createdBy: {
                id: '2',
                name: 'Γιώργος Νικολάου',
                role: RoleTypes.MANAGER
            },
            createdAt: '2024-02-15T10:00:00Z',
            updatedAt: '2024-02-15T11:30:00Z',
            relatedTickets: [
                {
                    id: '1',
                    number: 'TK-001',
                    title: 'Αδυναμία σύνδεσης στο portal',
                    status: StatusEnum.PENDING,
                    priority: PriorityEnum.HIGH,
                    createdAt: '2024-02-15T10:00:00Z',
                    assignee: {
                        id: '1',
                        name: 'Μαρία Παπαδοπούλου',
                        role: RoleTypes.AGENT
                    }
                },
                {
                    id: '2',
                    number: 'TK-002',
                    title: 'Σφάλμα κατά την είσοδο',
                    status: StatusEnum.OPEN,
                    priority: PriorityEnum.HIGH,
                    createdAt: '2024-02-15T10:30:00Z'
                }
            ]
        }
        // Add more cases...
    ];

    const columns: ColumnDef<Case, string>[] = [
        {
            id: 'title',
            header: 'Υπόθεση',
            cell: ({ row }) => (
                <div className="flex flex-col">
                    <div className="font-medium text-gray-900">{row.original.title}</div>
                    <div className="text-sm text-gray-500 flex items-center gap-1">
                        <FileText className="w-4 h-4" />
                        {row.original.relatedTickets.length} σχετικά αιτήματα
                    </div>
                </div>
            ),
        },
        {
            id: 'status',
            header: 'Κατάσταση',
            cell: ({ row }) => (
                <div className="flex flex-col gap-2">
                    <StatusBadge status={row.original.status} />
                    <PriorityBadge priority={row.original.priority} />
                </div>
            ),
        },
        {
            id: 'company',
            header: 'Εταιρεία',
            accessorFn: (row) => row.company.name,
            cell: ({ row }) => (
                <div className="flex items-center gap-2">
                    <Building2 className="w-4 h-4 text-gray-400" />
                    <span>{row.original.company.name}</span>
                </div>
            ),
        },
        {
            id: 'assignment',
            header: 'Ανάθεση',
            cell: ({ row }) => (
                <div className="flex items-center gap-2">
                    {row.original.assignedTo ? (
                        <>
                            {/*<Avatar*/}
                            {/*    src={row.original.assignedTo.avatar}*/}
                            {/*    name={row.original.assignedTo.name}*/}
                            {/*    size="sm"*/}
                            {/*    role={row.original.assignedTo.role}*/}
                            {/*/>*/}
                            <span className="text-sm">{row.original.assignedTo.name}</span>
                        </>
                    ) : (
                        <span className="text-gray-400 italic">Μη ανατεθειμένο</span>
                    )}
                </div>
            ),
        },
        {
            id: 'lastUpdate',
            header: 'Τελ. Ενημέρωση',
            accessorKey: 'updatedAt',
            cell: ({ row }) => (
                <TimeAgo date={row.original.updatedAt} className="text-sm text-gray-500" />
            ),
        },
        {
            id: 'actions',
            header: 'Ενέργειες',
            cell: ({ row }) => (
                <div className="flex gap-2">
                    <Button
                        variant={ButtonVariant.GHOST}
                        size={ButtonSize.SM}
                        onClick={() => handleEdit(row.original)}
                    >
                        <Pencil className="h-4 w-4" />
                    </Button>
                    <Button
                        variant={ButtonVariant.GHOST}
                        size={ButtonSize.SM}
                        className="text-red-600 hover:text-red-700"
                    >
                        <Trash2 className="h-4 w-4" />
                    </Button>
                </div>
            ),
        },
    ];

    const handleEdit = (caseItem: Case) => {
        setEditingCase(caseItem);
        setIsDrawerOpen(true);
    };

    const CaseDrawerContent: React.FC<CaseDrawerContentProps> = ({
                                                                     caseItem
                                                                 }) => {        return (
        <div className="space-y-6">
        <InputWithLabel
                    label="Τίτλος Υπόθεσης"
                    required
                    defaultValue={caseItem?.title}
                    placeholder="Εισάγετε τίτλο υπόθεσης"
                />

                <TextArea
                    label="Περιγραφή"
                    required
                    rows={4}
                    defaultValue={caseItem?.description}
                    placeholder="Περιγράψτε την υπόθεση..."
                />

                <DropdownWithLabel
                    label="Προτεραιότητα"
                    required
                    options={[
                        { value: PriorityEnum.LOW, label: 'Χαμηλή' },
                        { value: PriorityEnum.MEDIUM, label: 'Μεσαία' },
                        { value: PriorityEnum.HIGH, label: 'Υψηλή' },
                        { value: PriorityEnum.URGENT, label: 'Επείγον' }
                    ]}
                    value={caseItem?.priority}
                />

                {/* Related Tickets Section */}
                <div className="border rounded-lg p-4">
                    <div className="flex items-center justify-between mb-4">
                        <h3 className="text-sm font-medium text-gray-900">Σχετικά Αιτήματα</h3>
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                        >
                            <Link className="w-4 h-4 mr-2" />
                            Σύνδεση Αιτήματος
                        </Button>
                    </div>

                    <div className="space-y-3">
                        {caseItem?.relatedTickets.map((ticket) => (
                            <div key={ticket.id} className="flex items-start justify-between p-3 bg-gray-50 rounded-lg">
                                <div className="flex flex-col">
                                    <div className="flex items-center gap-2">
                                        <span className="font-medium">#{ticket.number}</span>
                                        <StatusBadge status={ticket.status} />
                                    </div>
                                    <span className="text-sm text-gray-600">{ticket.title}</span>
                                    {ticket.assignee && (
                                        <div className="flex items-center gap-2 mt-1">
                                            {/*<Avatar*/}
                                            {/*    src={ticket.assignee.avatar}*/}
                                            {/*    name={ticket.assignee.name}*/}
                                            {/*    size="sm"*/}
                                            {/*    role={ticket.assignee.role}*/}
                                            {/*/>*/}
                                            <span className="text-xs text-gray-500">{ticket.assignee.name}</span>
                                        </div>
                                    )}
                                </div>
                                <Button
                                    variant={ButtonVariant.GHOST}
                                    size={ButtonSize.SM}
                                    className="text-gray-400 hover:text-gray-500"
                                >
                                    <Unlink className="w-4 h-4" />
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Assignment Section */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Ανάθεση
                    </label>
                    {caseItem?.assignedTo ? (
                        <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                            <div className="flex items-center gap-3">
                                {/*<Avatar*/}
                                {/*    src={caseItem.assignedTo.avatar}*/}
                                {/*    name={caseItem.assignedTo.name}*/}
                                {/*    role={caseItem.assignedTo.role}*/}
                                {/*/>*/}
                                <div>
                                    <div className="font-medium">{caseItem.assignedTo.name}</div>
                                    <div className="text-sm text-gray-500">Τεχνική Υποστήριξη</div>
                                </div>
                            </div>
                            <Button
                                variant={ButtonVariant.GHOST}
                                size={ButtonSize.SM}
                            >
                                <User className="w-4 h-4 mr-2" />
                                Αλλαγή
                            </Button>
                        </div>
                    ) : (
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            className="w-full"
                        >
                            <User className="w-4 h-4 mr-2" />
                            Ανάθεση Υπόθεσης
                        </Button>
                    )}
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end space-x-4">
                    <Button
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.LG}
                        onClick={() => setIsDrawerOpen(false)}
                    >
                        Ακύρωση
                    </Button>
                    <Button
                        type={ButtonType.SUBMIT}
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LG}
                    >
                        {caseItem ? 'Αποθήκευση' : 'Δημιουργία'}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className="h-full w-full bg-gray-50">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">Υποθέσεις</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Διαχείριση υποθέσεων και ομαδοποίηση αιτημάτων
                    </p>
                </div>

                <Card>
                    {/* Actions Bar */}
                    <div className="p-4 border-b border-gray-200 flex flex-col sm:flex-row justify-between gap-4">
                        <div className="relative">
                            <Search className="absolute left-3 top-1/2 -mt-2 h-4 w-4 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Αναζήτηση υπόθεσης..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 w-full sm:w-96"
                            />
                        </div>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SM}
                            className="flex items-center"
                            onClick={() => setIsDrawerOpen(true)}
                        >
                            <Plus className="h-4 w-4 mr-2" />
                            Νέα Υπόθεση
                        </Button>
                    </div>

                    {/* Table */}
                    <div className="p-4">
                        <DataTable
                            columns={columns}
                            data={cases.filter(caseItem =>
                                caseItem.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                caseItem.description.toLowerCase().includes(searchTerm.toLowerCase())
                            )}
                        />
                    </div>
                </Card>
            </div>

            {/* Drawer */}
            <Drawer
                isOpen={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                    setEditingCase(undefined);  // Reset to undefined when closing
                }}
                title={editingCase ? 'Επεξεργασία Υπόθεσης' : 'Νέα Υπόθεση'}
            >
                <CaseDrawerContent
                    caseItem={editingCase}
                    onSubmit={() => {
                        // Handle form submission
                        setIsDrawerOpen(false);
                        setEditingCase(undefined);
                    }}
                />
            </Drawer>

            {/* Link TicketTypes Modal would go here */}
        </div>
    );
};

export default CasesManagement;
