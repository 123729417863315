import React from 'react'

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string
    error?: string
    className?: string
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            label,
            error,
            className = '',
            disabled = false,
            rows = 4,
            id,
            ...props
        },
        ref
    ) => {
        // Generate a unique ID if none provided
        const textareaId = id || `textarea-${Math.random().toString(36).substr(2, 9)}`

        return (
            <div className="space-y-2">
                {label && (
                    <label
                        htmlFor={textareaId}
                        className="block text-sm font-medium text-gray-700"
                    >
                        {label}
                    </label>
                )}
                <div className="relative">
                    <textarea
                        ref={ref}
                        id={textareaId}
                        rows={rows}
                        disabled={disabled}
                        className={`
                            block w-full rounded-md border border-gray-300 shadow-sm
                            focus:border-primary-500 focus:ring-primary-500 sm:text-sm
                            disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500
                            ${error ? 'border-error-500 focus:border-error-500 focus:ring-error-500' : ''}
                            ${className}
                        `}
                        {...props}
                    />
                </div>
                {error && (
                    <p className="text-sm text-error-500">
                        {error}
                    </p>
                )}
            </div>
        )
    }
)

TextArea.displayName = 'TextArea'

export default TextArea
