// UserStatusBadge.tsx
import {cn} from "@/lib/utils";

export function UserStatusBadge({blocked}: { blocked: boolean }) {
    return (
        <span className={cn(
            'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border',
            blocked ? 'bg-gray-50 text-gray-700 border-gray-200' : 'bg-green-50 text-green-700 border-green-200'
        )}>
            {blocked ? 'Ανενεργός' : 'Ενεργός'}
        </span>
    );
}
