import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Mail, ChevronLeft,
    FileText, Settings, Lock, Building2,
    Phone, MapPin, CheckCircle2, Clock3, ThumbsUp,
} from 'lucide-react';
import { useGetUserQuery } from '@/components/features/users/services/users.queries';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { Avatar } from '@/components/ui/Avatar/Avatar';
import TabsComponent from '@/components/ui/TabsComponent';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card';
import { RoleBadge } from '@/components/ui/Badge/RoleBadge';
import Modal from '@/components/ui/Modal';
import { ROUTES } from '@/constants/routes.constants';
import { UserStatusBadge } from '@/components/ui/Badge/UserStatusBadge';
import useUserStore from "@/stores/useUserStore";
import UserOwnedTickets from "@/components/features/tickets/components/UserOwnedTickets";

// Dummy data
const userStats = {
    currentPeriod: {
        totalTickets: 70,
        resolvedTickets: 43,
        openTickets: 27,
        criticalTickets: 5,
        avgResponseTime: '2.5h',
        satisfaction: 92,
        slaCompliance: 95
    },
    last30Days: {
        totalTickets: 18,
        resolvedTickets: 15,
        avgResponseTime: '1.8h',
        satisfaction: 95,
        trend: {
            direction: 'up',
            percentage: 5
        }
    }
};

const StatCard: React.FC<{
    icon: React.ReactNode;
    value: string | number;
    label: string;
    iconColor: string;
    trend?: { direction: 'up' | 'down'; value: number };
}> = ({ icon, value, label, iconColor, trend }) => (
    <Card>
        <CardContent className="pt-6">
            <div className="text-center">
                <div className={`mx-auto w-8 h-8 ${iconColor}`}>
                    {icon}
                </div>
                <h3 className="mt-3 text-xl font-semibold">{value}</h3>
                <p className="mt-1.5 text-sm text-gray-500">{label}</p>
                {trend && (
                    <div className={`mt-2 text-sm ${trend.direction === 'up' ? 'text-success-600' : 'text-error-600'}`}>
                        {trend.direction === 'up' ? '↑' : '↓'} {trend.value}%
                    </div>
                )}
            </div>
        </CardContent>
    </Card>
);

const UserProfileSkeleton: React.FC = () => (
    <div className="min-h-screen bg-gray-100 animate-pulse">
        <div className="bg-white h-32 border-b border-gray-200"/>
        <div className="max-w-7xl mx-auto px-4 py-6">
            <div className="space-y-6">
                <div className="bg-white h-48 rounded-lg"/>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    {[...Array(4)].map((_, i) => (
                        <div key={i} className="bg-white h-32 rounded-lg"/>
                    ))}
                </div>
                <div className="bg-white h-96 rounded-lg"/>
            </div>
        </div>
    </div>
);

interface UserProfileProps {
    isProfile?: boolean;
}

export const UserProfile: React.FC<UserProfileProps> = ({ isProfile = false }) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const userStore = useUserStore(state => state.user);

    // Use either URL id or current user's id based on isProfile prop
    const userId = isProfile ? userStore.user_id : id;
    const { data: user, isLoading } = useGetUserQuery(userId!);

    const [resetPasswordModalOpen, setResetPasswordModalOpen] = React.useState(false);

    const handleResetPassword = async () => {
        // Add reset password mutation logic
        setResetPasswordModalOpen(false);
    };

    const handleEditUser = () => {
        navigate(`${ROUTES.USERS.DETAILS(id!)}/edit`);
    };

    if (isLoading || !user) {
        return <UserProfileSkeleton />;
    }

    const tabs = [
        {
            name: 'Επισκόπηση',
            content: (
                <div className="space-y-6">
                    <Card>
                        <CardHeader>
                            <CardTitle>Βασικές Πληροφορίες</CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="flex items-center space-x-2">
                                    <Mail className="w-4 h-4 text-gray-400"/>
                                    <span className="text-sm text-gray-600">Email:</span>
                                    <span className="text-sm font-medium">{user.email_address}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Phone className="w-4 h-4 text-gray-400"/>
                                    <span className="text-sm text-gray-600">Τηλέφωνο:</span>
                                    <span className="text-sm font-medium">{user.phone_number || '-'}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Building2 className="w-4 h-4 text-gray-400"/>
                                    <span className="text-sm text-gray-600">Εταιρεία:</span>
                                    <span className="text-sm font-medium">{user.company?.company_name || '-'}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <MapPin className="w-4 h-4 text-gray-400"/>
                                    <span className="text-sm text-gray-600">Τμήμα:</span>
                                    <span
                                        className="text-sm font-medium">{user.department?.department_name || '-'}</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <StatCard
                            icon={<FileText className="w-8 h-8"/>}
                            value={userStats.currentPeriod.totalTickets}
                            label="Σύνολο Αιτημάτων"
                            iconColor="text-primary-500"
                            trend={{direction: 'up', value: 12}}
                        />
                        <StatCard
                            icon={<CheckCircle2 className="w-8 h-8"/>}
                            value={userStats.currentPeriod.resolvedTickets}
                            label="Επιλυμένα"
                            iconColor="text-success-500"
                            trend={{direction: 'up', value: 8}}
                        />
                        <StatCard
                            icon={<Clock3 className="w-8 h-8"/>}
                            value={userStats.currentPeriod.avgResponseTime}
                            label="Μέσος Χρόνος"
                            iconColor="text-purple-500"
                        />
                        <StatCard
                            icon={<ThumbsUp className="w-8 h-8"/>}
                            value={`${userStats.currentPeriod.satisfaction}%`}
                            label="Ικανοποίηση"
                            iconColor="text-yellow-500"
                            trend={{direction: 'up', value: 3}}
                        />
                    </div>
                </div>
            )
        },
        {
            name: 'Αιτήματα',
            content: <UserOwnedTickets userId={userId!}/>
        },
        {
            name: 'Ειδοποιήσεις',
            content: (
                <Card>
                    <CardHeader>
                        <CardTitle>Ειδοποιήσεις</CardTitle>
                    </CardHeader>
                    <CardContent>
                        {/*<div className="space-y-4">*/}
                        {/*    {dummyActivities.map((activity) => (*/}
                        {/*        <ActivityItem*/}
                        {/*            key={activity.id}*/}
                        {/*            activity={activity}*/}
                        {/*            onTicketClick={handleTicketClick}*/}
                        {/*        />*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                    </CardContent>
                </Card>
            )
        },
        // {
        //     name: 'Στατιστικά',
        //     content: (
        //         <Card>
        //             <CardHeader>
        //                 <CardTitle>Στατιστικά Τελευταίων 30 Ημερών</CardTitle>
        //             </CardHeader>
        //             <CardContent>
        //                 <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        //                     <div className="space-y-4">
        //                         <div>
        //                             <h4 className="text-sm font-medium text-gray-500">Αιτήματα</h4>
        //                             <p className="mt-2 text-2xl font-semibold">
        //                                 {userStats.last30Days.resolvedTickets}/{userStats.last30Days.totalTickets}
        //                             </p>
        //                             <p className="text-sm text-gray-500">επιλυμένα αιτήματα</p>
        //                         </div>
        //                         <div>
        //                             <h4 className="text-sm font-medium text-gray-500">Μέσος Χρόνος Απόκρισης</h4>
        //                             <p className="mt-2 text-2xl font-semibold">{userStats.last30Days.avgResponseTime}</p>
        //                             <p className="text-sm text-success-600">
        //                                 20% βελτίωση από τον προηγούμενο μήνα
        //                             </p>
        //                         </div>
        //                     </div>
        //                     <div className="space-y-4">
        //                         <div>
        //                             <h4 className="text-sm font-medium text-gray-500">Ικανοποίηση Πελατών</h4>
        //                             <p className="mt-2 text-2xl font-semibold">{userStats.last30Days.satisfaction}%</p>
        //                             <div className="mt-1 flex items-center space-x-2">
        //                                 <FileBarChart className="w-5 h-5 text-success-500"/>
        //                                 <span className="text-success-600 font-medium">
        //                                     +{userStats.last30Days.trend.percentage}% από τον προηγούμενο μήνα
        //                                 </span>
        //                             </div>
        //                         </div>
        //                         <div>
        //                             <h4 className="text-sm font-medium text-gray-500">SLA Συμμόρφωση</h4>
        //                             <p className="mt-2 text-2xl font-semibold">{userStats.currentPeriod.slaCompliance}%</p>
        //                             <p className="text-sm text-gray-500">εντός χρονικών ορίων</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </CardContent>
        //         </Card>
        //     )
        // }
    ];

    return (
        <div className="min-h-screen bg-gray-100">
            {/* Header */}
            <header className="bg-white border-b border-gray-200 sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <button
                                className="text-gray-500 hover:text-gray-700"
                                onClick={() => navigate(ROUTES.USERS.LIST)}
                            >
                                <ChevronLeft className="w-6 h-6"/>
                            </button>
                            <div>
                                <h1 className="text-2xl font-semibold text-gray-900">
                                    {isProfile ? "Το Προφίλ μου" : "Προφίλ Χρήστη"}
                                </h1>
                                <p className="mt-1 text-sm text-gray-500">
                                    {isProfile
                                        ? "Διαχείριση του προφίλ σας"
                                        : "Προβολή και διαχείριση στοιχείων χρήστη"
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-3">
                            {/* Only show reset password button if not viewing own profile */}
                            {!isProfile && (
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                    onClick={() => setResetPasswordModalOpen(true)}
                                >
                                    <Lock className="w-4 h-4 mr-2"/>
                                    Επαναφορά Κωδικού
                                </Button>
                            )}
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.SM}
                                onClick={handleEditUser}
                            >
                                <Settings className="w-4 h-4 mr-2"/>
                                Επεξεργασία
                            </Button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <main className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1">
                    <div className="space-y-6">
                        {/* Profile Header */}
                        <Card>
                            <CardContent className="p-6">
                                <div className="flex items-center space-x-4">
                                    <Avatar
                                        user={user}
                                        size="xl"
                                    />
                                    <div>
                                        <h2 className="text-2xl font-bold text-gray-900">
                                            {user.first_name} {user.last_name}
                                        </h2>
                                        <div className="mt-1 flex items-center space-x-2">
                                            <RoleBadge role={user.role} />
                                            <UserStatusBadge blocked={user.blocked} />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        {/* Tabs Section */}
                        <TabsComponent
                            tabs={tabs}
                            fullWidth
                            className="bg-white rounded-lg shadow"
                        />
                    </div>
                </div>
            </main>

            {/* Reset Password Modal */}
            <Modal
                open={resetPasswordModalOpen}
                setOpen={setResetPasswordModalOpen}
                title="Επαναφορά Κωδικού"
                type="default"
                icon={<Lock className="h-6 w-6 text-primary-600" />}
                primaryAction={{
                    text: 'Επαναφορά',
                    onClick: handleResetPassword
                }}
                secondaryAction={{
                    text: 'Ακύρωση',
                    onClick: () => setResetPasswordModalOpen(false)
                }}
            >
                <p className="text-sm text-gray-500">
                    Είστε βέβαιοι ότι θέλετε να επαναφέρετε τον κωδικό του χρήστη{' '}
                    <span className="font-medium text-gray-900">
                        {user.first_name} {user.last_name}
                    </span>
                    ; Ένας νέος κωδικός θα δημιουργηθεί και θα αποσταλεί στο email του χρήστη.
                </p>
            </Modal>
        </div>
    );
};

export default UserProfile;
