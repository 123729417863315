import React, { useState } from 'react';
import { Pencil, BarChart } from 'lucide-react';
import { useGetCompanySlaQuery } from '@/components/features/companies/services/companies.queries';
import { SLA } from '@/types/sla.types';
import { PriorityName, getPriorityIcon } from '@/types/priority.types';
import { getPriorityColorClasses } from '@/constants/colors.constants';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import Button from "@/components/ui/Button";
import { ButtonSize, ButtonVariant } from "@/components/ui/Button/ButtonSize";
import SLADrawer from "@/components/ui/Drawer/SLADrawer";
import {PermissionGuard} from "@/components/guards/PermissionGuard";
import {useUpdateSlaMutation} from "@/components/features/sla/services/sla.mutations";

const CompanySLATab = ({ companyId }: { companyId: string }) => {
    const { data: slas = [], isLoading } = useGetCompanySlaQuery(companyId);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editingSla, setEditingSla] = useState<SLA | null>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const updateSlaMutation = useUpdateSlaMutation(companyId);

    const handleEdit = (sla: SLA) => {
        setEditingSla(sla);
        setIsDrawerOpen(true);
    };

    const handleSubmit = async (data: Partial<SLA>) => {
        if (editingSla) {
            try {
                await updateSlaMutation.mutateAsync({
                    slaId: editingSla.sla_id,
                    data: {
                        sla_name: data.sla_name!,
                        description: data.description!,
                        response_time: data.response_time!,
                        resolution_time: data.resolution_time!
                    }
                });
                setIsDrawerOpen(false);
                setEditingSla(null);
            } catch (error) {
                console.error('Failed to update SLA:', error);
            }
        }
    };

    const formatTime = (hours: number) => {
        if (hours < 1) return `${hours * 60} λεπτά`;
        if (hours === 1) return '1 ώρα';
        return `${hours} ώρες`;
    };

    return (
        <>
            <Card>
                <CardHeader>
                    <div className="flex items-center justify-between">
                        <CardTitle className="flex items-center">
                            <BarChart className="w-5 h-5 mr-2" />
                            Συμφωνίες Επιπέδου Υπηρεσιών (SLA)
                        </CardTitle>
                        <PermissionGuard requiredPermissions={['update:sla']}>
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            onClick={() => setIsEditMode(!isEditMode)}
                            className={isEditMode ? 'bg-gray-100' : ''}
                        >
                            <Pencil className="h-4 w-4 mr-2" />
                            {isEditMode ? 'Ολοκλήρωση' : 'Επεξεργασία'}
                        </Button>
                        </PermissionGuard>
                    </div>
                </CardHeader>
                <CardContent>
                    {isLoading ? (
                        <div className="text-center py-4 text-gray-500">Φόρτωση...</div>
                    ) : slas.length === 0 ? (
                        <div className="text-center py-4 text-gray-500">
                            Δεν υπάρχουν καταχωρημένα SLA
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                            {slas.map((sla) => {
                                const priority = sla.priority.priority_name as PriorityName;
                                const Icon = getPriorityIcon(priority);
                                const colors = getPriorityColorClasses(priority);

                                return (
                                    <Card key={sla.sla_id} className="border-0 shadow-sm">
                                        <CardHeader className={`${colors.bg} rounded-t-lg p-4`}>
                                            <div className="space-y-3">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center gap-3">
                                                        <div className={`p-2 ${colors.dark} rounded-lg`}>
                                                            <Icon className="w-5 h-5 text-white"/>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <CardTitle className={`text-base ${colors.text}`}>
                                                                {sla.sla_name}
                                                            </CardTitle>
                                                        </div>
                                                    </div>
                                                    {isEditMode && (
                                                        <Button
                                                            variant={ButtonVariant.GHOST}
                                                            size={ButtonSize.SM}
                                                            onClick={() => handleEdit(sla)}
                                                            className={`${colors.text} hover:${colors.bg}`}
                                                        >
                                                            <Pencil className="h-4 w-4"/>
                                                        </Button>
                                                    )}
                                                </div>

                                                <div className="grid grid-cols-1 gap-x-6 gap-y-2">
                                                    <div>
                                                        <p className="text-md font-medium text-gray-600">{sla.description}</p>
                                                    </div>

                                                </div>

                                                <div className="grid grid-cols-2 gap-x-6 gap-y-2">
                                                    <div>
                                                        <div className="text-sm font-medium text-gray-500">Απόκριση
                                                        </div>
                                                        <div className={`text-sm font-bold ${colors.text}`}>
                                                            {formatTime(sla.response_time)}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="text-sm font-medium text-gray-500">Επίλυση</div>
                                                        <div className={`text-sm font-bold ${colors.text}`}>
                                                            {formatTime(sla.resolution_time)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                );
                            })}
                        </div>
                    )}
                </CardContent>
            </Card>

            <SLADrawer
                isOpen={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                    setEditingSla(null);
                }}
                editingSla={editingSla}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default CompanySLATab;
