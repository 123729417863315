// src/features/tickets/services/tickets.queries.ts
import { useQuery } from '@tanstack/react-query';
import { ticketsApi } from './tickets.api';
import { TICKET_KEYS } from './tickets.queryKeys';
import type { TicketFilters } from '@/types/ticket.types';

export function useGetTicketsQuery(filters?: TicketFilters) {
    return useQuery({
        queryKey: TICKET_KEYS.list(filters),
        queryFn: () => ticketsApi.getAll(filters),
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: true
    });
}

export function useGetTicketQuery(id: string) {
    return useQuery({
        queryKey: TICKET_KEYS.detail(id),
        queryFn: () => ticketsApi.getById(id),
        enabled: Boolean(id),
        staleTime: 1000 * 60 * 5 // 5 minutes
    });
}

export function useGetTicketAuditsQuery(ticketId: string) {
    const statusAudits = useQuery({
        queryKey: [...TICKET_KEYS.detail(ticketId), 'status-audits'],
        queryFn: () => ticketsApi.getStatusAudits(ticketId),
        enabled: Boolean(ticketId),
    });

    const assignmentAudits = useQuery({
        queryKey: [...TICKET_KEYS.detail(ticketId), 'assignment-audits'],
        queryFn: () => ticketsApi.getAssignmentAudits(ticketId),
        enabled: Boolean(ticketId),
    });

    return {
        statusAudits,
        assignmentAudits,
        isLoading: statusAudits.isLoading || assignmentAudits.isLoading,
        isError: statusAudits.isError || assignmentAudits.isError,
    };
}
