import { useQuery } from '@tanstack/react-query';
import { notesApi } from './notes.api';
import { NOTE_KEYS } from './notes.queryKeys';

export function useTicketNotesQuery(ticketId: string) {
    return useQuery({
        queryKey: NOTE_KEYS.list(ticketId),
        queryFn: () => notesApi.getAll(ticketId),
        enabled: Boolean(ticketId),
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: true
    });
}
