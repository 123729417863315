import { useQuery } from '@tanstack/react-query';
import { Department } from '@/types/department.types';
import { DEPARTMENT_KEYS } from './departments.queryKeys';
import { departmentsApi } from './departments.api';

export const useGetDepartmentsByCompanyQuery = (companyId?: string) => {
    return useQuery<Department[]>({
        queryKey: companyId ? DEPARTMENT_KEYS.byCompany(companyId) : DEPARTMENT_KEYS.list,
        queryFn: () => companyId ? departmentsApi.getDepartmentsByCompany(companyId) : departmentsApi.getAll(),
        enabled: !companyId || !!companyId
    });
};
