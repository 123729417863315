import React from 'react';
import {
    AlertTriangle,
    Building2,
    CheckCircle2,
    Clock,
    Filter,
    Search,
    TrendingUp,
    Users, AlertCircle
} from 'lucide-react';
import Button from "@/components/ui/Button";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/Card";
import { ButtonSize, ButtonVariant } from "@/components/ui/Button/ButtonSize";

export default function ManagerDashboard() {

    const stats = {
        activeAgents: { count: 12, trend: 2, trendUp: true },
        departmentTickets: { count: 125, trend: 8, trendUp: true },
        slaCompliance: { value: "95%", trend: 2, trendUp: true },
        customerSatisfaction: { value: "4.8/5", trend: 0.2, trendUp: true },
        resolutionTime: { value: "4.2 ώρες", trend: -15, trendUp: false },
        ticketsPerAgent: { value: "15", trend: -2, trendUp: false },
        firstResponseTime: { value: "12 λεπτά", trend: -5, trendUp: false },
        urgentUnassigned: { count: 3, trend: -1, trendUp: false },
    };

    const teamData = [
        {
            name: "Μαρία Κ.",
            activeTickets: 8,
            avgResTime: "3.5 ώρες",
            completedToday: 12,
            status: "online",
            slaCompliance: "98%",
            satisfaction: "4.9/5"
        },
        {
            name: "Γιώργος Ν.",
            activeTickets: 6,
            avgResTime: "4.1 ώρες",
            completedToday: 15,
            status: "online",
            slaCompliance: "96%",
            satisfaction: "4.7/5"
        }
    ];

    const criticalTickets = [
        {
            id: "TK-001",
            title: "Διακοπή υπηρεσίας σε παραγωγικό περιβάλλον",
            priority: "URGENT",
            company: "Cosmote",
            assignedTo: "Μαρία Κ.",
            slaRemaining: "1 ώρα",
            status: "PENDING"
        }
    ];

    const workloadDistribution = [
        { category: "Τεχνικά Προβλήματα", count: 45, percentage: "36%" },
        { category: "Αιτήματα Χρηστών", count: 35, percentage: "28%" }
    ];

    return (
        <div className="h-full w-full bg-gray-100">
            <div className="mx-auto p-4 sm:p-6 lg:p-8 max-w-7xl">
                {/* Header */}
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Διαχείριση Τμήματος</h1>
                        <p className="mt-1 text-sm text-gray-500">
                            Επισκόπηση απόδοσης και διαχείριση ομάδας
                        </p>
                    </div>
                </div>

                {/* Primary Stats */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {[
                        {
                            title: "Ενεργοί Agents",
                            count: stats.activeAgents.count,
                            trend: stats.activeAgents.trend,
                            trendUp: stats.activeAgents.trendUp,
                            icon: <Users className="h-6 w-6 text-blue-700" />,
                            color: "blue",
                        },
                        {
                            title: "Αιτήματα Τμήματος",
                            count: stats.departmentTickets.count,
                            trend: stats.departmentTickets.trend,
                            trendUp: stats.departmentTickets.trendUp,
                            icon: <Building2 className="h-6 w-6 text-purple-700" />,
                            color: "purple",
                        },
                        {
                            title: "SLA Συμμόρφωση",
                            value: stats.slaCompliance.value,
                            trend: stats.slaCompliance.trend,
                            trendUp: stats.slaCompliance.trendUp,
                            icon: <CheckCircle2 className="h-6 w-6 text-green-700" />,
                            color: "green",
                        },
                        {
                            title: "Ικανοποίηση Πελατών",
                            value: stats.customerSatisfaction.value,
                            trend: stats.customerSatisfaction.trend,
                            trendUp: stats.customerSatisfaction.trendUp,
                            icon: <TrendingUp className="h-6 w-6 text-yellow-700" />,
                            color: "yellow",
                        },
                    ].map((stat, index) => (
                        <Card key={index} className={`bg-${stat.color}-50 border-${stat.color}-200`}>
                            <CardContent className="p-4">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <p className={`text-sm font-medium text-${stat.color}-700`}>{stat.title}</p>
                                        <div className="flex items-baseline">
                                            <p className={`text-2xl font-semibold text-${stat.color}-900`}>
                                                {stat.count || stat.value}
                                            </p>
                                            <span className={`ml-2 text-sm text-${stat.color}-700`}>
                                                {stat.trend > 0 ? '+' : ''}
                                                {stat.trend}
                                                {stat.value && '%'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={`p-3 bg-${stat.color}-100 rounded-lg`}>{stat.icon}</div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>


                {/* Main Content Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Team Performance Overview */}
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-lg font-medium flex justify-between items-center">
                                <span>Απόδοση Ομάδας</span>
                                <span className="text-sm text-green-600">{stats.activeAgents.count} ενεργοί agents</span>
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            {teamData.map((member) => (
                                <div key={member.name} className="bg-gray-50 rounded-lg p-4">
                                    <div className="flex items-center justify-between mb-3">
                                        <div className="flex items-center">
                                            <div className="mr-3">
                                                <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                                                    <Users className="h-5 w-5 text-gray-600" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex items-center">
                                                    <h3 className="font-medium text-gray-900">{member.name}</h3>
                                                    <span className={`ml-2 h-2 w-2 rounded-full ${
                                                        member.status === 'online' ? 'bg-green-400' : 'bg-gray-400'
                                                    }`} />
                                                </div>
                                                <p className="text-sm text-gray-500">{member.activeTickets} ενεργά αιτήματα</p>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <div className="text-sm font-medium text-green-600">{member.slaCompliance} SLA</div>
                                            <div className="text-sm text-gray-500">{member.satisfaction} ικανοποίηση</div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="bg-white rounded p-2 text-center">
                                            <div className="text-xs text-gray-500">Μ.Ο. Επίλυσης</div>
                                            <div className="text-sm font-medium">{member.avgResTime}</div>
                                        </div>
                                        <div className="bg-white rounded p-2 text-center">
                                            <div className="text-xs text-gray-500">Σήμερα</div>
                                            <div className="text-sm font-medium">{member.completedToday} επιλύθηκαν</div>
                                        </div>
                                        <div className="bg-white rounded p-2 text-center">
                                            <div className="text-xs text-gray-500">Ενεργά/Στόχος</div>
                                            <div className="text-sm font-medium">{member.activeTickets}/15</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </CardContent>
                    </Card>

                    {/* Critical Issues & SLA Monitoring */}
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-lg font-medium flex justify-between items-center">
                                <span>Επείγοντα Αιτήματα</span>
                                <span className="text-sm text-red-600">{criticalTickets.length} κρίσιμα</span>
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            {criticalTickets.map(ticket => (
                                <div key={ticket.id} className="bg-gray-50 rounded-lg p-4">
                                    <div className="flex items-center justify-between mb-2">
                                        <div className="flex items-center space-x-2">
                                            <AlertTriangle className="h-5 w-5 text-red-500" />
                                            <span className="font-medium">#{ticket.id}</span>
                                            <span className="px-2 py-1 text-xs font-medium bg-red-100 text-red-800 rounded-full">
                                                {ticket.priority}
                                            </span>
                                        </div>
                                        <div className="flex items-center text-red-600 text-sm">
                                            <Clock className="h-4 w-4 mr-1" />
                                            <span>SLA: {ticket.slaRemaining}</span>
                                        </div>
                                    </div>
                                    <p className="text-sm text-gray-900 mb-2">{ticket.title}</p>
                                    <div className="flex items-center justify-between text-sm">
                                        <div className="flex items-center text-gray-500">
                                            <Building2 className="h-4 w-4 mr-1" />
                                            <span>{ticket.company}</span>
                                            <span className="mx-2">•</span>
                                            <span>{ticket.assignedTo}</span>
                                        </div>
                                        <Button variant={ButtonVariant.OUTLINE} size={ButtonSize.SM}>
                                            Διαχείριση
                                        </Button>
                                    </div>
                                </div>
                            ))}

                            <div className="mt-4 space-y-2">
                                <div className="bg-green-50 border border-green-200 rounded-lg p-3 flex justify-between items-center">
                                    <div className="flex items-center">
                                        <CheckCircle2 className="h-5 w-5 text-green-600 mr-2" />
                                        <span className="text-sm font-medium text-green-600">Εντός SLA</span>
                                    </div>
                                    <span className="text-sm text-green-600">95% αιτημάτων</span>
                                </div>
                                <div className="bg-red-50 border border-red-200 rounded-lg p-3 flex justify-between items-center">
                                    <div className="flex items-center">
                                        <AlertCircle className="h-5 w-5 text-red-600 mr-2" />
                                        <span className="text-sm font-medium text-red-600">Σε Κίνδυνο</span>
                                    </div>
                                    <span className="text-sm text-red-600">2 αιτήματα</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Workload Distribution */}
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-lg font-medium">Κατανομή Φόρτου Εργασίας</CardTitle>
                        </CardHeader>
                        <CardContent>
                            {workloadDistribution.map((category) => (
                                <div key={category.category} className="mb-4">
                                    <div className="flex justify-between items-center mb-2">
                                        <div>
                                            <div className="text-sm font-medium">{category.category}</div>
                                            <div className="text-sm text-gray-500">{category.count} αιτήματα</div>
                                        </div>
                                        <div className="text-sm font-medium">{category.percentage}</div>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2">
                                        <div
                                            className="bg-blue-600 h-2 rounded-full"
                                            style={{width: category.percentage}}
                                        />
                                    </div>
                                </div>
                            ))}
                        </CardContent>
                    </Card>

                    {/* SLA Performance Graph or Additional Analytics */}
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-lg font-medium">Τάσεις Απόδοσης</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="bg-gray-50 rounded-lg p-4">
                                    <div className="text-sm font-medium mb-1">Μέσος Χρόνος Επίλυσης</div>
                                    <div className="text-2xl font-bold text-blue-600">4.2 ώρες</div>
                                    <div className="text-sm text-green-600">-15% από χθες</div>
                                </div>
                                <div className="bg-gray-50 rounded-lg p-4">
                                    <div className="text-sm font-medium mb-1">Ικανοποίηση Πελατών</div>
                                    <div className="text-2xl font-bold text-blue-600">94%</div>
                                    <div className="text-sm text-green-600">+2% από χθες</div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}
