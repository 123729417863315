import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import type {
    CompanyFormFields,
    CompanyEntryResponse, Company, AssignProductResponse, AssignProductsPayload
} from "@/types/company.types";
import {COMPANY_KEYS} from "@/components/features/companies/services/companies.queryKeys";
import {companiesApi} from "@/components/features/companies/services/companies.api";
import {ActionStatus} from "@/types/api.types";

export const useCreateCompanyMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<CompanyEntryResponse, Error, CompanyFormFields>({
        mutationFn: companiesApi.create,
        onSuccess: async (response) => {
            await queryClient.invalidateQueries({queryKey: COMPANY_KEYS.list});
            toast.success('Η εταιρία δημιουργήθηκε επιτυχώς');
            return response;
        },
        onError: (error) => {
            console.error('Create company error:', error);
            toast.error('Σφάλμα κατά τη δημιουργία της εταιρίας');
        }
    });
};

export const useUpdateCompanyMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<
        ActionStatus,
        Error,
        { id: string; company: CompanyFormFields }
    >({
        mutationFn: companiesApi.update,
        onSuccess: async (response, variables) => {
            await Promise.all([
                queryClient.invalidateQueries({queryKey: COMPANY_KEYS.list}),
                queryClient.invalidateQueries({queryKey: COMPANY_KEYS.detail(variables.id)})
            ]);
            toast.success('Η εταιρία ενημερώθηκε επιτυχώς');
            return response;
        },
        onError: (error) => {
            console.error('Update company error:', error);
            toast.error('Σφάλμα κατά την ενημέρωση της εταιρίας');
        }
    });
};

export const useDeleteCompanyMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<ActionStatus, Error, string>({
        mutationFn: companiesApi.delete,
        onSuccess: async (response) => {
            await queryClient.invalidateQueries({ queryKey: COMPANY_KEYS.list });
            toast.success('Η εταιρία διαγράφηκε επιτυχώς');
            return response;
        },
        onError: (error) => {
            console.error('Delete company error:', error);
            toast.error('Σφάλμα κατά τη διαγραφή της εταιρίας');
        },
        onMutate: async (company_id) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({
                queryKey: COMPANY_KEYS.list
            });

            // Snapshot the previous value
            const previousCompanies = queryClient.getQueryData<Company[]>(COMPANY_KEYS.list);

            // Optimistically remove the company from the list
            queryClient.setQueryData<Company[]>(
                COMPANY_KEYS.list,
                (old) => old?.filter(company => company.company_id !== company_id) ?? []
            );

            // Return a context object with the snapshot value
            return { previousCompanies };
        },
        onSettled: async () => {
            // Always refetch after error or success
            await queryClient.invalidateQueries({
                queryKey: COMPANY_KEYS.list
            });
        }
    });
};

export const useAssignProductsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<AssignProductResponse[], Error, AssignProductsPayload>({
        mutationFn: async ({ companyId, productIds, price }) => {
            // Handle multiple product assignments sequentially to prevent race conditions
            const results = [];
            for (const productId of productIds) {
                const result = await companiesApi.assignProduct(companyId, {
                    product_id: productId,
                    price
                });
                results.push(result);
            }
            return results;
        },
        onSuccess: async (response, variables) => {
            // Check if all assignments were successful
            const allSuccessful = response.every(r => r.action_status);

            if (allSuccessful) {
                // Invalidate relevant queries
                await queryClient.refetchQueries({
                    queryKey: COMPANY_KEYS.detail(variables.companyId)
                });
                await queryClient.refetchQueries({
                    queryKey: COMPANY_KEYS.products(variables.companyId)
                });

                toast.success('Τα προϊόντα ανατέθηκαν επιτυχώς');
            } else {
                // If any failed, show error with details
                const failedCount = response.filter(r => !r.action_status).length;
                toast.error(`Σφάλμα κατά την ανάθεση ${failedCount} προϊόντων`);
            }
        },
        onError: (error) => {
            console.error('Assign products error:', error);
            toast.error('Σφάλμα κατά την ανάθεση των προϊόντων');
        }
    });
};
