// src/components/ui/Button/ButtonSize.tsx
export enum ButtonSize {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl'
}

export enum ButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    NEUTRAL = 'neutral',
    OUTLINE = 'outline',
    GHOST = 'ghost'  // Added GHOST variant
}

export enum ButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset'
}

export enum LoadingType {
    SPINNER = 'spinner',
    SKELETON = 'skeleton'
}
