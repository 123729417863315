import { client } from '@/lib/httpClient';
import type {
    Case,
    CaseResponse,
    CreateCaseRequest,
    CaseFilters,
    CasesListResponse
} from "@/types/case.types";
import { createCaseSchema } from '@/schemas/case.schemas';
import {ROUTES} from "@/constants/routes.constants";

export const casesApi = {
    getAll: async (filters?: CaseFilters) => {
        const response = await client.get<CasesListResponse>(
            ROUTES.CASES.API.BASE,
            { params: filters }
        );
        return response.data;
    },

    getById: async (id: string) => {
        const response = await client.get<Case>(
            ROUTES.CASES.API.DETAIL(id)
        );
        return response.data;
    },

    create: async (data: CreateCaseRequest) => {
        const validated = createCaseSchema.parse(data);
        const response = await client.post<CaseResponse>(
            ROUTES.CASES.API.BASE,
            validated
        );
        return response.data;
    },

    delete: async (id: string) => {
        const response = await client.delete<CaseResponse>(
            ROUTES.CASES.API.DETAIL(id)
        );
        return response.data;
    },

    updateStatus: async (id: string, statusId: string) => {
        const response = await client.put<CaseResponse>(
            ROUTES.CASES.API.STATUS(id, statusId)
        );
        return response.data;
    },

    updateAssignee: async (id: string, userId: string) => {
        const response = await client.put<CaseResponse>(
            ROUTES.CASES.API.ASSIGN(id, userId)
        );
        return response.data;
    },

    linkTickets: async (id: string, data: { ticket_ids: string[] }) => {
        const response = await client.post<CaseResponse>(
            ROUTES.CASES.API.LINK_TICKETS(id),
            data
        );
        return response.data;
    },

    unlinkTickets: async (id: string, data: { ticket_ids: string[] }) => {
        const response = await client.delete<CaseResponse>(
            ROUTES.CASES.API.UNLINK_TICKETS(id),
            { data }
        );
        return response.data;
    }
};
