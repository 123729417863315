// src/pages/EditCompany.tsx
import React, {useState} from 'react';
import CompanyForm from "@/components/features/companies/components/CompanyForm";
import {
    useDeleteCompanyMutation,
    useUpdateCompanyMutation
} from "@/components/features/companies/services/companies.mutations";
import {useGetCompanyQuery} from "@/components/features/companies/services/companies.queries";
import {useNavigate, useParams} from "react-router-dom";
import {PageTitle} from "@/components/layouts/PageLayout/PageTitle";
import {CompanyFormFields} from "@/types/company.types";
import {ROUTES} from "@/constants/routes.constants";
import {Trash2} from "lucide-react";
import Button from "@/components/ui/Button";
import {ButtonSize, ButtonVariant} from "@/components/ui/Button/ButtonSize";
import Modal from "@/components/ui/Modal";
import {PermissionGuard} from "@/components/guards/PermissionGuard";

type CreateCompanyParams = {
    id: string;
}

const baseUrl : string = ROUTES.COMPANIES.LIST;

const EditCompany = () => {
    const { id = "" } = useParams<CreateCompanyParams>();
    const { data: company, isLoading: isQueryLoading } = useGetCompanyQuery(id);
    const { mutateAsync: updateCompany } = useUpdateCompanyMutation();
    const { mutateAsync: deleteCompany } = useDeleteCompanyMutation();
    const navigate = useNavigate();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleSubmit = async (data: CompanyFormFields) => {
        try {
            const response = await updateCompany({ id, company: data });
            if (response.action_status) {
                navigate(`${baseUrl}/${id}`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await deleteCompany(id);
            if (response.action_status) {
                navigate(baseUrl);
            }
        } catch (error) {
            console.log(error);
        }
    };

    if (isQueryLoading) return <div>Loading...</div>;

    return (
        <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
            <div className="pt-8 flex items-center justify-between">
                <PageTitle
                    title={company?.company_name}
                    description="Επεξεργασία Εταιρίας"
                />
                <PermissionGuard requiredPermissions={['manage:companies']}>
                <Button
                    variant={ButtonVariant.OUTLINE}
                    size={ButtonSize.SM}
                    onClick={() => setDeleteModalOpen(true)}
                    className="text-red-600 hover:text-red-700 border-red-200 hover:border-red-300"
                >
                    <Trash2 className="w-4 h-4 mr-2"/>
                    Διαγραφή
                </Button>
                </PermissionGuard>
            </div>
            <div className="space-y-6 pb-8">
                <CompanyForm
                    isEdit={true}
                    initialData={company}
                    onSubmit={handleSubmit}
                />
            </div>

            <Modal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                title="Διαγραφή Εταιρείας"
                type="delete"
                icon={<Trash2 className="h-6 w-6 text-error-600" />}
                primaryAction={{
                    text: "Διαγραφή",
                    onClick: handleDelete
                }}
                secondaryAction={{
                    text: "Ακύρωση",
                    onClick: () => setDeleteModalOpen(false)
                }}
            >
                <p className="text-sm text-gray-500">
                    Είστε σίγουροι ότι θέλετε να διαγράψετε την εταιρεία{' '}
                    <span className="font-medium text-gray-700">
                        {company?.company_name}
                    </span>
                    ; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.
                </p>
            </Modal>
        </div>
    );
};

export default EditCompany;
