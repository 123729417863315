import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notesApi } from "./notes.api";
import { NOTE_KEYS } from "./notes.queryKeys";
import type { CreateNoteRequest } from "@/schemas/note.schemas";

export function useCreateNoteMutation(ticketId: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: CreateNoteRequest) =>
            notesApi.create(ticketId, data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: NOTE_KEYS.list(ticketId)
            });
            toast.success('Η σημείωση προστέθηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Create note error:', error);
            toast.error('Σφάλμα κατά την προσθήκη της σημείωσης');
        }
    });
}
