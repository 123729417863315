import React, { useState } from 'react';
import { Plus, Search } from 'lucide-react';
import { type ColumnDef } from '@tanstack/react-table';

import type { Category, CategoryFormFields } from '@/types/category.types';
import DataTable from '@/components/ui/DataTable';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonType, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { Card } from '@/components/ui/Card';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import Drawer from "@/components/ui/Drawer/Drawer";
import { RowActions } from "@/components/ui/DataTable/RowActions";
import {useGetCategoriesQuery} from "@/components/features/categories/services/categories.queries";
import {
    useCreateCategoryMutation, useDeleteCategoryMutation,
    useUpdateCategoryMutation
} from "@/components/features/categories/services/categories.mutations";
import {toast} from "react-toastify";

const TicketCategories = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState<Category | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [formData, setFormData] = useState<CategoryFormFields>({
        category_name: '',
        description: ''
    });

    // Queries and Mutations
    const { data: categories = [], isLoading } = useGetCategoriesQuery();
    const createCategory = useCreateCategoryMutation();
    const updateCategory = useUpdateCategoryMutation();
    const deleteCategory = useDeleteCategoryMutation();

    const columns: ColumnDef<Category, string>[] = [
        {
            id: 'name',
            header: 'Κατηγορία',
            accessorKey: 'category_name',
        },
        {
            id: 'description',
            header: 'Περιγραφή',
            accessorKey: 'description',
            cell: ({ row }) => (
                <div className="max-w-md truncate">
                    {row.original.description}
                </div>
            ),
        },
        {
            id: 'actions',
            header: 'Ενέργειες',
            cell: ({ row }) => (
                <RowActions
                    actions={[
                        {
                            type: 'edit',
                            onClick: () => handleEdit(row.original),
                            show: true,
                        },
                        {
                            type: 'delete',
                            onClick: () => handleDelete(row.original.category_id),
                            show: true, // Add your condition here
                        },
                    ]}
                />
            ),
        },
    ];

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
        setEditingCategory(null);
        setFormData({
            category_name: '',
            description: ''
        });
    };

    const handleEdit = (category: Category) => {
        setEditingCategory(category);
        setFormData({
            category_name: category.category_name,
            description: category.description
        });
        setIsDrawerOpen(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDelete = async (id: string) => {
        try {
            await deleteCategory.mutateAsync(id);
            toast.success('Η κατηγορία διαγράφηκε επιτυχώς');
        } catch (error) {
            toast.error('Σφάλμα κατά τη διαγραφή της κατηγορίας');
        }
    };

    const handleAddNew = () => {
        setEditingCategory(null);
        setIsDrawerOpen(true);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            if (editingCategory) {
                await updateCategory.mutateAsync({
                    id: editingCategory.category_id,
                    category: formData
                });
                toast.success('Η κατηγορία ενημερώθηκε επιτυχώς');
            } else {
                await createCategory.mutateAsync(formData);
                toast.success('Η κατηγορία δημιουργήθηκε επιτυχώς');
            }
            handleDrawerClose();
        } catch (error) {
            toast.error('Σφάλμα κατά την αποθήκευση της κατηγορίας');
        }
    };

    const filteredCategories = categories.filter(cat =>
        cat.category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cat.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="h-full w-full bg-gray-50">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">Κατηγορίες</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Διαχείριση κατηγοριών για αιτήματα και υποθέσεις
                    </p>
                </div>

                <Card>
                    {/* Actions Bar */}
                    <div className="p-4 border-b border-gray-200 flex flex-col sm:flex-row justify-between gap-4">
                        <div className="relative">
                            <Search className="absolute left-3 top-1/2 -mt-2 h-4 w-4 text-gray-400"/>
                            <input
                                type="text"
                                placeholder="Αναζήτηση κατηγορίας..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 w-full sm:w-96"
                            />
                        </div>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SM}
                            className="flex items-center"
                            onClick={handleAddNew}
                        >
                            <Plus className="h-4 w-4 mr-2"/>
                            Νέα Κατηγορία
                        </Button>
                    </div>

                    {/* Table */}
                    <div className="p-4">
                        <DataTable
                            columns={columns}
                            data={filteredCategories}
                            isLoading={isLoading}
                        />
                    </div>
                </Card>
            </div>

            {/* Drawer */}
            <Drawer
                isOpen={isDrawerOpen}
                onClose={handleDrawerClose}
                title={editingCategory ? 'Επεξεργασία Κατηγορίας' : 'Νέα Κατηγορία'}
            >
                <form onSubmit={handleSubmit} className="space-y-6">
                    <InputWithLabel
                        name="category_name"
                        label="Όνομα Κατηγορίας"
                        required
                        value={formData.category_name}
                        onChange={handleInputChange}
                        placeholder="π.χ. Τεχνικό Πρόβλημα"
                    />

                    <TextArea
                        name="description"
                        label="Περιγραφή"
                        required
                        rows={4}
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="Περιγράψτε τη χρήση της κατηγορίας..."
                    />

                    <div className="flex justify-end space-x-4">
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.LG}
                            onClick={handleDrawerClose}
                        >
                            Ακύρωση
                        </Button>
                        <Button
                            type={ButtonType.SUBMIT}
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LG}
                            isLoading={createCategory.isPending || updateCategory.isPending}
                        >
                            {editingCategory ? 'Αποθήκευση' : 'Δημιουργία'}
                        </Button>
                    </div>
                </form>
            </Drawer>
        </div>
    );
};

export default TicketCategories;
