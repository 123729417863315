// components/layouts/PageHeader.tsx
import {ChevronLeft} from "lucide-react";
import {useNavigate} from "react-router-dom";

interface PageHeaderProps {
    title: string;
    description?: string | React.ReactNode;
    backButton?: boolean;
    actions?: React.ReactNode;
}

export function PageHeader({ title, description, backButton, actions }: PageHeaderProps) {
    const navigate = useNavigate();
    return (
        <header className="bg-white border-b border-gray-200 sticky top-0 z-10">
            <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
                    <div className="flex items-center space-x-4">
                        {backButton && (
                            <button className="text-gray-500 hover:text-gray-700" onClick={() => navigate(-1)}>
                                <ChevronLeft className="w-6 h-6" />
                            </button>
                        )}
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
                            {description && (
                                <p className="mt-1 text-sm text-gray-500">{description}</p>
                            )}
                        </div>
                    </div>
                    {actions && (
                        <div className="flex items-center gap-3">{actions}</div>
                    )}
                </div>
            </div>
        </header>
    );
}
