// components/ui/SelectMenu/SelectMenu.tsx
import React, { useState, useEffect, useRef } from 'react'
import { Check, ChevronDown, Search } from 'lucide-react'

interface MenuItem {
    id: string;
    label: string;
}

interface SelectMenuProps {
    menuItems: MenuItem[];
    label?: string;
    value?: string;
    onChange?: (value: MenuItem) => void;
    disabled?: boolean;
    defaultValue?: string;
    placeholder?: string;
    error?: string;
    required?: boolean;
    searchPlaceholder?: string;
}

const SelectMenu = React.forwardRef<HTMLButtonElement, SelectMenuProps>(
    ({
         menuItems,
         label,
         onChange,
         disabled = false,
         defaultValue,
         placeholder = 'Select an option',
         error,
         required = false,
         searchPlaceholder = 'Search...',
     }, ref) => {
        const [isOpen, setIsOpen] = useState(false);
        const [searchTerm, setSearchTerm] = useState('');
        const [selected, setSelected] = useState<MenuItem | null>(
            menuItems.find((item) => item.id === defaultValue) || null
        );
        const searchInputRef = useRef<HTMLInputElement>(null);
        const dropdownRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (defaultValue) {
                const defaultItem = menuItems.find((item) => item.id === defaultValue);
                if (defaultItem) {
                    setSelected(defaultItem);
                }
            }
        }, [defaultValue, menuItems]);

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    setIsOpen(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }, []);

        const handleSelect = (item: MenuItem) => {
            setSelected(item);
            onChange?.(item);
            setIsOpen(false);
            setSearchTerm('');
        };

        const filteredItems = menuItems.filter((item) =>
            item.label.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return (
            <div className="relative" ref={dropdownRef}>
                {label && (
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        {label}
                        {required && <span className="text-error-500 ml-1">*</span>}
                    </label>
                )}
                <button
                    ref={ref}
                    type="button"
                    className={`
                        relative w-full cursor-default rounded-md border
                        bg-white py-2 pl-3 pr-10 text-left shadow-sm
                        focus:outline-none focus:ring-1 sm:text-sm
                        ${error
                        ? 'border-error-500 focus:border-error-500 focus:ring-error-500'
                        : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500'
                    }
                        ${disabled ? 'bg-gray-50 text-gray-500 cursor-not-allowed' : ''}
                    `}
                    onClick={() => !disabled && setIsOpen(!isOpen)}
                    disabled={disabled}
                >
                    <span className="block truncate">
                        {selected ? selected.label : placeholder}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDown className="h-4 w-4 text-gray-400" aria-hidden="true" />
                    </span>
                </button>

                {error && (
                    <p className="mt-1 text-sm text-error-500">{error}</p>
                )}

                {isOpen && !disabled && (
                    <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="p-2">
                            <div className="relative">
                                <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                                <input
                                    ref={searchInputRef}
                                    type="text"
                                    className="w-full rounded-md border border-gray-300 py-1.5 pl-8 pr-3 text-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                                    placeholder={searchPlaceholder}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        </div>

                        <div className="max-h-60 overflow-auto py-1">
                            {filteredItems.length > 0 ? (
                                filteredItems.map((item) => (
                                    <button
                                        key={item.id}
                                        className={`
                                            relative cursor-default select-none py-2 pl-8 pr-4 w-full text-left
                                            ${selected?.id === item.id
                                            ? 'bg-primary-50 text-primary-900'
                                            : 'text-gray-900 hover:bg-gray-100'
                                        }
                                        `}
                                        onClick={() => handleSelect(item)}
                                    >
                                        <span className={`block truncate ${selected?.id === item.id ? 'font-semibold' : 'font-normal'}`}>
                                            {item.label}
                                        </span>
                                        {selected?.id === item.id && (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-primary-600">
                                                <Check className="h-4 w-4" />
                                            </span>
                                        )}
                                    </button>
                                ))
                            ) : (
                                <div className="py-2 px-3 text-sm text-gray-500">
                                    No results found
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

SelectMenu.displayName = 'SelectMenu';

export default SelectMenu;
