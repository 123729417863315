import { Boxes, Building2, FileText, Folders, InfoIcon, LayoutDashboard, Users} from "lucide-react";
import {NavItem} from "@/routes/navigation";

export const agentNavItems: NavItem[] = [
    {
        name: 'Πίνακας Ελέγχου',
        icon: LayoutDashboard,
        href: '/'
    },
    {
        name: 'Αιτήματα',
        icon: FileText,
        children: [
            { name: 'Όλα τα Αιτήματα', href: '/tickets' },
            { name: 'Δημιουργία Αιτήματος', href: '/tickets/new' },
            { name: 'Κατηγορίες', href: '/tickets/categories' },
        ]
    },
    {
        name: 'Υποθέσεις',
        icon: Folders,
        children: [
            { name: 'Όλες οι Υποθέσεις', href: '/cases' },
            { name: 'Δημιουργία Υπόθεσης', href: '/cases/new' }
        ]
    },
    {
        name: 'Χρήστες',
        icon: Users,
        children: [
            { name: 'Όλοι οι Χρήστες', href: '/users' },
        ]
    },
    {
        name: 'Εταιρίες',
        icon: Building2,
        children: [
            { name: 'Όλες οι Εταιρίες', href: '/companies' },
        ]
    },
    {
        name: 'Προϊόντα',
        icon: Boxes,
        children: [
            { name: 'Όλα τα Προϊόντα', href: '/products' },
            { name: 'Προσθήκη Προϊόντος', href: '/products/new' },
            { name: 'Τύποι Προϊόντων', href: '/products/types' }
        ]
    },
    // {
    //     name: 'Αναφορές',
    //     icon: BarChart3,
    //     children: [
    //         { name: 'Αναφορά Αιτημάτων', href: '/reports/tickets' },
    //         { name: 'Αναφορά SLA', href: '/reports/sla' }
    //     ]
    // },
    {
        name: 'Πληροφορίες',
        icon: InfoIcon,
        children: [
            { name: 'Επίπεδα Προτεραιότητας', href: '/priorities' },
            { name: 'Καταστάσεις', href: '/status' }
        ]
    },
];
