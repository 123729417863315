// src/pages/CreateCompany.tsx
import React from 'react';
import {useCreateCompanyMutation} from "@/components/features/companies/services/companies.mutations";
import {useNavigate} from "react-router-dom";
import CompanyForm from "@/components/features/companies/components/CompanyForm";
import {CompanyFormFields} from "@/types/company.types";

const baseUrl: string = "/companies";

const CreateCompany = () => {
    const navigate = useNavigate();
    const {mutateAsync} = useCreateCompanyMutation();

    const handleSubmit = async (data: CompanyFormFields) => {
        try {
            const response = await mutateAsync(data);
            if (response.action_status) {
                navigate(`${baseUrl}/${response.company_id}`);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
            <div className="mb-6 pt-8 flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <div>
                        <h1 className="text-2xl font-semibold text-gray-900">Προσθήκη νέας Εταιρίας</h1>
                        <p className="mt-1 text-sm text-gray-500">
                            Προσθήκη νέας Εταιρίας
                        </p>
                    </div>
                </div>
            </div>
            <div className="space-y-6 pb-8">
                <CompanyForm onSubmit={handleSubmit} />
            </div>
        </div>
    );
};

export default CreateCompany;
