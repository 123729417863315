import React, { useEffect } from 'react'
import { X } from 'lucide-react'

interface ModalProps {
    open: boolean
    setOpen: (open: boolean) => void
    title?: string
    children: React.ReactNode
    primaryAction?: {
        text: string
        onClick: () => void
    }
    secondaryAction?: {
        text: string
        onClick: () => void
    }
    icon?: React.ReactNode
    type?: 'delete' | 'success' | 'default'
    className?: string
}

const Modal: React.FC<ModalProps> = ({
    open,
    setOpen,
    title,
    children,
    primaryAction,
    secondaryAction,
    icon,
    type = 'default',
    className = '',
}) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [open])

    if (!open) return null

    const typeClasses = {
        delete: {
            iconBg: 'bg-error-50',
            iconText: 'text-error-600',
            primaryBg: 'bg-error-600',
            primaryHover: 'hover:bg-error-700',
            primaryFocus: 'focus:ring-error-500',
        },
        success: {
            iconBg: 'bg-success-50',
            iconText: 'text-success-600',
            primaryBg: 'bg-success-600',
            primaryHover: 'hover:bg-success-700',
            primaryFocus: 'focus:ring-success-500',
        },
        default: {
            iconBg: 'bg-primary-50',
            iconText: 'text-primary-600',
            primaryBg: 'bg-primary-600',
            primaryHover: 'hover:bg-primary-700',
            primaryFocus: 'focus:ring-primary-500',
        },
    }

    const classes = typeClasses[type]

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" 
                 onClick={() => setOpen(false)} 
            />
            
            <div className="flex min-h-full items-center justify-center p-4">
                <div
                    className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 ${className}`}
                >
                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                        <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                        >
                            <span className="sr-only">Close</span>
                            <X className="h-6 w-6" />
                        </button>
                    </div>

                    <div>
                        {icon && (
                            <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${classes.iconBg} sm:mx-0 sm:h-10 sm:w-10`}>
                                {icon}
                            </div>
                        )}
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                            {title && (
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    {title}
                                </h3>
                            )}
                            <div className="mt-2">{children}</div>
                        </div>
                    </div>

                    {(primaryAction || secondaryAction) && (
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            {primaryAction && (
                                <button
                                    type="button"
                                    className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${classes.primaryBg} ${classes.primaryHover} ${classes.primaryFocus}`}
                                    onClick={primaryAction.onClick}
                                >
                                    {primaryAction.text}
                                </button>
                            )}
                            {secondaryAction && (
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={secondaryAction.onClick}
                                >
                                    {secondaryAction.text}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Modal
