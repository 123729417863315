import { client } from "@/lib/httpClient";
import { ROUTES } from "@/constants/routes.constants";
import { Status } from "@/types/status.types";

export const statusesApi = {
    getAll: async (): Promise<Status[]> => {
        const response = await client.get<Status[]>(ROUTES.STATUS.API.BASE);
        return response.data ?? [];
    },
};
