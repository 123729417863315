import { client } from "@/lib/httpClient";
import { Category, CategoryFormFields, CategoryResponse } from "@/types/category.types";
import { ActionStatus } from "@/types/api.types";
import { ROUTES } from "@/constants/routes.constants";
import { categorySchema } from "@/schemas/category.schemas";

export const categoriesApi = {
    getAll: async (): Promise<Category[]> => {
        const response = await client.get<Category[]>(ROUTES.CATEGORIES.API.BASE);
        return response.data ?? [];
    },

    getById: async (id: string): Promise<Category> => {
        const response = await client.get<Category>(ROUTES.CATEGORIES.API.DETAIL(id));
        return response.data;
    },

    create: async (data: CategoryFormFields): Promise<CategoryResponse> => {
        const validated = categorySchema.parse(data);
        const response = await client.post<CategoryResponse>(
            ROUTES.CATEGORIES.API.BASE,
            validated
        );
        return response.data;
    },

    update: async ({ id, category }: { id: string; category: CategoryFormFields }): Promise<ActionStatus> => {
        const response = await client.put<ActionStatus>(
            ROUTES.CATEGORIES.API.DETAIL(id),
            category
        );
        return response.data;
    },

    delete: async (id: string): Promise<ActionStatus> => {
        const response = await client.delete<ActionStatus>(ROUTES.CATEGORIES.API.DETAIL(id));
        return response.data;
    }
};
