import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Mail, PhoneIcon } from "lucide-react";

import type {Company, CompanyFormFields} from "@/types/company.types";
import { ButtonSize, ButtonType, ButtonVariant } from "@/components/ui/Button/ButtonSize";
import InputWithLabel from "@/components/ui/InputWithLabel";
import Button from "@/components/ui/Button";
import {createCompanySchema} from "@/schemas/company.schemas";

interface CompanyFormProps {
    isEdit?: boolean;
    initialData?: Company;
    onSubmit: (data: CompanyFormFields) => Promise<void>;
    isLoading?: boolean;
}

export const CompanyForm = ({
                                isEdit = false,
                                initialData,
                                onSubmit,
                                isLoading = false
                            }: CompanyFormProps) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<CompanyFormFields>({
        resolver: zodResolver(createCompanySchema),
        defaultValues: {
            company_name: "",
            address: "",
            phone_number: "",
            email_address: "",
        }
    });

    useEffect(() => {
        if (isEdit && initialData) {
            setValue("company_name", initialData.company_name);
            setValue("address", initialData.address);
            setValue("phone_number", initialData.phone_number);
            setValue("email_address", initialData.email_address);
        }
    }, [isEdit, initialData, setValue]);

    useEffect(() => { if (Object.keys(errors).length > 0) { console.log('Validation errors:', errors) } }, [errors])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="bg-white shadow rounded-lg">
                <div className="p-6">
                    <h2 className="text-xl font-semibold text-gray-900 mb-6">
                        {isEdit ? 'Επεξεργασία Εταιρίας' : 'Νέα Εταιρία'}
                    </h2>

                    <div className="space-y-6 mb-6">
                        {/* CompanyName */}
                        <InputWithLabel
                            {...register("company_name")}
                            label="Επωνυμία"
                            required
                            placeholder="Η επωνυμία της Εταιρίας"
                        />

                        {errors.company_name && <div className="text-red-500">{errors.company_name.message}</div>}

                        {/* Address */}
                        <InputWithLabel
                            {...register("address")}
                            label="Διεύθυνση"
                            required
                            placeholder="Η Διεύθυνση της Εταιρίας"
                        />

                        {errors.address && <div className="text-red-500">{errors.address.message}</div>}


                        {/* PhoneNumber & Email */}
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <InputWithLabel
                                {...register("phone_number")}
                                label="Τηλ. Επικοινωνίας"
                                required
                                placeholder="Τηλέφωνο Επικοινωνίας"
                                icon={PhoneIcon}
                            />
                            <InputWithLabel
                                {...register("email_address")}
                                label="Email"
                                required
                                placeholder="Email"
                                icon={Mail}
                            />
                        </div>
                        {errors.phone_number && <div className="text-red-500">{errors.phone_number.message}</div>}
                        {errors.email_address && <div className="text-red-500">{errors.email_address.message}</div>}
                    </div>

                </div>
            </div>

            <div className="flex justify-end space-x-4">
                <Button
                    type={ButtonType.SUBMIT}
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MD}
                    disabled={isLoading}
                    className="min-w-[120px]"
                >
                    {isLoading ? 'Παρακαλώ περιμένετε...' : isEdit ? 'Ενημέρωση' : 'Καταχώρηση'}
                </Button>
            </div>
        </form>
    );
};

export default CompanyForm;
