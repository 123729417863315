import React, { useEffect, useState } from 'react';

interface TimeAgoProps {
    date: string | Date;
    className?: string;
    shortFormat?: boolean;
}

const getGreekPlural = (number: number, singular: string, plural: string, genitive: string): string => {
    if (number === 1) return singular;
    if (number % 10 === 1 && number % 100 !== 11) return singular;
    if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) return plural;
    return genitive;
};

const parseDate = (date: string | Date): Date => {
    if (date instanceof Date) return date;

    // If the date string doesn't end with 'Z', assume it's UTC and append 'Z'
    const dateString = date.endsWith('Z') ? date : `${date}Z`;
    return new Date(dateString);
};

const getTimeAgo = (date: string | Date, shortFormat: boolean, setUpdateInterval: (interval: number) => void): string => {
    try {
        const now = new Date();
        const past = parseDate(date);

        if (isNaN(past.getTime())) {
            return 'μη έγκυρη ημερομηνία';
        }

        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;
        const msPerWeek = msPerDay * 7;
        const msPerMonth = msPerDay * 30;
        const msPerYear = msPerDay * 365;

        // Calculate elapsed time in UTC
        const elapsed = now.getTime() - past.getTime();

        if (elapsed < msPerMinute) {
            setUpdateInterval(1000);
        } else if (elapsed < msPerHour) {
            setUpdateInterval(60000);
        } else {
            setUpdateInterval(300000);
        }

        if (elapsed < msPerMinute) {
            const seconds = Math.round(elapsed / 1000);
            if (seconds <= 0) return 'μόλις τώρα';
            return shortFormat
                ? `${seconds}δ`
                : `πριν ${seconds} ${getGreekPlural(seconds, 'δευτερόλεπτο', 'δευτερόλεπτα', 'δευτερόλεπτα')}`;
        } else if (elapsed < msPerHour) {
            const minutes = Math.round(elapsed / msPerMinute);
            return shortFormat
                ? `${minutes}λ`
                : `πριν ${minutes} ${getGreekPlural(minutes, 'λεπτό', 'λεπτά', 'λεπτά')}`;
        } else if (elapsed < msPerDay) {
            const hours = Math.round(elapsed / msPerHour);
            return shortFormat
                ? `${hours}ω`
                : `πριν ${hours} ${getGreekPlural(hours, 'ώρα', 'ώρες', 'ώρες')}`;
        } else if (elapsed < msPerWeek) {
            const days = Math.round(elapsed / msPerDay);
            return shortFormat
                ? `${days}η`
                : `πριν ${days} ${getGreekPlural(days, 'ημέρα', 'ημέρες', 'ημέρες')}`;
        } else if (elapsed < msPerMonth) {
            const weeks = Math.round(elapsed / msPerWeek);
            return shortFormat
                ? `${weeks}ε`
                : `πριν ${weeks} ${getGreekPlural(weeks, 'εβδομάδα', 'εβδομάδες', 'εβδομάδες')}`;
        } else if (elapsed < msPerYear) {
            const months = Math.round(elapsed / msPerMonth);
            return shortFormat
                ? `${months}μ`
                : `πριν ${months} ${getGreekPlural(months, 'μήνα', 'μήνες', 'μήνες')}`;
        } else {
            const years = Math.round(elapsed / msPerYear);
            return shortFormat
                ? `${years}χ`
                : `πριν ${years} ${getGreekPlural(years, 'χρόνο', 'χρόνια', 'χρόνια')}`;
        }
    } catch (error) {
        console.error('Error parsing date:', error);
        return 'μη έγκυρη ημερομηνία';
    }
};

export const TimeAgo: React.FC<TimeAgoProps> = ({ date, className, shortFormat = false }) => {
    const [timeAgo, setTimeAgo] = useState<string>('');
    const [updateInterval, setUpdateInterval] = useState<number>(60000);

    useEffect(() => {
        const updateTimeAgo = () => {
            setTimeAgo(getTimeAgo(date, shortFormat, setUpdateInterval));
        };

        updateTimeAgo();
        const interval = setInterval(updateTimeAgo, updateInterval);

        return () => clearInterval(interval);
    }, [date, updateInterval, shortFormat]);

    const formattedDate = (() => {
        try {
            const parsedDate = parseDate(date);
            if (isNaN(parsedDate.getTime())) {
                return 'μη έγκυρη ημερομηνία';
            }

            return parsedDate.toLocaleString('el-GR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone: 'UTC'  // Explicitly use UTC for formatting
            });
        } catch {
            return 'μη έγκυρη ημερομηνία';
        }
    })();

    return (
        <time
            className={className}
            title={formattedDate}
            dateTime={parseDate(date).toISOString()}
            aria-label={`Δημοσιεύτηκε ${formattedDate}`}
        >
            {timeAgo}
        </time>
    );
};
