import React from 'react';
import DropdownWithLabel from '@/components/ui/DropdownWithLabel';
import type { Status } from '@/types/status.types'; // Import Status type
import { useGetStatusesQuery } from "@/components/features/statuses/services/statuses.queries";

interface StatusDropdownProps {
    label?: string;
    value?: string;
    onChange?: (value: string) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    helperText?: string;
}

const StatusDropdown = React.forwardRef<HTMLSelectElement, StatusDropdownProps>(
    ({
         label = 'Κατάσταση',
         value,
         onChange,
         error,
         required = false,
         disabled = false,
         placeholder = 'Επιλέξτε κατάσταση',
         helperText,
         ...props
     }, ref) => {
        const { data: statuses, isLoading, isError } = useGetStatusesQuery();

        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange?.(event.target.value);
        };

        const statusOptions = React.useMemo(() => {
            if (!statuses) return [];

            return statuses.map((status: Status) => ({  // Change type to Status
                value: status.status_id,
                label: status.status_name,
            }));
        }, [statuses]);

        if (isError) {
            return (
                <DropdownWithLabel
                    ref={ref}
                    label={label}
                    options={[]}
                    error="Σφάλμα φόρτωσης καταστάσεων"
                    disabled
                    required={required}
                    {...props}
                />
            );
        }

        return (
            <DropdownWithLabel
                ref={ref}
                label={label}
                options={statusOptions}
                value={value}
                onChange={handleChange}
                error={error}
                disabled={disabled || isLoading}
                required={required}
                placeholder={isLoading ? 'Φόρτωση...' : placeholder}
                helperText={helperText}
                {...props}
            />
        );
    }
);

StatusDropdown.displayName = 'StatusDropdown';

export default StatusDropdown;
