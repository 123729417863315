// useGlobalStore.ts
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { getItem, setItem } from '@/lib/localStorage'

interface GlobalState {
    isSidebarOpen: boolean
}

export interface GlobalStore extends GlobalState {
    toggleSidebar: (b: boolean) => void
}

const initialState: Pick<GlobalStore, keyof GlobalState> = {
    isSidebarOpen: getItem('isSidebarOpen') ?? false,
}

const useGlobalStore = create<GlobalStore>()(
    devtools(
        (set) => ({
            ...initialState,
            toggleSidebar: () => {
                set((state) => {
                    setItem('isSidebarOpen', !state.isSidebarOpen)
                    return { isSidebarOpen: !state.isSidebarOpen }
                })
            },
        }),
        {
            name: "Global Store",
            enabled: import.meta.env.DEV
        }
    )
)

export default useGlobalStore
