import { useQuery } from '@tanstack/react-query';
import {ProductType} from "@/types/productTypes.types";
import {PRODUCT_TYPE_KEYS} from "@/components/features/productTypes/services/productTypes.queryKeys";
import {productTypesApi} from "@/components/features/productTypes/services/productTypes.api";

export const useGetProductTypesQuery = () => {
    return useQuery<ProductType[]>({
        queryKey: PRODUCT_TYPE_KEYS.list,
        queryFn: productTypesApi.getAll
    });
};
