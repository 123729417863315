import Button from "@/components/ui/Button";
import {useAuth0} from "@auth0/auth0-react";

const LoginButton = () => {
    const {loginWithRedirect} = useAuth0();

    return <>
        <Button onClick={() => loginWithRedirect()}>ΕΙΣΟΔΟΣ</Button>
    </>
}

export default LoginButton;