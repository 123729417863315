import { useEffect } from 'react';
import { useHeartbeatMutation } from '@/components/features/users/services/users.mutations';

const UserActivityHeartbeat = () => {
    const heartbeatMutation = useHeartbeatMutation();

    // Send heartbeat once when component mounts
    useEffect(() => {
        heartbeatMutation.mutate();
        // We explicitly want this to run only once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default UserActivityHeartbeat;
