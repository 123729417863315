// src/pages/AdministratorDashboard.tsx
import React from 'react';
import {
    Building2,
    AlertTriangle,
    Clock,
    ArrowUp,
    ArrowDown,
    Activity,
    CheckCircle2,
    AlertCircle,
    UserCog,
    ClipboardList,
    Timer,
    BarChart3
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import { TimeAgo } from "@/components/ui/TimeAgo/TimeAgo";
import { Badge } from "@/components/ui/Badge/Badge";
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import {PriorityEnum} from "@/types/priority.types";
import {StatusEnum} from "@/types/status.types";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import StatusBadge from "@/components/ui/Badge/StatusBadge";

export default function AdministratorDashboard() {
    const stats = {
        totalAgents: {
            count: 24,
            trend: 2,
            trendUp: true
        },
        avgResponseTime: {
            value: "1.5 ώρες",
            trend: -15,
            trendUp: false // Down is good here
        },
        avgResolutionTime: {
            value: "8.2 ώρες",
            trend: -5,
            trendUp: false // Down is good here
        },
        slaBreaches: {
            count: 3,
            trend: -2,
            trendUp: false
        },
        openTickets: {
            count: 15,
            trend: 2,
            trendUp: true
        },
        activeTickets: {
            count: 48,
            trend: 5,
            trendUp: true
        },
        customerSatisfaction: {
            value: "92%",
            trend: 3,
            trendUp: true
        },
        avgTicketsPerDay: {
            value: 25,
            trend: 2,
            trendUp: true
        }
    };

    const unassignedTickets = [
        {
            id: "TK-001",
            title: "Αδυναμία πρόσβασης στο dashboard",
            status: StatusEnum.OPEN,
            priority: PriorityEnum.HIGH,
            company: "Cosmote",
            createdAt: "2024-02-15T10:30:00Z",
            department: "Τεχνικό"
        },
        // Add more tickets...
    ];

    const activeTickets = [
        {
            id: "TK-002",
            title: "Σφάλμα κατά την εξαγωγή αναφοράς",
            status: StatusEnum.PENDING,
            priority: PriorityEnum.MEDIUM,
            company: "Vodafone",
            assignedTo: "Μαρία Π.",
            createdAt: "2024-02-15T09:15:00Z"
        },
        // Add more tickets...
    ];

    const highPriorityTickets = [
        {
            id: "TK-003",
            title: "Διακοπή υπηρεσίας σε παραγωγικό περιβάλλον",
            status: StatusEnum.OPEN,
            priority: PriorityEnum.URGENT,
            company: "Wind",
            assignedTo: "Γιώργος Ν.",
            createdAt: "2024-02-15T11:00:00Z",
            slaRemaining: "2 ώρες"
        },
        // Add more tickets...
    ];

    const slaAlerts = [
        {
            ticket: "TK-045",
            timeLeft: "30 λεπτά",
            priority: PriorityEnum.HIGH,
            company: "Wind",
            assignedTo: "Νίκος Κ."
        },
        // Add more alerts...
    ];

    return (
        <div className="h-full w-full bg-gray-100">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                <div className="mb-8">
                    <h1 className="text-2xl font-bold text-gray-900">Πίνακας Ελέγχου</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Επισκόπηση συστήματος και διαχείριση αιτημάτων
                    </p>
                </div>

                {/* Primary Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Open Tickets Card */}
                    <Card className="bg-blue-50 border-blue-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-blue-700">Μη Ανατεθειμένα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-blue-900">{stats.openTickets.count}</p>
                                        <p className="ml-2 text-sm text-blue-700">
                                            αιτήματα
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-blue-100 rounded-lg">
                                    <ClipboardList className="h-6 w-6 text-blue-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Active Tickets */}
                    <Card className="bg-green-50 border-green-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-green-700">Ενεργά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-green-900">{stats.activeTickets.count}</p>
                                        <p className={`ml-2 text-sm ${stats.activeTickets.trendUp ? 'text-error-600' : 'text-success-600'}`}>
                                            {stats.activeTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.activeTickets.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-green-100 rounded-lg">
                                    <Activity className="h-6 w-6 text-green-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Response Time */}
                    <Card className="bg-purple-50 border-purple-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-purple-700">Μέσος Χρόνος Απόκρισης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-purple-900">{stats.avgResponseTime.value}</p>
                                        <p className={`ml-2 text-sm ${!stats.avgResponseTime.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.avgResponseTime.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.avgResponseTime.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-purple-100 rounded-lg">
                                    <Timer className="h-6 w-6 text-purple-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Breaches - Warning Card */}
                    <Card className="bg-error-50 border-error-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-error-700">Παραβιάσεις SLA</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-error-900">{stats.slaBreaches.count}</p>
                                        <p className={`ml-2 text-sm ${stats.slaBreaches.trendUp ? 'text-error-600' : 'text-success-600'}`}>
                                            {stats.slaBreaches.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {Math.abs(stats.slaBreaches.trend)}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-error-100 rounded-lg">
                                    <AlertTriangle className="h-6 w-6 text-error-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Secondary Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Resolution Time */}
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-gray-600">Μέσος Χρόνος Επίλυσης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-gray-900">{stats.avgResolutionTime.value}</p>
                                        <p className={`ml-2 text-sm ${!stats.avgResolutionTime.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.avgResolutionTime.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.avgResolutionTime.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-100 rounded-lg">
                                    <Clock className="h-6 w-6 text-gray-600"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Available Agents */}
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-gray-600">Διαθέσιμοι Agents</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-gray-900">{stats.totalAgents.count}</p>
                                        <p className={`ml-2 text-sm ${stats.totalAgents.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.totalAgents.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.totalAgents.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-100 rounded-lg">
                                    <UserCog className="h-6 w-6 text-gray-600"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Customer Satisfaction */}
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-gray-600">Ικανοποίηση Πελατών</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-gray-900">{stats.customerSatisfaction.value}</p>
                                        <p className={`ml-2 text-sm ${stats.customerSatisfaction.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.customerSatisfaction.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.customerSatisfaction.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-100 rounded-lg">
                                    <CheckCircle2 className="h-6 w-6 text-gray-600"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Daily TicketTypes Average */}
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-gray-600">Μ.Ο. Αιτημάτων/Ημέρα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-gray-900">{stats.avgTicketsPerDay.value}</p>
                                        <p className={`ml-2 text-sm ${!stats.avgTicketsPerDay.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.avgTicketsPerDay.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.avgTicketsPerDay.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-gray-100 rounded-lg">
                                    <BarChart3 className="h-6 w-6 text-gray-600"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                    {/* Unassigned Tickets */}
                    <Card>
                        <CardHeader>
                            <div className="flex items-center justify-between">
                                <CardTitle className="flex items-center">
                                    <span>Μη Ανατεθειμένα Αιτήματα</span>
                                    <Badge variant="warning" className="ml-2">
                                        {unassignedTickets.length} σε αναμονή
                                    </Badge>
                                </CardTitle>
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                >
                                    Προβολή Όλων
                                </Button>
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {unassignedTickets.map(ticket => (
                                    <div key={ticket.id} className="flex items-start justify-between border-b pb-4">
                                        <div className="space-y-1">
                                            <div className="flex items-center space-x-2">
                                                <span className="font-medium">#{ticket.id}</span>
                                                <PriorityBadge priority={ticket.priority} />
                                            </div>
                                            <p className="text-sm text-gray-900">{ticket.title}</p>
                                            <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                <Building2 className="h-4 w-4" />
                                                <span>{ticket.company}</span>
                                                <span>•</span>
                                                <span>{ticket.department}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end space-y-2">
                                            <TimeAgo date={ticket.createdAt} className="text-sm text-gray-500" />
                                            <Button
                                                variant={ButtonVariant.PRIMARY}
                                                size={ButtonSize.SM}
                                            >
                                                Ανάθεση
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* High Priority Tickets */}
                    <Card>
                        <CardHeader>
                            <div className="flex items-center justify-between">
                                <CardTitle className="flex items-center">
                                    <span>Υψηλής Προτεραιότητας</span>
                                    <Badge variant="error" className="ml-2">
                                        {highPriorityTickets.length} επείγοντα
                                    </Badge>
                                </CardTitle>
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                >
                                    Προβολή Όλων
                                </Button>
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {highPriorityTickets.map(ticket => (
                                    <div key={ticket.id} className="flex items-start justify-between border-b pb-4">
                                        <div className="space-y-1">
                                            <div className="flex items-center space-x-2">
                                                <span className="font-medium">#{ticket.id}</span>
                                                <StatusBadge status={ticket.status} />
                                            </div>
                                            <p className="text-sm text-gray-900">{ticket.title}</p>
                                            <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                <Building2 className="h-4 w-4" />
                                                <span>{ticket.company}</span>
                                                <span>•</span>
                                                <span>{ticket.assignedTo}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end space-y-2">
                                            <div className="flex items-center text-error-600 text-sm">
                                                <Clock className="h-4 w-4 mr-1" />
                                                <span>SLA: {ticket.slaRemaining}</span>
                                            </div>
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                size={ButtonSize.SM}
                                            >
                                                Προβολή
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Alerts */}
                    <Card>
                        <CardHeader>
                            <div className="flex items-center justify-between">
                                <CardTitle className="flex items-center">
                                    <span>Επείγουσες Ειδοποιήσεις SLA</span>
                                    <Badge variant="outline" className="ml-2">
                                        {slaAlerts.length} κρίσιμα
                                    </Badge>
                                </CardTitle>
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {slaAlerts.map(alert => (
                                    <div key={alert.ticket} className="flex items-center justify-between border-b pb-4">
                                        <div className="space-y-1">
                                            <div className="flex items-center space-x-2">
                                                <AlertCircle className="h-4 w-4 text-error-500" />
                                                <span className="font-medium">#{alert.ticket}</span>
                                                <PriorityBadge priority={alert.priority} />
                                            </div>
                                            <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                <Building2 className="h-4 w-4" />
                                                <span>{alert.company}</span>
                                                <span>•</span>
                                                <span>{alert.assignedTo}</span>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-3">
                                            <div className="flex items-center text-error-600">
                                                <Clock className="h-4 w-4 mr-1" />
                                                <span className="text-sm font-medium">{alert.timeLeft}</span>
                                            </div>
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                size={ButtonSize.SM}
                                            >
                                                Προβολή
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* Active Tickets Summary */}
                    <Card>
                        <CardHeader>
                            <div className="flex items-center justify-between">
                                <CardTitle className="flex items-center">
                                    <span>Σύνοψη Ενεργών Αιτημάτων</span>
                                    <Badge variant="success" className="ml-2">
                                        {activeTickets.length} συνολικά
                                    </Badge>
                                </CardTitle>
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                >
                                    Προβολή Όλων
                                </Button>
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {activeTickets.map(ticket => (
                                    <div key={ticket.id} className="flex items-start justify-between border-b pb-4">
                                        <div className="space-y-1">
                                            <div className="flex items-center space-x-2">
                                                <span className="font-medium">#{ticket.id}</span>
                                                <StatusBadge status={ticket.status} />
                                                <PriorityBadge priority={ticket.priority} />
                                            </div>
                                            <p className="text-sm text-gray-900">{ticket.title}</p>
                                            <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                <Building2 className="h-4 w-4" />
                                                <span>{ticket.company}</span>
                                                <span>•</span>
                                                <span>{ticket.assignedTo}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end space-y-2">
                                            <TimeAgo date={ticket.createdAt} className="text-sm text-gray-500" />
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                size={ButtonSize.SM}
                                            >
                                                Προβολή
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )
}



