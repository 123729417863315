import { useQuery } from '@tanstack/react-query';
import type { User } from '@/types/user.types';
import { USER_KEYS } from './users.queryKeys';
import { usersApi, UserFilters } from './users.api';

export const useGetUsersQuery = (filters?: UserFilters) => {
    return useQuery<User[]>({
        queryKey: [USER_KEYS.list, filters],
        queryFn: () => usersApi.getAll(filters),
    });
};

export const useGetUserQuery = (id: string) => {
    return useQuery<User>({
        queryKey: USER_KEYS.detail(id),
        queryFn: () => usersApi.getById(id),
        enabled: !!id,
    });
};
