// src/components/ui/InputWithLabel/InputWithLabel.tsx
import React from 'react'
import { LucideIcon } from 'lucide-react'

interface InputWithLabelProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: string
    required?: boolean
    icon?: LucideIcon  // Add icon prop
}

const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>(
    ({ label, error, required, className = '', id, icon: Icon, ...props }, ref) => {
        // Generate unique ID if none provided
        const inputId = id || `input-${Math.random().toString(36).substr(2, 9)}`

        return (
            <div className="input-with-label">
                {label && (
                    <label
                        htmlFor={inputId}
                        className="block text-sm font-medium text-gray-700"
                    >
                        {label}
                        {required && <span className="text-error-500 ml-1">*</span>}
                    </label>
                )}
                <div className="mt-1 relative">
                    {Icon && (
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Icon className="h-5 w-5 text-gray-400" />
                        </div>
                    )}
                    <input
                        ref={ref}
                        id={inputId}
                        className={`
                            block w-full rounded-md border-gray-300 shadow-sm 
                            focus:border-primary-500 focus:ring-primary-500 sm:text-sm
                            disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500
                            ${Icon ? 'pl-10' : ''} 
                            ${error ? 'border-error-500 focus:border-error-500 focus:ring-error-500' : ''}
                            ${className}
                        `}
                        {...props}
                    />
                    {error && (
                        <p className="mt-1 text-sm text-error-500">
                            {error}
                        </p>
                    )}
                </div>
            </div>
        )
    }
)

InputWithLabel.displayName = 'InputWithLabel'

export default InputWithLabel
