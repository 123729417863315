import { client } from '@/lib/httpClient';
import type { Product, ProductFormFields, ProductResponse, ProductClient } from '@/types/product.types';
import { ROUTES } from '@/constants/routes.constants';
import { productSchema } from '@/schemas/product.schemas';
import type { ActionStatus } from '@/types/api.types';

export const productsApi = {
    getAll: async (): Promise<Product[]> => {
        const response = await client.get<Product[]>(
            ROUTES.PRODUCTS.API.BASE
        );
        return response.data ?? [];
    },

    getById: async (id: string): Promise<Product> => {
        const response = await client.get<Product>(
            ROUTES.PRODUCTS.API.DETAIL(id)
        );
        return response.data;
    },

    create: async (data: ProductFormFields): Promise<ProductResponse> => {
        const validated = productSchema.parse(data);
        const response = await client.post<ProductResponse>(
            ROUTES.PRODUCTS.API.BASE,
            validated
        );
        return response.data;
    },

    update: async (id: string, data: ProductFormFields): Promise<ActionStatus> => {
        const validated = productSchema.parse(data);
        const response = await client.put<ActionStatus>(
            ROUTES.PRODUCTS.API.DETAIL(id),
            validated
        );
        return response.data;
    },

    delete: async (id: string): Promise<ActionStatus> => {
        const response = await client.delete<ActionStatus>(
            ROUTES.PRODUCTS.API.DETAIL(id)
        );
        return response.data;
    },

    getClients: async (id: string): Promise<ProductClient[]> => {
        const response = await client.get<ProductClient[]>(
            ROUTES.PRODUCTS.API.CLIENTS(id)
        );
        return response.data ?? [];
    }
};
