// src/components/ui/Badge/Badge.tsx
import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

const badgeVariants = cva(
    "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    {
        variants: {
            variant: {
                default: "bg-primary-500 text-primary-foreground hover:bg-primary-600",
                secondary: "bg-gray-200 text-gray-foreground hover:bg-gray-300",
                success: "bg-success-100 text-success-700 hover:bg-success-200",
                error: "bg-error-100 text-error-700 hover:bg-error-200",
                warning: "bg-warning-100 text-warning-700 hover:bg-warning-200",
                destructive: "bg-destructive-100 text-destructive-700 hover:bg-destructive-200",
                outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
)

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

export function Badge({ className, variant, ...props }: BadgeProps) {
    return (
        <div className={badgeVariants({ variant, className })} {...props} />
    )
}
