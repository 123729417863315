import { useQuery } from '@tanstack/react-query';
import { messagesApi } from './messages.api';
import { MESSAGE_KEYS } from './messages.queryKeys';

export function useGetTicketMessagesQuery(ticketId: string) {
    return useQuery({
        queryKey: MESSAGE_KEYS.list(ticketId),
        queryFn: () => messagesApi.getAll(ticketId),
        enabled: Boolean(ticketId),
        staleTime: 1000 * 60, // 30 seconds - more frequent updates for messages
        refetchInterval: 1000 * 60 // Auto refresh every 60 seconds
    });
}

export function useGetTicketMessageAttachmentQuery(messageAttachmentId: string) {
    return useQuery({
        queryKey: MESSAGE_KEYS.attachment(messageAttachmentId),
        queryFn: () => messagesApi.getAttachment(messageAttachmentId),
        staleTime: 1000 * 60, // 30 seconds - more frequent updates for messages
        refetchInterval: 1000 * 60 // Auto refresh every 60 seconds
    });
}
