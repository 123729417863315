import {toast} from "react-toastify";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {CreateMessageRequest} from "@/schemas/message.schemas";
import {messagesApi} from "@/components/features/messages/services/messages.api";
import {MESSAGE_KEYS} from "@/components/features/messages/services/messages.queryKeys";

export function useCreateMessageMutation(ticketId: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: CreateMessageRequest) =>
            messagesApi.create(ticketId, data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: MESSAGE_KEYS.list(ticketId)
            });
            toast.success('Το μήνυμα στάλθηκε επιτυχώς');
        },
        onError: (error) => {
            console.error('Create message error:', error);
            toast.error('Σφάλμα κατά την αποστολή του μηνύματος');
        }
    });
}
