import { Search, Bell } from 'lucide-react';
import useGlobalStore from "@/stores/useGlobalStore";
import { Menu } from 'lucide-react';
import ProfileDropdown from "@/components/ui/ProfileDropdown";

export default function Header() {
    const { toggleSidebar } = useGlobalStore();

    return (
        <header className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm">
            <button
                onClick={() => toggleSidebar(true)}
                className="text-gray-500 focus:outline-none md:hidden"
            >
                <Menu className="h-6 w-6" />
            </button>

            {/* Search */}
            <div className="flex flex-1 gap-x-4">
                <div className="flex flex-1 items-center">
                    <div className="relative w-full max-w-md">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <Search className="h-4 w-4 text-gray-400" />
                        </div>
                        <input
                            type="text"
                            placeholder="Αναζήτηση"
                            className="block w-full rounded-lg border-0 py-1.5 pl-10 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-white sm:text-sm sm:leading-6 bg-white"
                        />
                    </div>
                </div>
            </div>

            {/* Right section */}
            <div className="flex items-center gap-x-4">
                <button className="text-gray-400 hover:text-gray-500">
                    <Bell className="h-6 w-6" />
                </button>
                <ProfileDropdown />
            </div>
        </header>
    );
}
