import {AlertTriangle, ClockAlert, Flame, Info, LucideIcon} from "lucide-react";

export type Priority = {
    priority_id: string;
    priority_name: PriorityName;
    description: string;
    response_time: number;
    resolution_time: number;
};


export const PriorityEnum = {
    LOW: 'Χαμηλή',
    MEDIUM: 'Μεσαία',
    HIGH: 'Υψηλή',
    URGENT: 'Επείγον'
} as const;

export type PriorityName = typeof PriorityEnum[keyof typeof PriorityEnum];


export const PRIORITY_ICONS: Record<PriorityName, LucideIcon> = {
    [PriorityEnum.URGENT]: Flame,
    [PriorityEnum.HIGH]: AlertTriangle,
    [PriorityEnum.MEDIUM]: ClockAlert,
    [PriorityEnum.LOW]: Info
} as const;


// Utility function to get enum key from value
// export const getPriorityKey = (value: PriorityName): keyof typeof PriorityEnum => {
//     return Object.entries(PriorityEnum).find(([_, val]) => val === value)?.[0] as keyof typeof PriorityEnum;
// };

export const getPriorityIcon = (priority: PriorityName): LucideIcon => {
    return PRIORITY_ICONS[priority] || Info;
};

export const mapPriorityToEnum = (priority: string): PriorityName => {
    const priorityMap: Record<string, PriorityName> = {
        'Χαμηλή': PriorityEnum.LOW,
        'Μεσαία': PriorityEnum.MEDIUM,
        'Υψηλή': PriorityEnum.HIGH,
        'Επείγον': PriorityEnum.URGENT
    };

    return priorityMap[priority] || PriorityEnum.LOW; // Fallback to LOW if priority not found
};
