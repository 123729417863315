import { z } from 'zod';
import type { CreateDepartmentRequest } from '@/types/department.types';

export const createDepartmentSchema = z.object({
  department_name: z.string()
      .min(2, 'Το όνομα πρέπει να έχει τουλάχιστον 2 χαρακτήρες')
      .max(150, 'Το όνομα δεν μπορεί να υπερβαίνει τους 150 χαρακτήρες'),
  description: z.string().nullish(),  // Use nullish() instead of optional().nullable()
  company_id: z.string().uuid('Μη έγκυρο ID εταιρείας')
}) satisfies z.ZodType<CreateDepartmentRequest>;

export const updateDepartmentSchema = createDepartmentSchema.partial();
