import React from 'react';
import { Activity, FileText, RefreshCw, UserCheck} from 'lucide-react';
import { TimeAgo } from '@/components/ui/TimeAgo/TimeAgo';
import { useGetTicketAuditsQuery } from '../services/tickets.queries';
import type { TimelineItem } from '@/types/audit.types';
import {getBgColor, getStatusColorClasses, STATUS_COLORS} from '@/constants/colors.constants';

interface TimelineItemProps {
    item: TimelineItem;
    isLast: boolean;
}

const TimelineItemComponent: React.FC<TimelineItemProps> = ({ item, isLast }) => {
    const getIcon = () => {
        switch (item.type) {
            case 'status':
                if (!item.details.old?.status_name) {
                    return <FileText className="h-5 w-5 text-blue-500" />;
                }
                return <RefreshCw className="h-5 w-5 text-green-500" />;

            case 'assignment':
                return <UserCheck className="h-5 w-5 text-orange-500" />;

            default:
                // Fallback to Activity icon
                return <Activity className="h-5 w-5 text-gray-400" />;
        }
    };

    const getItemColorClasses = () => {
        if (item.type === 'status') {
            if (!item.details.old?.status_name) {
                // New ticket creation
                return {
                    icon: 'text-blue-500',
                    bg: 'bg-blue-50',
                    ring: 'ring-blue-50'
                };
            }
            // Status change - use the new status color
            const statusColors = getStatusColorClasses(item.details.new.status_name);
            return {
                icon: statusColors.icon,
                bg: getBgColor(STATUS_COLORS[item.details.new.status_name]),
                ring: `ring-${STATUS_COLORS[item.details.new.status_name]}-50`
            };
        }
        // Assignment changes
        return {
            icon: 'text-gray-500',
            bg: 'bg-gray-100',
            ring: 'ring-white'
        };
    };

    const getMessage = () => {
        switch (item.type) {
            case 'status':
                if (!item.details.old?.status_name) {
                    return (
                        <>
                            δημιούργησε το αίτημα με κατάσταση{' '}
                            <span className={`font-medium ${getStatusColorClasses(item.details.new.status_name).text}`}>
                                {item.details.new.status_name}
                            </span>
                        </>
                    );
                }
                return (
                    <>
                        άλλαξε την κατάσταση από{' '}
                        <span className={`font-medium ${getStatusColorClasses(item.details.old.status_name).text}`}>
                            {item.details.old.status_name}
                        </span>
                        {' σε '}
                        <span className={`font-medium ${getStatusColorClasses(item.details.new.status_name).text}`}>
                            {item.details.new.status_name}
                        </span>
                    </>
                );
            case 'assignment':
                return (() => {
                    const oldName = item.details.old
                        ? `${item.details.old.first_name} ${item.details.old.last_name}`
                        : 'μη ανατεθειμένο';
                    const newName = item.details.new
                        ? `${item.details.new.first_name} ${item.details.new.last_name}`
                        : 'μη ανατεθειμένο';
                    return (
                        <>
                            άλλαξε την ανάθεση από{' '}
                            <span className="font-medium text-gray-900">{oldName}</span>
                            {' σε '}
                            <span className="font-medium text-gray-900">{newName}</span>
                        </>
                    );
                })();
            default:
                return null;
        }
    };

    const colorClasses = getItemColorClasses();

    return (
        <li>
            <div className="relative pb-8">
                {!isLast && (
                    <span
                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                    />
                )}
                <div className="relative flex items-start space-x-3">
                    <div>
                        <div className="relative px-1">
                            <div
                                className={`flex h-8 w-8 items-center justify-center rounded-full ${colorClasses.bg} ring-1 ${colorClasses.ring}`}>
                                <div className={colorClasses.icon}>
                                    {getIcon()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-0 flex-1 py-1.5">
                        <div className="text-sm text-gray-500">
                            <span className="font-medium text-gray-900 mr-1">
                                {item.user.first_name} {item.user.last_name}
                            </span>
                            {getMessage()}
                            <TimeAgo date={item.created_at} className="ml-2"/>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};

interface TicketTimelineProps {
    ticketId: string;
}

export const TicketTimeline: React.FC<TicketTimelineProps> = ({ ticketId }) => {
    const { statusAudits, assignmentAudits, isLoading } = useGetTicketAuditsQuery(ticketId);

    if (isLoading) {
        return <div className="animate-pulse">Loading...</div>;
    }

    console.log(statusAudits.data);
    console.log(assignmentAudits.data);

    const timelineItems: TimelineItem[] = [
        ...(statusAudits.data?.map(audit => ({
            id: audit.status_audit_id,
            type: 'status' as const,
            created_at: audit.created_at,
            user: audit.user,
            details: {
                old: audit.old_status,
                new: audit.new_status,
            },
        })) || []),
        ...(assignmentAudits.data?.map(audit => ({
            id: audit.assignment_audit_id,
            type: 'assignment' as const,
            created_at: audit.created_at,
            user: audit.user,
            details: {
                old: audit.old_assignee,
                new: audit.new_assignee,
            },
        })) || []),
    ].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    return (
        <div className="flow-root">
            <ul role="list" className="-mb-8">
                {timelineItems.map((item, index) => (
                    <TimelineItemComponent
                        key={item.id}
                        item={item}
                        isLast={index === timelineItems.length - 1}
                    />
                ))}
            </ul>
        </div>
    );
};
