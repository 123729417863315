// src/pages/CreateProduct.tsx
import React from 'react';
import {ChevronLeft, Package} from 'lucide-react';
import Button from '@/components/ui/Button';
import {ButtonSize, ButtonType, ButtonVariant} from '@/components/ui/Button/ButtonSize';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/Card";
import {Product, ProductFormFields} from "@/types/product.types";
import {useLocation, useNavigate} from "react-router-dom";
import {productSchema} from "@/schemas/product.schemas";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import ProductTypeDropdown from "@/components/ui/DropdownWithLabel/ProductTypeDropdown";
import {
    useCreateProductMutation,
    useUpdateProductMutation
} from "@/components/features/products/services/products.mutations";
import {toast} from "react-toastify";
import {ROUTES} from "@/constants/routes.constants";

const CreateProduct = () => {
    const [isActive] = React.useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const product = location.state?.product as Product | undefined;

    const createMutation = useCreateProductMutation();
    const updateMutation = useUpdateProductMutation();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting }
    } = useForm<ProductFormFields>({
        resolver: zodResolver(productSchema),
        defaultValues: product ? {
            product_code: product.product_code,
            product_name: product.product_name,
            product_type_id: product.product_type.product_type_id,
            description: product.description,
            price: product.price,
            is_active: true
        } : {
            is_active: true
        }
    });

    const onSubmit = async (data: ProductFormFields) => {
        try {
            if (product) {
                await updateMutation.mutateAsync({
                    id: product.product_id,
                    data: { ...data, is_active: isActive }
                });
                toast.success('Το προϊόν ενημερώθηκε επιτυχώς');
            } else {
                await createMutation.mutateAsync({ ...data, is_active: isActive });
                toast.success('Το προϊόν δημιουργήθηκε επιτυχώς');
            }
            navigate(ROUTES.PRODUCTS.LIST);
        } catch (error) {
            toast.error('Σφάλμα κατά την αποθήκευση του προϊόντος');
        }
    };


    return (
        <div className="min-h-screen bg-gray-100">
            <header className="bg-white border-b border-gray-200 sticky top-0 z-10">
                <div className="max-w-3xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <button className="text-gray-500 hover:text-gray-700">
                                <ChevronLeft className="w-6 h-6"/>
                            </button>
                            <div>
                                <h1 className="text-2xl font-semibold text-gray-900">
                                    {product ? 'Επεξεργασία Προϊόντος' : 'Προσθήκη Προϊόντος'}
                                </h1>
                                <p className="mt-1 text-sm text-gray-500">
                                    {product ? 'Επεξεργασία προϊόντος ή υπηρεσίας' : 'Δημιουργία νέου προϊόντος ή υπηρεσίας'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <main className="max-w-3xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    {/* Basic Information */}
                    <Card>
                        <CardHeader>
                            <CardTitle className="flex items-center">
                                <Package className="w-5 h-5 mr-2"/>
                                Βασικές Πληροφορίες
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <InputWithLabel
                                    label="Κωδικός Προϊόντος"
                                    required
                                    placeholder="π.χ. PRD-001"
                                    error={errors.product_code?.message}
                                    {...register('product_code')}
                                />
                                <InputWithLabel
                                    label="Όνομα Προϊόντος"
                                    required
                                    placeholder="Εισάγετε όνομα προϊόντος"
                                    error={errors.product_name?.message}
                                    {...register('product_name')}
                                />
                            </div>

                            <TextArea
                                label="Περιγραφή"
                                required
                                rows={4}
                                placeholder="Περιγράψτε το προϊόν..."
                                error={errors.description?.message}
                                {...register('description')}
                            />

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <ProductTypeDropdown
                                    required
                                    error={errors.product_type_id?.message}
                                    value={product?.product_type.product_type_id}
                                    onChange={(value) => setValue('product_type_id', value)}
                                />
                                <InputWithLabel
                                    label="Τιμή"
                                    type="number"
                                    required
                                    placeholder="10.00"
                                    // step="10"
                                    error={errors.price?.message}
                                    {...register('price', { valueAsNumber: true })}
                                />
                            </div>

                        </CardContent>
                    </Card>

                    {/* Submit Buttons */}
                    <div className="flex justify-end space-x-4">
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.LG}
                            type={ButtonType.BUTTON}
                            onClick={() => navigate(ROUTES.PRODUCTS.LIST)}
                        >
                            Ακύρωση
                        </Button>
                        <Button
                            type={ButtonType.SUBMIT}
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LG}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Αποθήκευση...' : product ? 'Αποθήκευση' : 'Δημιουργία'}
                        </Button>
                    </div>
                </form>
            </main>
        </div>
)
    ;
};

export default CreateProduct;
