import {client} from "@/lib/httpClient";
import {ActionStatus} from "@/types/api.types";
import {ROUTES} from "@/constants/routes.constants";

export interface UpdateSlaRequest {
    sla_name: string;
    description: string;
    response_time: number;
    resolution_time: number;
}

export const slaApi = {

    update: async (slaId: string, data: UpdateSlaRequest) => {
        const response = await client.put<ActionStatus>(
            `${ROUTES.SLA.API.DETAIL(slaId)}`,
            data
        );
        return response.data;
    }
};
