import { useQuery } from "@tanstack/react-query";
import type { Category } from "@/types/category.types";
import { CATEGORY_KEYS } from "./categories.queryKeys";
import { categoriesApi } from "./categories.api";

export const useGetCategoriesQuery = () => {
    return useQuery<Category[]>({
        queryKey: CATEGORY_KEYS.list,
        queryFn: categoriesApi.getAll,
    });
};

export const useGetCategoryQuery = (id: string) => {
    return useQuery<Category>({
        queryKey: CATEGORY_KEYS.detail(id),
        queryFn: () => categoriesApi.getById(id),
        enabled: !!id,
    });
};
