// useUserStore.ts
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface AuthUser {
    user_id: string;
    name: string | undefined;
    email: string | undefined;
    profilePicture: string | undefined;
    roles: string[];
    company_id: string;
    permissions: string[];
}

interface UseUserStore {
    user: AuthUser;
    initCompleted: boolean;
    setUser: (user: AuthUser) => void;
    clearUser: () => void;
}

const initialUserState: AuthUser = {
    user_id: '',
    name: '',
    email: '',
    profilePicture: '',
    roles: [],
    company_id: '',
    permissions: [],
}

const useUserStore = create<UseUserStore>()(
    devtools(
        (set) => ({
            user: initialUserState,
            initCompleted: false,
            setUser: (user) => set({ user, initCompleted: true }),
            clearUser: () => set({ user: initialUserState, initCompleted: false }),
        }),
        {
            name: "User Store",
            enabled: import.meta.env.DEV
        }
    )
)

export default useUserStore
