import logo from '@/assets/logo_color.svg'
import LoginButton from "@/components/LoginButton";

export default function Login() {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-20 w-auto"
                        src={logo}
                        alt="Mind Assist"
                    />
                    <h2 className="mt-6 text-center text-2xl tracking-tight text-gray-900 mb-5">Καλώς ήρθατε στο MindAssist</h2>
                </div>
                <LoginButton />
            </div>
        </div>
    );
}
