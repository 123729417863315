import React from 'react'
import { Search, X } from 'lucide-react'
import { type Column } from '@tanstack/react-table'

interface ColumnFilterProps<T> {
    column: Column<T>
    placeholder?: string
}

export function ColumnFilter<T>({
                                       column,
                                       placeholder
                                   }: ColumnFilterProps<T>) {
    const filterValue = column.getFilterValue() as string

    return (
        <div className="relative max-w-sm">
            <input
                type="text"
                value={filterValue ?? ''}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={placeholder}
                className="w-full rounded-md border-gray-300 pl-9 pr-9 text-sm shadow-sm
                         focus:border-primary-500 focus:ring-primary-500"
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-400">
                <Search className="h-4 w-4" />
            </div>
            {filterValue && (
                <button
                    onClick={() => column.setFilterValue('')}
                    className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400 hover:text-gray-600"
                >
                    <X className="h-4 w-4" />
                </button>
            )}
        </div>
    )
}

export default ColumnFilter
