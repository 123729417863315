import React from 'react'
import { cva, type VariantProps } from "class-variance-authority"

interface Option {
    value: string | number
    label: string
    disabled?: boolean
}

const selectVariants = cva(
    "block w-full rounded-md shadow-sm sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 transition-colors duration-200",
    {
        variants: {
            variant: {
                default: "border-gray-300 focus:border-primary-500 focus:ring-primary-500",
                error: "border-error-500 focus:border-error-500 focus:ring-error-500",
            },
            size: {
                default: "h-10 px-3",
                sm: "h-8 px-2 text-sm",
                lg: "h-12 px-4",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
)

interface DropdownWithLabelProps
    extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'value' | 'size'>,
        VariantProps<typeof selectVariants> {
    label?: string
    options: Option[]
    error?: string
    value?: string | number
    helperText?: string
    required?: boolean
    fullWidth?: boolean
    placeholder?: string
}

const DropdownWithLabel = React.forwardRef<HTMLSelectElement, DropdownWithLabelProps>(
    ({
         label,
         options,
         error,
         className = '',
         id,
         variant,
         size,
         helperText,
         required,
         fullWidth = true,
         placeholder,
         value,
         ...props
     }, ref) => {
        // Generate unique ID if none provided
        const selectId = id || `select-${Math.random().toString(36).substr(2, 9)}`

        return (
            <div className={`dropdown-with-label ${fullWidth ? 'w-full' : 'w-auto'}`}>
                {label && (
                    <label
                        htmlFor={selectId}
                        className="block text-sm font-medium text-gray-700 mb-1.5"
                    >
                        {label}
                        {required && <span className="text-error-500 ml-1">*</span>}
                    </label>
                )}
                <div className="relative">
                    <select
                        ref={ref}
                        id={selectId}
                        value={value}
                        className={selectVariants({
                            variant: error ? "error" : variant,
                            size,
                            className: `${className} ${fullWidth ? 'w-full' : 'w-auto'}`
                        })}
                        aria-invalid={error ? 'true' : 'false'}
                        aria-describedby={error ? `${selectId}-error` : undefined}
                        required={required}
                        {...props}
                    >
                        {placeholder && (
                            <option value="" disabled>
                                {placeholder}
                            </option>
                        )}
                        {options.map((option) => (
                            <option
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                            >
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Helper Text or Error Message */}
                {(error || helperText) && (
                    <p
                        className={`mt-1.5 text-sm ${error ? 'text-error-500' : 'text-gray-500'}`}
                        id={error ? `${selectId}-error` : undefined}
                    >
                        {error || helperText}
                    </p>
                )}
            </div>
        )
    }
)

DropdownWithLabel.displayName = 'DropdownWithLabel'

export default DropdownWithLabel
