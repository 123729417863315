import React, { useState } from 'react';
import { type ColumnDef } from '@tanstack/react-table';
import { Plus, RefreshCcw, Tag, Pencil, Trash2} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import DataTable from '@/components/ui/DataTable';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { Card } from '@/components/ui/Card';
import { Badge } from '@/components/ui/Badge/Badge';
import { useGetProductsQuery } from '@/components/features/products/services/products.queries';
import { ROUTES } from '@/constants/routes.constants';
import type { Product } from '@/types/product.types';
import Modal from '@/components/ui/Modal';
import { toast } from 'react-toastify';
import ClientsModal from "@/components/features/products/components/ClientsModal";
import {useDeleteProductMutation} from "@/components/features/products/services/products.mutations";

const ProductsList = () => {
    const navigate = useNavigate();
    const { data: products = [], isLoading, refetch } = useGetProductsQuery();
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [clientsModalOpen, setClientsModalOpen] = useState(false);
    const [productToDelete, setProductToDelete] = useState<Product | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const deleteMutation = useDeleteProductMutation();


    const columns: ColumnDef<Product>[] = [
        {
            id: 'product_code',
            header: 'Κωδικός',
            accessorKey: 'product_code',
            cell: ({ row }) => (
                <div className="font-medium text-gray-900">{row.original.product_code}</div>
            ),
        },
        {
            id: 'product_name',
            header: 'Προϊόν',
            accessorKey: 'product_name',
            cell: ({ row }) => (
                <div className="flex flex-col">
                    <span className="font-medium text-gray-900">{row.original.product_name}</span>
                    <span className="text-sm text-gray-500 truncate max-w-md">
                        {row.original.description}
                    </span>
                </div>
            ),
        },
        {
            id: 'product_type',
            header: 'Τύπος',
            accessorFn: (row) => row.product_type.product_type_name,
            cell: ({ row }) => (
                <Badge variant="outline" className="border">
                    {row.original.product_type.product_type_name}
                </Badge>
            ),
        },
        {
            id: 'price',
            header: 'Τιμή',
            accessorKey: 'price',
            cell: ({ row }) => (
                <div className="font-medium text-gray-900">
                    {row.original.price.toLocaleString('el-GR', {
                        style: 'currency',
                        currency: 'EUR'
                    })}
                </div>
            ),
        },
        {
            id: 'actions',
            header: '',
            cell: ({ row }) => {
                const product = row.original;  // Get the product from the row
                return (
                    <div className="flex justify-end gap-2">
                        <Button
                            variant={ButtonVariant.GHOST}
                            size={ButtonSize.SM}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedProduct(product);
                                setClientsModalOpen(true);
                            }}
                            title="Προβολή Πελατών"
                        >
                            <Tag className="h-4 w-4" />
                        </Button>
                        <Button
                            variant={ButtonVariant.GHOST}
                            size={ButtonSize.SM}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(ROUTES.PRODUCTS.NEW, { state: { product } });
                            }}
                            title="Επεξεργασία"
                        >
                            <Pencil className="h-4 w-4" />
                        </Button>
                        <Button
                            variant={ButtonVariant.GHOST}
                            size={ButtonSize.SM}
                            onClick={(e) => {
                                e.stopPropagation();
                                setProductToDelete(product);
                                setDeleteModalOpen(true);
                            }}
                            className="text-red-600 hover:text-red-700"
                            title="Διαγραφή"
                        >
                            <Trash2 className="h-4 w-4" />
                        </Button>
                    </div>
                );
            }
        },
    ];

    return (
        <div className="h-full w-full bg-gray-50">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">Προϊόντα</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Διαχείριση προϊόντων και υπηρεσιών
                    </p>
                </div>

                <Card>
                    <div className="p-4 border-b border-gray-200 flex flex-col sm:flex-row justify-between gap-4">
                        <div className="flex flex-wrap gap-2">
                            <Button
                                variant={ButtonVariant.OUTLINE}
                                size={ButtonSize.SM}
                                onClick={() => refetch()}
                                disabled={isLoading}
                            >
                                <RefreshCcw className="w-4 h-4 mr-2"/>
                                Ανανέωση
                            </Button>
                        </div>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SM}
                            onClick={() => navigate(ROUTES.PRODUCTS.NEW)}
                        >
                            <Plus className="w-4 h-4 mr-2"/>
                            Νέο Προϊόν
                        </Button>
                    </div>

                    <div className="p-4">
                        <DataTable
                            columns={columns}
                            data={products}
                            isLoading={isLoading}
                        />
                    </div>
                </Card>
            </div>

            {selectedProduct && (
                <ClientsModal
                    product={selectedProduct}
                    open={clientsModalOpen}
                    setOpen={setClientsModalOpen}
                />
            )}

            {productToDelete && (
                <Modal
                    open={deleteModalOpen}
                    setOpen={setDeleteModalOpen}
                    title="Διαγραφή Προϊόντος"
                    type="delete"
                    icon={<Trash2 className="h-6 w-6 text-error-600" />}
                    primaryAction={{
                        text: "Διαγραφή",
                        onClick: async () => {
                            try {
                                await deleteMutation.mutateAsync(productToDelete.product_id);
                                toast.success('Το προϊόν διαγράφηκε επιτυχώς');
                                setDeleteModalOpen(false);
                                setProductToDelete(null);
                            } catch (error) {
                                toast.error('Σφάλμα κατά τη διαγραφή του προϊόντος');
                            }
                        }
                    }}
                    secondaryAction={{
                        text: "Ακύρωση",
                        onClick: () => {
                            setDeleteModalOpen(false);
                            setProductToDelete(null);
                        }
                    }}
                >
                    <p className="text-sm text-gray-500">
                        Είστε σίγουροι ότι θέλετε να διαγράψετε το προϊόν{' '}
                        <span className="font-medium text-gray-700">
                {productToDelete.product_name}
            </span>
                        ; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.
                    </p>
                </Modal>
            )}
        </div>
    );
};

export default ProductsList;
