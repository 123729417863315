import React, { useState } from 'react';
import { Pencil, Plus, Search, Trash2 } from 'lucide-react';
import { type ColumnDef } from '@tanstack/react-table';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DataTable from '@/components/ui/DataTable';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonType, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { Card } from '@/components/ui/Card';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import Drawer from "@/components/ui/Drawer/Drawer";
import {
    useCreateProductTypeMutation, useDeleteProductTypeMutation,
    useUpdateProductTypeMutation
} from "@/components/features/productTypes/services/productTypes.mutations";
import {ProductType, ProductTypeFormFields} from "@/types/productTypes.types";
import {useGetProductTypesQuery} from "@/components/features/productTypes/services/productTypes.queries";
import {productTypeSchema} from "@/schemas/productType.schemas";
import {toast} from "react-toastify";
import ColumnTitle from "@/components/ui/DataTable/ColumnTitle";
import Modal from "@/components/ui/Modal";

const ProductTypes = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editingType, setEditingType] = useState<ProductType | undefined>();
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [typeToDelete, setTypeToDelete] = useState<ProductType | null>(null);


    const { data: productTypes = [], isLoading } = useGetProductTypesQuery();
    const createMutation = useCreateProductTypeMutation();
    const updateMutation = useUpdateProductTypeMutation();
    const deleteMutation = useDeleteProductTypeMutation();

    const { register, handleSubmit, reset, formState: { errors } } = useForm<ProductTypeFormFields>({
        resolver: zodResolver(productTypeSchema),
        defaultValues: editingType ? {
            product_type_name: editingType.product_type_name,
            description: editingType.description
        } : undefined
    });

    const columns: ColumnDef<ProductType>[] = [
        {
            accessorKey: 'product_type_name',
            header: 'Τύπος Προϊόντος',
            cell: ({ row }) => (
                <div className="flex flex-col">
                    <span className="font-medium text-gray-900">
                        {row.original.product_type_name}
                    </span>
                </div>
            ),
        },
        {
            accessorKey: 'description',
            header: ({ column }) => (
                <ColumnTitle title="Περιγραφή" isSortable column={column} />
            ),
        },
        {
            id: 'actions',
            header: 'Ενέργειες',
            cell: ({ row }) => (
                <div className="flex gap-2">
                    <Button
                        variant={ButtonVariant.GHOST}
                        size={ButtonSize.SM}
                        onClick={() => handleEdit(row.original)}
                    >
                        <Pencil className="h-4 w-4" />
                    </Button>
                    <Button
                        variant={ButtonVariant.GHOST}
                        size={ButtonSize.SM}
                        className="text-red-600 hover:text-red-700"
                        onClick={() => {
                            setTypeToDelete(row.original);
                            setDeleteModalOpen(true);
                        }}
                    >
                        <Trash2 className="h-4 w-4" />
                    </Button>
                </div>
            ),
        },
    ];

    const handleEdit = (type: ProductType) => {
        setEditingType(type);
        reset({
            product_type_name: type.product_type_name,
            description: type.description
        });
        setIsDrawerOpen(true);
    };

    const handleAddNew = () => {
        setEditingType(undefined);
        reset({
            product_type_name: '',
            description: ''
        });
        setIsDrawerOpen(true);
    };

    const handleDelete = async (id: string) => {
        try {
            await deleteMutation.mutateAsync(id);
            toast.success('Ο τύπος προϊόντος διαγράφηκε επιτυχώς');
            setDeleteModalOpen(false);
            setTypeToDelete(null);
        } catch (error) {
            toast.error('Σφάλμα κατά τη διαγραφή του τύπου προϊόντος');
        }
    };

    const onSubmit = async (data: ProductTypeFormFields) => {
        try {
            if (editingType) {
                await updateMutation.mutateAsync({
                    id: editingType.product_type_id,
                    data
                });
                toast.success('Ο τύπος προϊόντος ενημερώθηκε επιτυχώς');
            } else {
                await createMutation.mutateAsync(data);
                toast.success('Ο τύπος προϊόντος δημιουργήθηκε επιτυχώς');
            }
            setIsDrawerOpen(false);
            reset();
        } catch (error) {
            toast.error('Σφάλμα κατά την αποθήκευση του τύπου προϊόντος');
        }
    };

    const filteredTypes = productTypes.filter(type =>
        type.product_type_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        type.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="h-full w-full bg-gray-50">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">Τύποι Προϊόντων</h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Διαχείριση κατηγοριών προϊόντων και υπηρεσιών
                    </p>
                </div>

                <Card>
                    {/* Actions Bar */}
                    <div className="p-4 border-b border-gray-200 flex flex-col sm:flex-row justify-between gap-4">
                        <div className="relative">
                            <Search className="absolute left-3 top-1/2 -mt-2 h-4 w-4 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Αναζήτηση τύπου..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 w-full sm:w-96"
                            />
                        </div>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.SM}
                            className="flex items-center"
                            onClick={handleAddNew}
                        >
                            <Plus className="h-4 w-4 mr-2" />
                            Νέος Τύπος
                        </Button>
                    </div>

                    {/* Table */}
                    <div className="p-4">
                        <DataTable
                            columns={columns}
                            data={filteredTypes}
                            isLoading={isLoading}
                        />
                    </div>
                </Card>
            </div>

            {/* Drawer */}
            <Drawer
                isOpen={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                    reset();
                    setEditingType(undefined);
                }}
                title={editingType ? 'Επεξεργασία Τύπου' : 'Νέος Τύπος Προϊόντος'}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <InputWithLabel
                        label="Όνομα Τύπου"
                        required
                        placeholder="π.χ. Λογισμικό"
                        error={errors.product_type_name?.message}
                        {...register('product_type_name')}
                    />

                    <TextArea
                        label="Περιγραφή"
                        required
                        rows={4}
                        placeholder="Περιγράψτε τον τύπο προϊόντος..."
                        error={errors.description?.message}
                        {...register('description')}
                    />

                    {/*{editingType && (*/}
                    {/*    <div className="rounded-lg bg-gray-50 p-4">*/}
                    {/*        <div className="text-sm text-gray-500">*/}
                    {/*            Αυτός ο τύπος χρησιμοποιείται σε{' '}*/}
                    {/*            <span className="font-medium text-gray-900">*/}
                    {/*                {editingType.productsCount}*/}
                    {/*            </span>{' '}*/}
                    {/*            προϊόντα*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div className="flex justify-end space-x-4">
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.LG}
                            onClick={() => {
                                setIsDrawerOpen(false);
                                reset();
                            }}
                            type={ButtonType.BUTTON}
                        >
                            Ακύρωση
                        </Button>
                        <Button
                            type={ButtonType.SUBMIT}
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LG}
                            disabled={createMutation.isPending || updateMutation.isPending}
                        >
                            {createMutation.isPending || updateMutation.isPending
                                ? 'Αποθήκευση...'
                                : editingType ? 'Αποθήκευση' : 'Δημιουργία'}
                        </Button>
                    </div>
                </form>
            </Drawer>

            {/* Delete Modal */}
            <Modal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                title="Διαγραφή Τύπου Προϊόντος"
                type="delete"
                icon={<Trash2 className="h-6 w-6 text-error-600" />}
                primaryAction={{
                    text: "Διαγραφή",
                    onClick: () => typeToDelete && handleDelete(typeToDelete.product_type_id)
                }}
                secondaryAction={{
                    text: "Ακύρωση",
                    onClick: () => {
                        setDeleteModalOpen(false);
                        setTypeToDelete(null);
                    }
                }}
            >
                <p className="text-sm text-gray-500">
                    Είστε σίγουροι ότι θέλετε να διαγράψετε τον τύπο προϊόντος{' '}
                    <span className="font-medium text-gray-700">
            {typeToDelete?.product_type_name}
        </span>
                    ; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.
                </p>
            </Modal>
        </div>
    );
};

export default ProductTypes;
