import { client } from '@/lib/httpClient';
import { ROUTES } from '@/constants/routes.constants';
import type { ActionStatus } from '@/types/api.types';
import {ProductType, ProductTypeFormFields, ProductTypeResponse} from "@/types/productTypes.types";
import {productTypeSchema} from "@/schemas/productType.schemas";

export const productTypesApi = {
    getAll: async (): Promise<ProductType[]> => {
        const response = await client.get<ProductType[]>(
            ROUTES.PRODUCT_TYPES.API.BASE
        );
        return response.data ?? [];
    },

    create: async (data: ProductTypeFormFields): Promise<ProductTypeResponse> => {
        const validated = productTypeSchema.parse(data);
        const response = await client.post<ProductTypeResponse>(
            ROUTES.PRODUCT_TYPES.API.BASE,
            validated
        );
        return response.data;
    },

    update: async (id: string, data: ProductTypeFormFields): Promise<ActionStatus> => {
        const validated = productTypeSchema.parse(data);
        const response = await client.put<ActionStatus>(
            ROUTES.PRODUCT_TYPES.API.DETAIL(id),
            validated
        );
        return response.data;
    },

    delete: async (id: string): Promise<ActionStatus> => {
        const response = await client.delete<ActionStatus>(
            ROUTES.PRODUCT_TYPES.API.DETAIL(id)
        );
        return response.data;
    }
};
