import React from 'react';
import {
    AlertTriangle,
    ArrowDown,
    ArrowUp,
    Building2,
    CheckCircle2,
    Clock,
    Filter, Plus,
    Search,
    Timer,
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import { Badge } from "@/components/ui/Badge/Badge";
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { TimeAgo } from "@/components/ui/TimeAgo/TimeAgo";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import {PriorityEnum} from "@/types/priority.types";
import {StatusEnum} from "@/types/status.types";

export default function AgentDashboard() {
    const stats = {
        activeTickets: {
            count: 8,
            trend: 2,
            trendUp: true
        },
        slaCompliance: {
            value: "96%",
            trend: 3,
            trendUp: true
        },
        avgResponseTime: {
            value: "15 λεπτά",
            trend: -12,
            trendUp: false
        },
        resolvedToday: {
            count: 12,
            trend: 4,
            trendUp: true
        }
    };

    const activeTickets = [
        {
            id: "TK-1234",
            title: "Πρόβλημα σύνδεσης στο σύστημα",
            status: StatusEnum.OPEN,
            priority: PriorityEnum.URGENT,
            company: "Cosmote",
            slaRemaining: "2 ώρες",
            createdAt: "2024-02-15T10:30:00Z"
        },
        {
            id: "TK-1235",
            title: "Αίτημα για νέα λειτουργικότητα",
            status: StatusEnum.OPEN,
            priority: PriorityEnum.MEDIUM,
            company: "Vodafone",
            slaRemaining: "18 ώρες",
            createdAt: "2024-02-15T09:15:00Z"
        }
    ];

    const recentActivity = [
        {
            id: "ACT-1",
            type: "resolution",
            ticketId: "TK-1230",
            description: "Επίλυση αιτήματος",
            timestamp: "2024-02-15T08:00:00Z"
        }
    ];

    const performanceMetrics = {
        resolutionTime: {
            value: "3.2 ώρες",
            trend: -15,
            trendUp: false
        },
        satisfaction: {
            value: "4.8/5",
            trend: 0.2,
            trendUp: true
        }
    };

    return (
        <div className="h-full w-full bg-gray-100">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                {/* Header Section */}
                <div className="mb-8">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">Τα Αιτήματά μου</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Διαχείριση και παρακολούθηση αιτημάτων
                            </p>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.SM}
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Νέο Αίτημα
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Active Tickets */}
                    <Card className="bg-blue-50 border-blue-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-blue-700">Ενεργά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-blue-900">{stats.activeTickets.count}</p>
                                        <p className={`ml-2 text-sm ${stats.activeTickets.trendUp ? 'text-error-600' : 'text-success-600'}`}>
                                            {stats.activeTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.activeTickets.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-blue-100 rounded-lg">
                                    <CheckCircle2 className="h-6 w-6 text-blue-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Compliance */}
                    <Card className="bg-green-50 border-green-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-green-700">SLA Συμμόρφωση</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-green-900">{stats.slaCompliance.value}</p>
                                        <p className={`ml-2 text-sm ${stats.slaCompliance.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.slaCompliance.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.slaCompliance.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-green-100 rounded-lg">
                                    <Timer className="h-6 w-6 text-green-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Response Time */}
                    <Card className="bg-purple-50 border-purple-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-purple-700">Χρόνος Απόκρισης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-purple-900">
                                            {stats.avgResponseTime.value}
                                        </p>
                                        <p className={`ml-2 text-sm ${!stats.avgResponseTime.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.avgResponseTime.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.avgResponseTime.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-purple-100 rounded-lg">
                                    <Clock className="h-6 w-6 text-purple-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Resolved Today */}
                    <Card className="bg-yellow-50 border-yellow-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-yellow-700">Επιλυμένα Σήμερα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-yellow-900">{stats.resolvedToday.count}</p>
                                        <p className={`ml-2 text-sm ${stats.resolvedToday.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.resolvedToday.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.resolvedToday.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-yellow-100 rounded-lg">
                                    <CheckCircle2 className="h-6 w-6 text-yellow-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                    {/* Active Tickets */}
                    <Card>
                        <CardHeader>
                            <div className="flex items-center justify-between">
                                <CardTitle className="flex items-center">
                                    <span>Ενεργά Αιτήματα</span>
                                    <Badge variant="default" className="ml-2">
                                        {activeTickets.length} ενεργά
                                    </Badge>
                                </CardTitle>
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                >
                                    Προβολή Όλων
                                </Button>
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {activeTickets.map(ticket => (
                                    <div key={ticket.id} className="flex items-start justify-between border-b pb-4">
                                        <div className="space-y-1">
                                            <div className="flex items-center space-x-2">
                                                <span className="font-medium">#{ticket.id}</span>
                                                <PriorityBadge priority={ticket.priority} />
                                                <StatusBadge status={ticket.status} />
                                            </div>
                                            <p className="text-sm text-gray-900">{ticket.title}</p>
                                            <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                <Building2 className="h-4 w-4" />
                                                <span>{ticket.company}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end space-y-2">
                                            <div className="flex items-center text-error-600 text-sm">
                                                <Clock className="h-4 w-4 mr-1" />
                                                <span>SLA: {ticket.slaRemaining}</span>
                                            </div>
                                            <TimeAgo date={ticket.createdAt} className="text-sm text-gray-500" />
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                size={ButtonSize.SM}
                                            >
                                                Προβολή
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* SLA Monitoring */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Παρακολούθηση SLA</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                                    <div className="flex items-center">
                                        <AlertTriangle className="h-5 w-5 text-red-400" />
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-red-800">Επείγουσα Ειδοποίηση</h3>
                                            <div className="mt-2 text-sm text-red-700">
                                                <p>Το #TK-1234 πλησιάζει στη λήξη του SLA (2 ώρες απομένουν)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <div className="flex items-center justify-between mb-1">
                                        <span className="text-sm font-medium text-gray-600">Σημερινή Απόδοση SLA</span>
                                        <span className="text-sm font-medium text-green-600">96%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2">
                                        <div className="bg-green-600 h-2 rounded-full w-[96%]"></div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div className="bg-green-50 p-4 rounded-lg">
                                        <div className="flex items-center justify-between">
                                            <span className="text-sm font-medium text-green-800">Εντός SLA</span>
                                            <span className="text-lg font-semibold text-green-800">7</span>
                                        </div>
                                    </div>
                                    <div className="bg-yellow-50 p-4 rounded-lg">
                                        <div className="flex items-center justify-between">
                                            <span className="text-sm font-medium text-yellow-800">Σε Κίνδυνο</span>
                                            <span className="text-lg font-semibold text-yellow-800">1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Recent Activity */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Πρόσφατη Δραστηριότητα</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flow-root">
                                <ul className="-mb-8">
                                    {recentActivity.map((activity) => (
                                        <li key={activity.id}>
                                            <div className="relative pb-8">
                                                <span
                                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                    aria-hidden="true"
                                                />
                                                <div className="relative flex space-x-3">
                                                    <div>
                                                        <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                                                            <CheckCircle2 className="h-5 w-5 text-white" />
                                                        </span>
                                                    </div>
                                                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                        <div>
                                                            <p className="text-sm text-gray-500">
                                                                {activity.description}{' '}
                                                                <span className="font-medium text-gray-900">
                                                                    #{activity.ticketId}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                            <TimeAgo date={activity.timestamp} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Performance Metrics */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Στατιστικά Απόδοσης</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <div className="text-sm font-medium text-gray-500">
                                        Μ.Ο. Χρόνος Επίλυσης
                                    </div>
                                    <div className="mt-1">
                                        <div className="text-lg font-semibold text-gray-900">
                                            {performanceMetrics.resolutionTime.value}
                                        </div>
                                        <div className="text-sm text-green-600">
                                            {performanceMetrics.resolutionTime.trend}% από τον προηγούμενο μήνα
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <div className="text-sm font-medium text-gray-500">
                                        Ικανοποίηση Πελατών
                                    </div>
                                    <div className="mt-1">
                                        <div className="text-lg font-semibold text-gray-900">
                                            {performanceMetrics.satisfaction.value}
                                        </div>
                                        <div className="text-sm text-green-600">
                                            +{performanceMetrics.satisfaction.trend} από τον προηγούμενο μήνα
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}

