// src/types/sla.types.ts

import {Company} from "@/types/company.types";
import {Priority} from "@/types/priority.types";

export interface SLA {
    created_at: string;
    updated_at: string;
    sla_id: string;
    sla_name: string;
    description: string;
    company: Company;
    priority: Priority;
    response_time: number;
    resolution_time: number;
}


export const SLAStatus = {
    MET: 'met',
    PENDING: 'pending',
    BREACHED: 'breached'
} as const;

export type SLAStatus = typeof SLAStatus[keyof typeof SLAStatus];

export type SLAFirstResponse = {
    status: SLAStatus;
    time: string;
};

export type SLAResolutionTime = {
    status: SLAStatus;
    remaining: string;  // This field is different from firstResponse
};

export type SLAMetrics = {
    firstResponse: SLAFirstResponse;
    resolutionTime: SLAResolutionTime;
};
