// src/lib/config.ts
const config = {
    nodeEnv: import.meta.env.MODE,
    isDev: import.meta.env.MODE === 'development',
    authServer: import.meta.env.VITE_AUTH_SERVER,
    authClientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    audience: import.meta.env.VITE_AUTH_AUDIENCE,
    apiBaseURL: import.meta.env.VITE_API_BASE_URL,
} as const;

export default config;
