export interface Role {
    role_id: string;
    role_name: string;
    description: string;
    hierarchy_level: number;
}

export const RoleTypes = {
    ADMINISTRATOR: 'administrator',
    HELPDESK: 'helpdesk',
    MANAGER: 'manager',
    AGENT: 'agent',
    CLIENT_ADMINISTRATOR: 'client_administrator',
    CLIENT_MANAGER: 'client_manager',
    CLIENT_EMPLOYEE: 'client_employee'
} as const;

export type UserRole = typeof RoleTypes[keyof typeof RoleTypes];

export const RoleNames: Record<keyof typeof RoleTypes, string> = {
    ADMINISTRATOR: 'Διαχειριστής',
    HELPDESK: 'Τεχνική Υποστήριξη',
    MANAGER: 'Υπεύθυνος',
    AGENT: 'Εκπρόσωπος',
    CLIENT_ADMINISTRATOR: 'Διαχειριστής Εταιρίας',
    CLIENT_MANAGER: 'Υπεύθυνος Εταιρίας',
    CLIENT_EMPLOYEE: 'Υπάλληλος Εταιρίας'
};

export const ROLE_NAME_TO_TYPE: Record<string, UserRole> = {
    'Διαχειριστής': RoleTypes.ADMINISTRATOR,
    'Τεχνική Υποστήριξη': RoleTypes.HELPDESK,
    'Υπεύθυνος': RoleTypes.MANAGER,
    'Εκπρόσωπος': RoleTypes.AGENT,
    'Διαχειριστής Εταιρίας': RoleTypes.CLIENT_ADMINISTRATOR,
    'Υπεύθυνος Εταιρίας': RoleTypes.CLIENT_MANAGER,
    'Υπάλληλος Εταιρίας': RoleTypes.CLIENT_EMPLOYEE
};


// Keep only the role-related functionality here
const CLIENT_ROLES = [
    RoleTypes.CLIENT_ADMINISTRATOR,
    RoleTypes.CLIENT_MANAGER,
    RoleTypes.CLIENT_EMPLOYEE
] as const;

const ADMIN_ROLES = [
    RoleTypes.ADMINISTRATOR,
    RoleTypes.HELPDESK,
] as const;

export const isClientRole = (role: UserRole): boolean => {
    return CLIENT_ROLES.includes(role as never);
};

export const isAdminRole = (role: UserRole): boolean => {
    return ADMIN_ROLES.includes(role as never);
};
