// components/features/products/components/ClientsModal.tsx
import React from 'react';
import Modal from '@/components/ui/Modal';
import type { Product } from '@/types/product.types';
import { Badge } from '@/components/ui/Badge';
import {useGetProductClientsQuery} from "@/components/features/products/services/products.queries";

interface ClientsModalProps {
    product: Product;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ClientsModal: React.FC<ClientsModalProps> = ({ product, open, setOpen }) => {
    const { data: clients = [], isLoading } = useGetProductClientsQuery(product.product_id);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={`Πελάτες - ${product.product_name}`}
            type="default"
        >
            <div className="mt-4">
                {isLoading ? (
                    <div className="animate-pulse space-y-4">
                        {[1, 2, 3].map((i) => (
                            <div key={i} className="h-16 bg-gray-100 rounded" />
                        ))}
                    </div>
                ) : clients.length > 0 ? (
                    <div className="space-y-4">
                        {clients.map((client) => (
                            <div
                                key={client.product_client_id}
                                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                            >
                                <div>
                                    <h4 className="font-medium text-gray-900">
                                        {client.client.company_name}
                                    </h4>
                                    <p className="text-sm text-gray-500">
                                        {client.client.email_address}
                                    </p>
                                </div>
                                <Badge variant="outline">
                                    {client.price.toLocaleString('el-GR', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    })}
                                </Badge>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center py-8">
                        <p className="text-gray-500">
                            Δεν υπάρχουν πελάτες που χρησιμοποιούν αυτό το προϊόν
                        </p>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ClientsModal;
