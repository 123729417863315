import React from 'react';
import {
    ArrowDown,
    ArrowUp,
    Clock,
    FileText,
    Filter,
    MessageSquare,
    Plus,
    Search,
    Timer
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import { Badge } from "@/components/ui/Badge/Badge";
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { TimeAgo } from "@/components/ui/TimeAgo/TimeAgo";
import PriorityBadge from "@/components/ui/Badge/PriorityBadge";
import StatusBadge from "@/components/ui/Badge/StatusBadge";
import CategoryDropdown from "@/components/ui/DropdownWithLabel/CategoryDropdown";
import PriorityDropdown from "@/components/ui/DropdownWithLabel/PriorityDropdown";
import InputWithLabel from '@/components/ui/InputWithLabel';
import {PriorityEnum} from "@/types/priority.types";
import {StatusEnum} from "@/types/status.types";

export default function ClientEmployeeDashboard() {
    // Quick ticket creation form state
    const [quickTicket, setQuickTicket] = React.useState({
        title: '',
        category_id: '',
        priority_id: ''
    });

    const stats = {
        myTickets: {
            count: 8,
            trend: 2,
            trendUp: true
        },
        activeTickets: {
            count: 3,
            trend: -1,
            trendUp: false
        },
        avgResolutionTime: {
            value: "4.5 ώρες",
            trend: -5,
            trendUp: false
        },
        resolvedTickets: {
            count: 15,
            trend: 3,
            trendUp: true
        }
    };

    const myTickets = [
        {
            id: "TK-4567",
            title: "Πρόβλημα με την εξαγωγή αναφορών",
            status: StatusEnum.OPEN,
            priority: PriorityEnum.MEDIUM,
            createdAt: "2024-02-15T09:30:00Z",
            lastUpdate: "2024-02-15T10:30:00Z",
            assignedTo: "Μαρία Κ."
        }
    ];

    const recentActivity = [
        {
            id: "ACT-1",
            ticketId: "TK-4567",
            action: "response",
            description: "Νέα απάντηση από την υποστήριξη",
            timestamp: "2024-02-15T10:30:00Z"
        }
    ];

    return (
        <div className="h-full w-full bg-gray-100">
            <div className="mx-auto p-4 sm:p-6 lg:p-8">
                {/* Header Section */}
                <div className="mb-8">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">Τα Αιτήματά μου</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Διαχείριση και παρακολούθηση αιτημάτων
                            </p>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Button
                                variant={ButtonVariant.PRIMARY}
                                size={ButtonSize.SM}
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Νέο Αίτημα
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Stats Grid */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {/* Total Tickets */}
                    <Card className="bg-blue-50 border-blue-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-blue-700">Συνολικά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-blue-900">
                                            {stats.myTickets.count}
                                        </p>
                                        <p className={`ml-2 text-sm ${stats.myTickets.trendUp ? 'text-error-600' : 'text-success-600'}`}>
                                            {stats.myTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.myTickets.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-blue-100 rounded-lg">
                                    <FileText className="h-6 w-6 text-blue-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Active Tickets */}
                    <Card className="bg-green-50 border-green-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-green-700">Ενεργά Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-green-900">
                                            {stats.activeTickets.count}
                                        </p>
                                        <p className={`ml-2 text-sm ${!stats.activeTickets.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.activeTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {Math.abs(stats.activeTickets.trend)}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-green-100 rounded-lg">
                                    <MessageSquare className="h-6 w-6 text-green-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Resolution Time */}
                    <Card className="bg-purple-50 border-purple-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-purple-700">Μέσος Χρόνος Επίλυσης</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-purple-900">
                                            {stats.avgResolutionTime.value}
                                        </p>
                                        <p className={`ml-2 text-sm ${!stats.avgResolutionTime.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.avgResolutionTime.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.avgResolutionTime.trend}%
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-purple-100 rounded-lg">
                                    <Timer className="h-6 w-6 text-purple-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Resolved Tickets */}
                    <Card className="bg-yellow-50 border-yellow-200">
                        <CardContent className="p-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm font-medium text-yellow-700">Επιλυμένα Αιτήματα</p>
                                    <div className="flex items-baseline">
                                        <p className="text-2xl font-semibold text-yellow-900">
                                            {stats.resolvedTickets.count}
                                        </p>
                                        <p className={`ml-2 text-sm ${stats.resolvedTickets.trendUp ? 'text-success-600' : 'text-error-600'}`}>
                                            {stats.resolvedTickets.trendUp ? <ArrowUp className="inline h-4 w-4"/> : <ArrowDown className="inline h-4 w-4"/>}
                                            {stats.resolvedTickets.trend}
                                        </p>
                                    </div>
                                </div>
                                <div className="p-3 bg-yellow-100 rounded-lg">
                                    <Clock className="h-6 w-6 text-yellow-700"/>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                    {/* My Tickets */}
                    <Card>
                        <CardHeader>
                            <div className="flex items-center justify-between">
                                <CardTitle className="flex items-center">
                                    <span>Τα Αιτήματά μου</span>
                                    <Badge variant="default" className="ml-2">
                                        {myTickets.length} ενεργά
                                    </Badge>
                                </CardTitle>
                                <div className="flex space-x-2">
                                    <div className="relative">
                                        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                                        <input
                                            type="text"
                                            placeholder="Αναζήτηση..."
                                            className="pl-8 pr-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    <Button variant={ButtonVariant.OUTLINE} size={ButtonSize.SM}>
                                        <Filter className="h-4 w-4 mr-2" />
                                        Φίλτρα
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {myTickets.map((ticket) => (
                                    <div key={ticket.id} className="flex items-start justify-between border-b pb-4">
                                        <div className="space-y-1">
                                            <div className="flex items-center space-x-2">
                                                <span className="font-medium">#{ticket.id}</span>
                                                <PriorityBadge priority={ticket.priority} />
                                                <StatusBadge status={ticket.status} />
                                            </div>
                                            <p className="text-sm text-gray-900">{ticket.title}</p>
                                            <div className="flex items-center space-x-2 text-sm text-gray-500">
                                                <MessageSquare className="h-4 w-4" />
                                                <span>Ανατέθηκε στον/ην {ticket.assignedTo}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <TimeAgo date={ticket.createdAt} className="text-sm text-gray-500" />
                                            <Button
                                                variant={ButtonVariant.OUTLINE}
                                                size={ButtonSize.SM}
                                                className="mt-2"
                                            >
                                                Προβολή
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>

                    {/* Recent Activity */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Πρόσφατη Δραστηριότητα</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flow-root">
                                <ul className="-mb-8">
                                    {recentActivity.map((activity) => (
                                        <li key={activity.id}>
                                            <div className="relative pb-8">
                                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                                <div className="relative flex space-x-3">
                                                    <div>
                                                        <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
                                                            <MessageSquare className="h-5 w-5 text-white" />
                                                        </span>
                                                    </div>
                                                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                        <div>
                                                            <p className="text-sm text-gray-500">
                                                                {activity.description} στο{' '}
                                                                <span className="font-medium text-gray-900">
                                                                    #{activity.ticketId}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                            <TimeAgo date={activity.timestamp} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Ticket Status Summary */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Κατάσταση Αιτημάτων</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                {/* Status Distribution */}
                                <div className="space-y-4">
                                    <div className="bg-gray-50 rounded-lg p-4">
                                        <div className="flex justify-between items-center mb-2">
                                            <span className="text-sm font-medium text-gray-900">Ανοιχτά</span>
                                            <span className="text-sm text-gray-500">3 αιτήματα</span>
                                        </div>
                                        <div className="w-full bg-gray-200 rounded-full h-2">
                                            <div className="bg-blue-600 h-2 rounded-full w-[30%]"></div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 rounded-lg p-4">
                                        <div className="flex justify-between items-center mb-2">
                                            <span className="text-sm font-medium text-gray-900">Σε Εξέλιξη</span>
                                            <span className="text-sm text-gray-500">2 αιτήματα</span>
                                        </div>
                                        <div className="w-full bg-gray-200 rounded-full h-2">
                                            <div className="bg-yellow-600 h-2 rounded-full w-[20%]"></div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 rounded-lg p-4">
                                        <div className="flex justify-between items-center mb-2">
                                            <span className="text-sm font-medium text-gray-900">Επιλυμένα</span>
                                            <span className="text-sm text-gray-500">5 αιτήματα</span>
                                        </div>
                                        <div className="w-full bg-gray-200 rounded-full h-2">
                                            <div className="bg-green-600 h-2 rounded-full w-[50%]"></div>
                                        </div>
                                    </div>
                                </div>

                                {/* Recent Resolutions */}
                                <div className="mt-6">
                                    <h4 className="text-sm font-medium text-gray-900 mb-4">Πρόσφατες Επιλύσεις</h4>
                                    <div className="space-y-3">
                                        <div className="bg-gray-50 p-3 rounded-lg">
                                            <div className="flex justify-between items-start">
                                                <div>
                                                    <p className="text-sm font-medium text-gray-900">
                                                        #TK-4562 - Αναβάθμιση λογισμικού
                                                    </p>
                                                    <p className="text-sm text-gray-500 mt-1">
                                                        Επιλύθηκε σε 3.5 ώρες
                                                    </p>
                                                </div>
                                                <Badge variant="success">
                                                    Επιτυχής
                                                </Badge>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Response Times */}
                    <Card>
                        <CardHeader>
                            <CardTitle>Χρόνοι Απόκρισης</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">
                                <div className="bg-gray-50 rounded-lg p-4">
                                    <h4 className="text-sm font-medium text-gray-900 mb-4">
                                        Μέσοι Χρόνοι ανά Προτεραιότητα
                                    </h4>
                                    <div className="space-y-4">
                                        <div>
                                            <div className="flex justify-between mb-1">
                                                <span className="text-sm text-gray-600">Υψηλή</span>
                                                <span className="text-sm text-gray-600">1.2 ώρες</span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div className="bg-green-600 h-2 rounded-full w-[85%]"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex justify-between mb-1">
                                                <span className="text-sm text-gray-600">Μεσαία</span>
                                                <span className="text-sm text-gray-600">4.5 ώρες</span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div className="bg-green-600 h-2 rounded-full w-[92%]"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Average Response Stats */}
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <p className="text-sm font-medium text-gray-500">
                                            Πρώτη απόκριση
                                        </p>
                                        <p className="mt-1 text-2xl font-semibold text-gray-900">
                                            45 λεπτά
                                        </p>
                                        <p className="mt-1 text-sm text-green-600">
                                            -15% από τον μέσο όρο
                                        </p>
                                    </div>
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <p className="text-sm font-medium text-gray-500">
                                            Χρόνος επίλυσης
                                        </p>
                                        <p className="mt-1 text-2xl font-semibold text-gray-900">
                                            4.5 ώρες
                                        </p>
                                        <p className="mt-1 text-sm text-green-600">
                                            -8% από τον μέσο όρο
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}

